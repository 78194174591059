import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './ListClasseDerniereAnnee.css'
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";


function ListClasseDerniereAnnee() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    const [listeClasses, setListeClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Recuperation liste des classes
    const [checked, setChecked] = useState([]);   

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, event.target.value];
        } else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    useEffect(()=>{
        setIsLoading(true)
       axios(`${APP_ENV.baseUrl}/getClasseLastAnne`,{headers: header}

        ).then(res=>{
            if(res.status === 200){
                setListeClasses(res.data.datas)
                setIsLoading(false)
            }
        })
        .catch(error =>{
            console.log(error);
        })
    }, [])
   
    const hundleSubmit = (e) =>{    
        e.preventDefault();  
        const donnees = {
            classe_id: checked
        }
   
        axios.post(`${APP_ENV.baseUrl}/afectationAnneeScolaireClasse`,donnees,

          {headers: header}
          ).then(res=>{
            navigate('/classeDerniereAnnee')
            window.location.reload(false)
          })
          .catch(er =>{
            // let ereur = er.response.data   
          })
    }
    
    return (
    <Squelette>
         {isLoading ? <LoadingSpinner/> : 
        <>
        <div className=''>
        
            {/* classe-create */}
            <div className="row justify-content-between">
                <div className="col-md-10 ml-5 mb-2"><h3>Liste des classes de la dernière année scolaire</h3></div>
          
                 <div className="col-md-5 ml-3 d-flex justify-content-center">
           
            </div>
            </div>
            <div className=''>
            <form onSubmit={hundleSubmit} >
              <div className='row justify-content-center'>
                  
                {listeClasses ? listeClasses.map((classe, index ) => (   
                    <>
                    <div key={index} className='col-md-2 m-3 mr-3 p-2 shadow border border-success bloc-classe checkboxAnnee' >
                        <input className="ml-1 " style={{float: 'left', borderColor: 'red', border: '3px'}}
                            type="checkbox"
                            name= "classe_id[]"
                            value={classe.id}
                            onChange={handleCheck}
                        />
                        <label className='mt-2' for="defaultIndeterminate2">{classe.code }({classe.type})</label>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                    </div>
                    </>
                )): <h1 style={{textAlign: 'center', marginTop: '60px'}}>Nous n'avons qu'une seule année scolaire</h1> }

            </div>
            {listeClasses?
            <div className="col-md-12 conteneur-btn ">
                    <button className='btn1-connexion enregistrer'>Enregistrer</button>
                </div>
                :<></>
            }
            </form>
        </div>
        </div>
        </>}
    </Squelette>
  )
}

export default ListClasseDerniereAnnee;
