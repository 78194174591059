import React from 'react'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";
import LoadingSpinner from '../helpers/LoadingSpinner'
import DataTableReportageNote from './DataTableReportageNote';
import ListNoteParClasse from './ListNoteParClasse';
import { HiChevronDown } from 'react-icons/hi';
import './reporterNote.css'

function ReporterNoteClasse() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [state] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listeClasse, setListeClasse] = useState([]);
    const [listeMatieres, setListeMatieres] = useState([]);
    const [annee, setAnnee] = useState('');
    const [listeEleve, setListeEleve] = useState([]);
    const [succes, setSucces] = useState('')
    const [datas, setDatas] = useState('')
    const [error, setError] = useState('')
    const [recherche, setRecherche] = useState('')   
    const [paiementOk, setPaiementOk] = useState(false)    
    const [dateListEleve, setDateListEleve] = useState('')    
    const [dataDonnees, setDataDonnees] = useState([])    
    const [formulaire, setFormulaire] = useState({
        semestre: '',
        evaluation: '',
        matiere_id: '',
        classe_id: '',
        eleves: '',
    })

    let profil = localStorage.getItem('profil')  
    let idUser = localStorage.getItem('enseignent_id') 
    //  list-matiers-d-unenseignent
    
    //##### Changement des inputs #####
    const hundeRecherche =(ev)=>{
        
        const name = ev.target.name;    
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
        
    }
    const hundleRecherche = (ev) =>{  
        
        setIsLoading(true);
        axios(`${APP_ENV.baseUrl}/cl-liste-eleve-matiere/${recherche.classe_id}`,{headers: header}
        ).then(res=>{
            if(res.status === 200){
                setDatas(res.data.datas)
                setListeEleve(res.data.datas.eleves)
            }
            setIsLoading(false);
        })
        .catch(error =>{
            let ereurs = error.response.data.error
        })
    }
    const hundeChange =(e)=>{
        
        const name = e.target.name;
        const value = e.target.value;
        setFormulaire({...formulaire ,[name]:value})
    }

    // Recuperation liste des classes
    const lastAnnee = async()=>{
        setIsLoading(true);
        await 
        axios(`${APP_ENV.baseUrl}/lastAnnee`,{headers: header}
        ).then(res=>{
            if(res.status == 200){
                setAnnee(res.data.annee)
            }
        })
        .catch(error =>{
            let ereurs = error.response.data.error
        })
        // Liste des classes
        if (profil === "Enseignent") {
            axios(`${APP_ENV.baseUrl}/matiersUnEnseignent/${idUser}`,{headers: header}
        
            ).then(res=>{
                if(res.status === 200){
                  
                    setListeClasse(res.data.data)
                    setListeMatieres(res.data.data[0].matieres)
                }
                setIsLoading(false);
            })
            .catch(error =>{
                // let ereurs = error.response.data.error
            })
        }else{
            axios(`${APP_ENV.baseUrl}/classes-liste`,{headers: header}
            
            ).then(res=>{
                if(res.status === 200){
                    setListeClasse(res.data.classes)
                }
                setIsLoading(false);
            })
            .catch(error =>{
                // let ereurs = error.response.data.error
            })
        }
    }
    useEffect(()=>{
        lastAnnee();
    },[])

    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
        e.preventDefault();
        formulaire.classe_id = recherche.classe_id;
        formulaire.eleves = listeEleve;

        axios.post(`${APP_ENV.baseUrl}/evaluation-create`, formulaire,

          {headers: header}
        ).then(res => {
             setSucces(res.data)
            setDataDonnees(res.data)
            setDateListEleve(formulaire)
            setPaiementOk(true);
        })
        .catch(er =>{
          let ereur = er.response.data.message
       
          setError(ereur)
        })
    }
       


    return (

    <Squelette>
        {isLoading ? <LoadingSpinner/> :
       
        <div className=''>
         {(paiementOk)?
            <>
           < ListNoteParClasse dataNotes={dateListEleve}  datas={dataDonnees}/>
            </>:
            <>
        <div className="row">
            <div className="col-md-8 font-weight-bold">Abou Oubayda AS SAKINA</div>
            <div className="col-md-4 text-float-right">Annee scolaire: <label className='font-weight-bold'>{annee.code}</label></div>
        </div>
        <form onSubmit={hundleRecherche} >
            <div className='row '>
                {error !== '' ?
                    <h2 className='alert alert-danger text-center ml-5'>{error}</h2>
                : ''
                }
                {succes !== '' ? 
                    <h2 className='alert alert-success text-center ml-5'>{succes}</h2>
                : '' 
                }
                <div className=" col-md-7 mt-3 inputSelect" >
                    <HiChevronDown className='icon-selectReportage'/>
                    <select name="classe_id" value={recherche.classe_id} onChange = {hundeRecherche} required>

                    <option value=''>Veuillez Choisir la classe à évaluer</option>
                       {(profil === "Enseignent") ?
                      <>
                        {  
                        listeClasse?listeClasse.map((classe, index ) => ( 
                        <option value={classe.classe.id}>{classe.classe.code+" / "+classe.classe.type}</option>
                    )):<></>}
                    </>:<>
                    { 
                    listeClasse?listeClasse.map((classe, index ) => ( 
                        <option value={classe.id}>{classe.code+" / "+classe.type}</option>
                    )):<></>}
                    </>
                    
                } 
                    </select>
                </div>
                <div className="col-md-3 mx-5 mt-2 justify-content-center ">

                    <button className='btn-classe mb-4'>Rechercher</button>
                </div>
            </div>
        </form>
        <form onSubmit={hundleSubmit}>
            <div className="row mb-4 justify-content-between ">
                <div className="col-md-4 inputSelectReportage1" >

                    <select name="matiere_id" class="form-control" onChange = {hundeChange} required>
                        <option value={''}>Choisir une matière</option>
                        {(profil === "Enseignent") ?
                        <>
                            { listeMatieres ? listeMatieres.map((mat, index) =>(
                                
                                <option value={mat.id}>{mat.libelle}</option>
                                )) :<></> }
                        </>:
                        <>
                            { datas.matieres ? datas.matieres.map((mat, index) =>(
                                <option value={mat.matiere_id}>{mat.libelle}</option>
                                )) : ''}
                        </>
                    }
                    </select>
                    <HiChevronDown className='icon-selectReportage1'/>
                </div>
                <div className="col-md-4 inputSelectReportage2" >
                    <HiChevronDown className='icon-selectReportage2'/>
                    <select name="semestre" class="form-control" onChange = {hundeChange} required>
                        <option value={''}>Choisir le semestre</option>
                        <option value={1}>Semestre 1</option>
                        <option value={2}>Semestre 2</option>
                    </select>
                </div>
                <div className="col-md-4 inputSelectReportage3" >
                    <HiChevronDown className='icon-selectReportage3'/>
                    {(datas.niveau === 'Primaire' )?
                      ( datas.classe.type === 'FR' )?
                                <select name="evaluation" class="form-control" onChange = {hundeChange} required>

                                <option value={''}>Type d'évaluation</option>
                            
                                <option value={'ressource'}>Ressource</option>
                                <option value={'competence'}>Compétence</option>
                                <option value={'compo'}>Composition</option>

                            </select>
                            :
                            <select name="evaluation" class="form-control" onChange = {hundeChange} required>

                                <option value={''}>Type d'évaluation</option>
                                <option value={'compo'}>Composition</option>
        
                            </select>
                       :
                    <select name="evaluation" class="form-control" onChange = {hundeChange} required>
                        <option value={''}>Type d'évaluation</option>
                        <option value={'dev1'}>Devoir 1</option>
                        <option value={'dev2'}>Devoir 2</option>
                        <option value={'compo'}>Composition</option>
                    </select>
                      }
                </div>
            </div>

            <fieldset className = 'fieldset mt-2'>
                <h3 className='tilte-fieldset user'> Liste des élèves </h3>
                <DataTableReportageNote data ={listeEleve? listeEleve: []} state={state} handleChange={hundeChange} />      
            </fieldset>
            <div className="col-md-12 conteneur-btn">
              <button className='btn1-connexion enregistrer'>Enregistrer</button>
              <button className='btn1-connexion annuler'>Annuler</button>
          </div>
        </form>
        </>
        }
        <br/>
        </div>
    }
    </Squelette>
  )
}

export default ReporterNoteClasse ;
