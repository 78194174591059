import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import APP_ENV from "../../config/.env";
import './Connexion.css'

function Reinitialisation() {
    let navigate = useNavigate();
  const [identifiant, setIdentifiant] = useState({
    email: '',
  })
  const [error, setError] = useState('')
  const [ setSuccess] = useState('')
 
      const hundeChange =(e)=>{
          const name = e.target.name;
          const value = e.target.value
          setIdentifiant({...identifiant ,[name]:value})
      }
     
      const hundleSubmit = (e) =>{
        e.preventDefault();
        
        axios.post(`${APP_ENV.baseUrl}/password/forgot-password`,identifiant,
        ).then(res=>{
          navigate('/modifierPassword')
          setSuccess(res.data.message);
        }).catch(er =>{
          let ereur = er.response.data.errors.email
          setError(ereur)
        }) 
      }
  return (
    <div className='content-form'>
       <form className="contactform" onSubmit={hundleSubmit}>
            <h1 >Réinitialisation de mot de passe</h1>
            <p>Veuillez entrer l’adresse  e-mail que vous avez utiliser 
                lors de la création de votre
                 compte et nous  vous enverrons dans votre mail un code de validation</p>
            <div className="inputboite">
                <input type="text" placeholder="Email" name='email' value={identifiant.email} style={{width: '95%'}} onChange = {hundeChange}/>
            </div>
            
          
            {error !== '' ? 
            <h5 className='alert alert-danger text-center'>{error}</h5>
            : '' 
            } 
            <div className="" >
                <button type = 'submit' className='btn-connexion' style={{width: '100%'}}>Valider</button>
            </div>
          
       </form>
     
    </div>
  )
}
 
export default Reinitialisation;
