import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Reinscrire.css";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import Recu from "./Recu"
import SweetAlert from "react-swal";


function Reinscrire() {
  const [ tarif, setTarif] = useState("");
  const [error, setError] = useState("");
  const [erreur, setErreur] = useState("");
  const [ setSuccess] = useState("");
  const [classeArabe, setClasseAR] = useState([]);
  const [classeFrance, setClasseFR] = useState([]);
  const [paiementOk, setPaiementOk] = useState(false)    
  const [tarification, setTarification] = useState('')    
  const [anneeScolaire, setAnneeScolaire] = useState()
  const [matricule, setMatricule] = useState('');
  const [eleves, setEleve] = useState({
    classeAR: '',
    classeFR: '',
    matricule: '',
    typeEleveNormal: "Normale",
    typeElevePriseEnCharge: "Prise en charge",
    typeEleveAvecInscription: "Avec inscription",
    typeEleveEnChargePersonalise: "En charge personalise",
  })
  const [elevesM, setMatriculeEleve] = useState({
    matricule : "",
  });
  
  const header = {
    Accept: "application/json",
    "Content-Type" : "application/json",
    WithCredentials: true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
const hundeChangeM = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    
    setMatriculeEleve({ ...elevesM, [name]: value });
}
  const hundeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEleve({ ...eleves, [name]: value });
  };

  const getClAR = async () => {
    await axios.get(`${APP_ENV.baseUrl}/classe-ar`, {
      headers: header,
    })
    .then((res) => {
      setClasseAR(res.data.classeAR);
    })
    .catch((er) => {
      let ereur = er.response.data.errors;
      setError(ereur);
    });
  }
  const getClFR = async () => {
    await
  axios.get(`${APP_ENV.baseUrl}/classe-fr`, {
      headers: header,
    })
    .then((res) => {
      setClasseFR(res.data.classeFR);
      
    })
    .catch((er) => {
      let ereur = er.response.data.errors;
      setError(ereur);
    });
  }
  // Tarification
  const getTarification =  async () => {
    await axios.get(`${APP_ENV.baseUrl}/tarif-get`, {
        headers: header,
      }).then((res) => {
        setTarif(res.data.tarification);
      }).catch((er) => {
        // let ereur = er.response.data.errors;
        // setError(ereur);
      });
    }
  useEffect(() => {
    getTarification()
    getClFR();
    getClAR();
  },[]);
 
  const hundleSubmitR = (e) => {
    e.preventDefault();
    axios
    .get(`${APP_ENV.baseUrl}/eleve-show/${elevesM.matricule}`, {
      headers: header,
    })
    .then((res) => {
      setEleve(res.data.eleve)
    })
   
    .catch((ere) => {
      let erreur = ere.response.data.error;
       setErreur(erreur)
    }); 
   
  };
  //##### Soumission du Formulaire #####
  const hundleSubmit = (e) => {
    e.preventDefault();
    
    const datas = {"classeAR": parseInt(eleves.classeAR),"classeFR":parseInt(eleves.classeFR),"matricule":eleves.matricule,"typeEleve":eleves.typeEleve}
    axios.put(`${APP_ENV.baseUrl}/reinscription`, datas
         , {
        headers: header,
      })
      .then((res) => {
        setMatricule(res.data.eleve.matricule)
        setTarification(res.data.tarification.montantInscription)
 
        setAnneeScolaire(res.data.anneeScolaire.code)
        setPaiementOk(true);
      })
      .catch((er) => {
        let ereur = er.response.data.error;
        setError(ereur)
      });
  };

  return (
    <Squelette>
       {(paiementOk)?
        <>
       < Recu data={matricule} tarification={tarification} anneeScolaire={anneeScolaire} />
        </>:
        <>
      <div className="eleveconteiner ml-4 ">    
          <div className="Rectangle-691">
              <div className="Rectangle-701">
                  <button className="inscription1" style={{borderRadius: "5px" }}>
                  
                  <Link style={{color: "#268d36", }} to="/inscription" >Inscription</Link> 
                </button>
                <button className="reinscription1">
                  Réinscription
                </button>
              </div>
          </div>
        <div className="eleveform formulaire">
          <fieldset className="fieldset-reinscrire" >
          
        <form className=" " onSubmit={hundleSubmitR}>
          <div className="row mt-4" style={{marginTop: '-20px'}}>
            <div className="col-md-3"></div>
            {erreur !== '' ? 
              <p className='bg-danger text-white text-center px-3'>{erreur}</p>
              : '' 
              }
          </div>
        <div className="row" style={{marginTop: '-20px'}}>
        <div className="col-md-7">
          <div className="inputboite">
            <input
              type="text"
              placeholder="Matricule de l'élève à rechercher"
              name="matricule"
              value={elevesM.matricule}
              onChange={hundeChangeM}
            />
          </div>
        </div>
       
          <div className="col-md-4">
            <button type="submit" className="btn1-connexion recherche">
                Rechercher
            </button>
          </div>
      </div>
        </form>
          <form className=" " onSubmit={hundleSubmit} style={{marginTop: '-20px'}}>
            <div className="row justify-content-between mr-2">
              <p className="text">
               
                Informations personnelles de l’élève
              </p>
              <hr className="bar"></hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Prénom"
                    name="prenom"
                    value={eleves.prenom}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom"
                    name="nom"
                    value={eleves.nom}
                    
                  />
                </div>
              </div>
            </div>
            <div className="row mr-2 ">
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Date de naissance"
                    name="dateNaissance"
                    value={eleves.dateNaiss}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Lieu de naissance"
                    name="lieuNaissance"
                    value={eleves.lieuNaiss}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div class="form-group ">
               
                  <select
                    class="form-control"
                    
                    value={eleves.sexe}
                     disabled
                  >
                    {eleves.sexe === 'F'?
                     <option >Femme</option>:
                     <option >Homme</option>
                    }
                   
                   
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="classeAR"
                    value={eleves.classeAR}
                    onChange={hundeChange}
                  >
                    <option>Séléctionner la classe Arabe</option> 

                    {classeArabe.map((classear) => (
                      <option value={classear.id}>{classear.code}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                
                <div class="form-group ">
           

                  <select
                    class="form-control"
                    id="sel1"
                    name="classeFR"
                    value={eleves.classeFR}
                    onChange={hundeChange}
                  >
                    
                    <option>Séléctionner la classe Français</option> 
                    {classeFrance.map((classefr) => (
                      <option value={classefr.id}>{classefr.code}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
              
            <div className="row mr-2">
              <span className="text"> Informations tuteurs</span>
              <hr className="barre"></hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    name="nomCompletPere"
                    value={eleves.nomCompletPere}
                     disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet de la mère"
                    name="nomCompletMere"
                    value={eleves.nomCompletMere}
                     disabled
                  />
                </div>
              </div>
            </div>

            <div className="row mr-2">
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet tuteur"
                    name="nomCompletTuteur"
                    value={eleves.nomCompletTuteur}
                     disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Téléphone du tuteur"
                    name="telephoneTuteur"
                    value={eleves.telephoneTuteur}
                     disabled
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
            <div className="col-md-6 inputboite ">
                  <input
                    type="text"
                    name="telephoneTuteur"
                    value={eleves.email}
                     disabled
                  />
                </div>
            </div>

            <p className="text"> Prise en charge</p>
            <div className="row">

            <hr className="bar mr-3"></hr>
            </div>
           
            <div className="form-check">
            {  ( eleves.typeEleve === 'Normale')?
                  <input id="normal"
                  className="form-check-input"
                  type="radio"
                  name="typeEleve"
                  value={eleves.typeEleveNormal}
                  onChange={hundeChange} 
                checked />:
                  <input id="normal"
                className="form-check-input"
                type="radio"
                name="typeEleve"
                value={eleves.typeEleveNormal}
                onChange={hundeChange} 
              />
                } 
             
              <label className="form-check-label">Non prise en charge</label>
            </div>
            <div className="form-check">
              
              <input
                className="form-check-input"
                type="radio"
                name="typeEleve"
                value={eleves.typeElevePriseEnCharge}
                onChange={hundeChange} 
              />
              <label className="form-check-label">Prise en charge total</label>
            </div>
            <div className="form-check">
            {  ( eleves.typeEleve === 'Avec inscription')?
                  <input
                  className="form-check-input"
                  type="radio" id="no"
                  name="typeEleve"
                  value={eleves.typeEleveAvecInscription}
                  onChange={hundeChange}
               checked  />:
                <input
                className="form-check-input"
                type="radio" id="no"
                name="typeEleve"
                value={eleves.typeEleveAvecInscription}
                onChange={hundeChange}
              />
                } 
             
              <label className="form-check-label">
                Prise en charge mais avec inscription
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio" id="no"
                name="typeEleve"
                value={eleves.typeEleveEnChargePersonalise}
                onChange={hundeChange}
              />
              <label className="form-check-label">
                Prise en charge pérsonalisée
              </label>
            </div>

            <div className="row mr-2" id="normalrow" style={{display: "none"}}>
              <div className="col-md-6">
                      <div className="inputboite" >
                          <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                            type="text"
                             value={tarif.montantInscription+ ' F CFA'} disabled
                              />
                    </div>
              </div>
                  <div className="col-md-6">
                          <div className="inputboite">
                              <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                                type="text"
                                 value={tarif.montantMensualite+ ' F CFA'} disabled
                                  />
                        </div>
                  </div>
            </div>

            <div className="row  mr-2" id="inscrie" style={{display: "none"}}>
              <div className="col-md-12">
                      <div className="inputboite" >
                          <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                            type="text"
                             value={tarif.montantInscription+ ' F CFA'} disabled
                          />
                    </div>
              </div>
                 
            </div>
            {error !== "" ? (
              <h2 className="alert alert-danger text-center">{error}</h2>
            ) : (
              ""
            )}

            <div>
              <div className="col-md-12 conteneur-btn btnAjoutUser ">
                <button type="submit" className="btn1-connexion enregistrer">
                  Enregistrer
                </button>
                <button className="btn1-connexion annuler">Annuler</button>
              </div>
              {/* <button className='btn-connexion'>Enregistre</button>
                <button type = 'submit' className='btn-annuler'>Enregistre</button> */}
            </div>
          
            </form>
          </fieldset>
       
        </div>
      </div>
      </>}
    </Squelette>
  );
}

export default Reinscrire;
