import React from 'react'
import './Paiement_create.css'
import { useEffect, useState} from 'react';
import axios from 'axios';
import './Paiement_create.css'
import Squelette from '../squelette/Squelette';
import RecuPaiement from './RecuPaiement';
import APP_ENV from "../../config/.env";



export default function Paiement_create() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [succes] = useState('')
    const [error, setError] = useState('')
    const [eleve, setEleve] = useState('');
    const [trouve, setTrouve] = useState(false);
    const [tarification, setTarification] =useState('')
    const [paiement, setPaiement] =useState('')
   
    const mois = ['octobre', 'novembre', 'decembre', 'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet'];

    
    const [checked, setChecked] = useState([]);   
    const [dataRecu, setDataRecu] = useState('');   

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, event.target.value];
        } else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

     
    const [recherche, setRecherche] = useState('')   
    const [paiementOk, setPaiementOk] = useState(false)    
    const [formulaire, setFormulaire] = useState({
        prenom: '',
        nom: '',
        montant: '',
    })

    //##### Changement des inputs #####
    const hundeRecherche =(ev)=>{
        const name = ev.target.name;
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
    }
    const hundeChange =(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setFormulaire({...formulaire ,[name]:value})
    }
  
    // Recuperation des niveau
   
    useEffect(()=>{
        
    }, [])
    
    const hundleRecherche = (ev) =>{    
        ev.preventDefault();
        axios(`${APP_ENV.baseUrl}/eleve-show/${recherche.matricule}`,
        {headers: header}
        ).then(res=>{
            setError('')
            setEleve(res.data.eleve)
         
            setTrouve(true)
        })
        .catch(er =>{
          let ereur = er.response.data.error
          setError(ereur)
        })
        axios(`${APP_ENV.baseUrl}/get-paiement-eleve/${recherche.matricule}`,
            {headers: header}
            ).then(res=>{
                // console.log(res);
                setTarification(res.data.paiement)
                setPaiement(res.data.paiement[0])
            })
            .catch(er =>{
                let ereur = er.response.data.error
                setError(ereur)
            })
        // Get Tarification
        if(!trouve){
            axios(`${APP_ENV.baseUrl}/tarif-get`,

            {headers: header}
            ).then(res=>{
                
                setTarification(res.data.tarification)
            })
            .catch(er =>{
                let ereur = er.response
                setError(ereur)
            })
        }
    }  

    const hundleSubmit = (e) =>{    
        e.preventDefault();  
        const donnees = {
            matricule: recherche.matricule,
            mois: checked
        }
     
        setDataRecu(donnees)
   
          axios.post(`${APP_ENV.baseUrl}/paiement-create`,donnees,

          {headers: header}
          ).then(res=>{
          
            setPaiementOk(true);
          })
          .catch(er =>{
            let ereur = er.response.data
            setError(ereur)       
          })
    }

    function isDesable(item){
        switch (item) {
            case 'octobre':
               return (paiement.octobre != null) ? true : false
                break;
            case 'novembre':
                return (paiement.novembre != null) ? true : false
                break;
            case 'decembre':
                return (paiement.decembre != null) ? true : false
                break;
            case 'janvier':
                return (paiement.janvier != null) ? true : false
                break;
            case 'fevrier':
                return (paiement.fevrier != null) ? true : false
                break;
            case 'mars':
                return (paiement.mars != null) ? true : false
                break;
            case 'avril':
                return (paiement.avril != null) ? true : false
                break;
            case 'mai':
                return (paiement.mai != null) ? true : false
                break;
            case 'juin':
                return (paiement.juin != null) ? true : false
                break;
            case 'juillet':
                return (paiement.juillet != null) ? true : false
                break;        
            default:
                return ''
                break;
        }
    }

return (
<Squelette>
    <div className='container'>
    {(paiementOk)?
        <>
       < RecuPaiement data={dataRecu} mensialite={eleve.typeEleve === "En charge personalise" ? paiement.montant_eleve_personalise : tarification.montantMensualite}/>
        </>:
        <>
        
        <div className='container mt-5 formulaire col-md-10'>
        <fieldset className = 'p-2'>
          <h3 className='title-form-paiement'>Paiement Mensuel</h3>
            <form onSubmit={hundleRecherche} >
                <div className="row ml-5">
                    {error !== '' ? 
                        <h2 className='alert alert-danger text-center'>{error}</h2>
                    : ''
                    }
                    {succes !== '' ? 
                        <h2 className='alert alert-success text-center'>{succes}</h2>
                    : '' 
                    }
                    <div className="inputboitePaiement col-md-5 ">
                        <input type="text" placeholder="Matricule" name='matricule' value={recherche.matricule} onChange = {hundeRecherche} required/>
                    </div>
                    <div className="inputboite col-md-4 ml-5 m-2">
                        <button className='btn-classe'>Rechercher</button>
                    </div>
                </div>
            </form>

            <form onSubmit={hundleSubmit} >
                <div className="row">
                    <div className="inputboitePaiement col-md-5 " >
                        <input className="mt-4" type="text" placeholder="Prénom" name='prenom' value={eleve.prenom} onChange = {hundeChange} readOnly/> 
                        <input className="mt-4"  type="text" placeholder="Nom" name='nom' value={eleve.nom} onChange = {hundeChange} readOnly/>

                        <input className="mt-4"  type="text" placeholder="Montant" name='montant' value={(tarification.montantMensualite && checked.length)? (eleve.typeEleve === "En charge personalise" ? parseInt(paiement.montant_eleve_personalise*checked.length) : parseInt(tarification.montantMensualite*checked.length)) : ""} onChange = {hundeChange} readOnly/>

                    </div>
                    <div className="col-md-6" >
                        <div className="row-md-12 mt-3 d-flex justify-content-center " style={{fontWeight: 'bold', fontSize:20}}>Choisi un ou des mois a payer</div>
                        <div className="row mr-4">
                            
                            {mois.map((item, index ) => (
                                <div key={index} className="col-md-4 d-flex align-items-center justify-content-center">
                                    {item} <br/> 
                                    { isDesable(item)? 
                                    
                                    <input className='m-3'
                                        type="checkbox"
                                        name= "choix[]"
                                        value={item}
                                        onChange={handleCheck} checked
                                     /> :
                                    <input className='m-3'
                                        type="checkbox"
                                        name= "choix[]"
                                        value={item}
                                        onChange={handleCheck}
                                     /> }
                                </div>
                            ))}

                        </div>
                    </div>                      
                </div>  
                <div className="col-md-12 conteneur-btn ">
                    <button className='btn1-connexion enregistrer'>Enregistrer</button>
                    <button className='btn1-connexion annuler'>Annuler</button>
                </div>
            </form>
                  
          
    
        </fieldset>
        </div>  
        </>
        }
    </div>
    </Squelette>

    )
}