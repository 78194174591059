import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './ListeClasse.css'
import Descripteur from '../accueil/Descripteur';
import { useParams } from 'react-router-dom';
import APP_ENV from "../../config/.env";

function ListeClasseDUnEenseignent() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    let idEnseignent = useParams(); 
    const [listeClasses, setListeClasses] = useState([]);
    // Recuperation liste des classes
    useEffect(()=>{
        // axios(base_url + 'classes-liste',{
            axios.get(`${APP_ENV.baseUrl}/list-classe-d-unenseignent/`+idEnseignent.id, {
            headers: header}
        
        ).then(res=>{
            if(res.status == 200){
                setListeClasses(res.data.datas.classes)
                console.log(res.data.datas);
            }
        })
        .catch(error =>{
            let ereurs = error.response.data.error
            // console.log(ereurs)
        })
    }, [])
    // Action apres click sur une classe
    function clickClasse(choix){
        // console.log(choix)
        if(choix){
            navigate('/classe-affiche/'+choix)
        }
    }

    return (
    <Squelette>
        <div className='container'>
            <h1>Liste des Classes D' un Enseignent </h1>
            <div className='container'>
              <div className='row'>
                  
                 {(listeClasses) ? listeClasses.map((cl, index ) => (    
                            
                    <div className='col-md-3 m-3 mr-3 p-2 shadow border border-success bloc-classe' onClick={e => clickClasse(cl.calsse.id)}>
                        <Descripteur texForm ={cl.classe.code.substr(0,1).toUpperCase()} text = {cl.classe.code} 
                            detail={cl.matieres.map((matiere, i)=>(
                                <>
                               {(cl.matieres.length - 2 === i) ?
                                   <> {matiere +" et "}</>:<>{matiere+" ."}</>
                                    }
                                    {/* {(cl.matieres.length - 1 === i) ?
                                   <> {matiere+","}</>:<>
                                   </>
                                    } */}
                                    {(cl.matieres.length  === i) ?
                                   <> .</>:<>
                                   </>
                                    }
                                      
                                    
                                </>
                                
                                // 
                            ))}
                        color = '#000'
                        colorBg = {cl.couleur} />
                    </div>
                    )) : <>
                    <br />
                      <div className="col-md-10 ">Pas encore de classe pour ce enseignent</div>

                    </>
                    }
                   

            </div>
        </div>
        </div>
    </Squelette>
  )
}

export default ListeClasseDUnEenseignent;
