import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Classe_create.css'
import APP_ENV from "../../config/.env";


function Classe_create() {
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
    let navigate = useNavigate( );
  const [listeNiveaux, setListNiveaux] = useState([]);  
  const [errorCode, setErrorCode] = useState('')
  const [errorLibelle, setErrorLibelle] = useState('')
  const [errorNiveau, setErrorNiveau] = useState('')
  const [errorType, setErrorType] = useState('')
  const [formulaire, setFormulaire] = useState({
    code: '',
    libelle : '',
    niveau_id : '',
    type : '',
  })
  // Recuperation des niveau
  const listNiveau = async () => {
    const res = await fetch(`${APP_ENV.baseUrl}/niveau-list`,{ 
      headers: header,
      })
      const data = await res.json()
      if(data){
        setListNiveaux(data.niveaux)
      }else{
        // console.log('errors : ' + data.errors)
      }
    }
  useEffect(()=>{

      listNiveau()
    },[])

    const [ setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire ,[name]:value})
    }

    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
      e.preventDefault();
      // Les controlle 
      if (formulaire.code === '') {
        setErrorCode("Veillez saisir le code")
        }else{
          setErrorCode('')
        }
        if (formulaire.libelle === '') {
          setErrorLibelle("Veillez saisir le libelle")
          }else{
            setErrorLibelle('')
          }
      if (formulaire.type === '') {
        setErrorType("Veillez selectionner type  de classe")
          }else{
            setErrorType('')
          }
          if (formulaire.niveau_id === '') {
            setErrorNiveau("Veillez selectionner le niveau ")
              }else{
                setErrorNiveau('')
              }
      axios.post(`${APP_ENV.baseUrl}/classe-create`,formulaire,
      {headers: header}
      ).then(res=>{
       
        if(res.status === 200){
          navigate('/classe-liste')
        }
      })
      .catch(er =>{
        let ereur = er.response.data.error
        setError(ereur)
        // console.log(ereur)
      })
  }
     
  return (
<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
        <fieldset className = 'fieldset'>
          <h3 className='tilte-fieldset user'>Création d'une classe</h3>
          <div className="row justify-content-between ">
            
                <div className="inputboite " style={{paddingLeft: 0,width: '46%'}} >
                      <input type="text" placeholder="Libelle de la classe" name='libelle' value={formulaire.libelle} onChange = {hundeChange} />
                      {(errorLibelle
                      )  ?
                                
                                <p className=' text-danger'>{errorLibelle
                                }</p>:
                                ''
                                }  
                </div>
                <div className="inputboite" style={{width: '46%',marginRight: '25px'}}>
                    <input type="text" placeholder="Code"  name='code' value={formulaire.code} onChange = {hundeChange} />
                    {(errorCode )  ?
                                
                                <p className=' text-danger'>{errorCode}</p>:
                                ''
                                } 
                    </div>
              </div>
            
           
                <div className="row justify-content-center">
                  <div className=" inputboite col-md-12 " style={{marginRight: '30px'}}>
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}
                    <select name="niveau_id" onChange = {hundeChange} className = ''   >
                    <option value="">Niveau de la classe</option>
                      {listeNiveaux.map((niveau, index) => (
                        <option value={ niveau.id}>{ niveau.libelle}</option>
                      ))}
                    </select>
                    {(errorNiveau )  ?
                             
                             <p className=' text-danger'>{errorNiveau}</p>:
                             ''
                             }  
                  </div>
                  <div className="col-md-12 inputboite  "style={{marginRight: '30px'}}>
                    {/* <HiChevronDown className='icon-chevron  mt-3'/> */}
                    <select name="type" onChange = {hundeChange} className = '' >
                      <option value="">Type de classe</option>
                      <option value="AR">Classe arabe</option>
                      <option value="FR">Classe français</option>
                    </select>
                    {(errorType )  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                  </div>
                </div>                      
                            
          
           
          <div className="col-md-12 conteneur-btn btnAjoutUser ">
              <button className='btn1-connexion enregistrer'>Enregistrer</button>
              <button className='btn1-connexion annuler'>Annuler</button>
          </div>
    
        </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default Classe_create;
