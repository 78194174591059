import React from 'react'
import './BulletinNoteP.css'
import { useEffect, useState} from 'react';
import axios from 'axios';
import APP_ENV from "../../config/.env";
import BulletinNoteMA from './BulletinNoteMA';
import { FiCheck } from 'react-icons/fi';
import { BsFillPrinterFill } from "react-icons/bs";
import { Link} from 'react-router-dom';

export default function BulletinNoteM({datas}) {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [listeClasses, setListeClasses] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [matiere, setMatiere] = useState([]);
    const [classe, setClasse] = useState([]);
    const [rang, setRang] = useState([]);
    const [moyenne, setMoyenne] = useState([]);
    const [eleves, setEleves] = useState([]);
    const [type, setType] = useState({});
    const [niveau, setNiveau] = useState([]);
    const [donnees, setDonnees] = useState({});
    
    const bulletinmoyensecondaire = async () => {
        await
        axios.post(`${APP_ENV.baseUrl}/bulletin-moyen-secondaire`,datas, 
        {headers: header}
        ).then(res=>{

                setDonnees(res.data)
                setListeClasses(res.data)
                setAnneeScolaire(res.data.anneeScolaire.code)
                setEleves(res.data.eleve)
                setMatiere(res.data.matieres)
                setClasse(res.data.classe)
                setType(res.data.classe.type)
                setNiveau(res.data.niveau)
                setRang(res.data.rang)
                setMoyenne(res.data.moyen)
               console.log(res.data.niveau)
               console.log(res.data.classe.type)
        })
        .catch(error =>{
            // console.log(ereurs)
        })
       }
    useEffect(()=>{
       // setIsLoading(true)
       bulletinmoyensecondaire();
       
    },[])
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }
    
    let profil = localStorage.getItem('profil')  
    
  return (
    <div>
     
        {( niveau !== 'Primaire' && type === 'AR') ?
                <BulletinNoteMA donnee={donnees}/>
            :
                <>
    <div className ='container'>
      <div id="printablediv">

        <div className="herder-bulletin ">
            <div className="col-md-4">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>Ecole Franco-Arabe</small>
            </div>
            <div className="col-md-4">
                  <small className=' d-flex justify-content-end'>Année Scolaire: {anneeScolaire} </small>
              <small className=' d-flex justify-content-end'>{listeClasses.semestre} semestre</small>
               
            </div>
    
        </div>
        <div className="row borederTitle ms-0" >
            <h4 className='title-bultin'>Bulletin de Note</h4>
        </div>
        <div className="herder-bulletin">
            
            <div className="col-md-4">
                <small>Nom: {eleves.nom}</small><br/>
                {/* <small>Né(e): {eleves.dateNaiss}</small><br/> */}
                <small>Matricule: {eleves.matricule}</small>
            </div>
            <div className="col-md-3" style={{textAlign: "center"}}>
                <small> à {eleves.lieuNaiss}</small><br/>
                <small>Nombre d'élève: {rang.effectif}</small>
            </div>
            <div className="col-md-4 px-3">
                <small className="d-flex justify-content-end">Prénom: {eleves.prenom}</small>
                <small className="d-flex justify-content-end">Classe: {classe.code}</small>
                <small className="d-flex justify-content-end">Classe Redoublee: 0</small>
            </div>
            
        </div>
        <table className='table table-bordered '>
            <thead>
                <tr style={{fontSize: '12px'}} className='entete-tableau'>
                    <th>Matières</th>
                    <th>Devoir</th>
                    <th>Comp</th>
                    <th>Moy/20</th>
                    <th>Coéf</th>
                    <th>Moy X</th>
                    <th>Appréciation</th>
                </tr>
            </thead>
            <tbody id="ligneTab">
                 {matiere?matiere.map((mat,index) => (
                <tr style={{fontSize: '12px'}} >
                    <td>{mat.libelle}</td>
                    <td>{mat.devoir}</td>
                    <td>{mat.compo}</td>
                    <td>{mat.moyenMatiere}</td>
                    <td>{mat.coef}</td>
                    <td>{mat.moyenx}</td>  
                    <td>{mat.appreciationNote}</td>  
                        
                </tr>
           )):<></>} 
                <tr style={{fontSize: '12px', fontWeight: 'bold'}}>
                    <th style={{fontSize: '12px'}}  >TOTAL</th>
                    <td colSpan ={3}></td>
                    <td>{listeClasses.sommeCoefs}</td>
                    <td colSpan ={2}>{listeClasses.sommeNotes}</td>
                </tr>
                <tr style={{fontSize: '12px', fontWeight: 'bold'}} >
                    <th  >Moyenne: {moyenne.valeur} / {moyenne.damander}</th>
                    
                    <td colSpan ={2}>Rang : 
                            {rang.position >1 ?
                          <>  {" "+rang.position + ' ème'} </>:
                           <> 
                           {" "+rang.position + ' ére'}
                           </>
                    }
                    </td>
                    
                    <td colSpan ={2}>Retards : 0</td>
                    <td colSpan ={2}>Absence : 1</td>
                </tr>
            </tbody>
        </table>
        <div className='row justify-content-between tab2'>
            <div className="col-5">
                {listeClasses.semestre === "1 ère" ?
                <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '13px',}}>
                        <tr>
                            <th>Satisfaisant doit continuer</th>
                            {(moyenne.valeur >= 12)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>   
                        <tr>
                            <th>Peut Mieux faire</th>
                            {(moyenne.valeur >= 10 && moyenne.valeur < 12)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Insuffisant</th>
                            {(moyenne.valeur >= 9 && moyenne.valeur < 10)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Risque de Redoubler</th>
                            {(moyenne.valeur >= 7 && moyenne.valeur < 9)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Risque de l'exclusion</th>
                            {(moyenne.valeur < 7 )?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>

                    </tbody>
                </table>
                : <table className='table table-bordered col-md-6' >
                <tbody>
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne première semestre</th>
                        <td>{listeClasses.moyenSemestre1+" / 20"}</td>
                    </tr>   
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne deuxième semestre</th>
                        <td>{moyenne.valeur+" / "+moyenne.damander}</td>
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne annuelle</th>
                        <td>{listeClasses.moyenAnnuelle+" / 20"}</td>
                    </tr>
                </tbody>
            </table>
            }
            </div>
            <div className="col-5">
                <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '13px',}}>
                        <tr>
                            <th>Félicitations</th>
                            {(moyenne.valeur >= 14)?
                            <td>
                                <FiCheck/>

                            </td>:
                            <td></td>
                        }
                        </tr>   
                        <tr>
                            <th>Encouragements</th>
                            {(moyenne.valeur >=10 && moyenne.valeur < 14)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Tableau d'honneur</th>
                            {(rang.position <= 5)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Avertissement</th>
                            {(moyenne.valeur >= 7 && moyenne.valeur < 10)?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                        <tr>
                            <th>Blame</th>
                            {(moyenne.valeur < 7 )?
                            <td>
                                <FiCheck/>
                            </td>:
                            <td></td>
                        }
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <div className='signature'>
            <div className='col-md-4 '>
                <h6 className=''>Observation du conseil professeurs</h6>
                <textarea rows="4" cols="36">
                </textarea>
            </div>
            <div className='col-md-4'>
                <h6 className='signature-chef'>Le chef de l'établissement</h6>
            </div>
            <div className="">
              
                <h5 className=''>le Maitre</h5>

          </div>
         </div>
        </div>
        </div>
        </>}
    
        {(profil === 'Admin') &&
            <div className="Rectangle-180 pl-3 pr-3 mb-4 " style={{marginRight: '300px'}} >
                <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
           </div>
        }
    </div>
  )
}
