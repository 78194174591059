import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import axios from 'axios';
import './CreerSurveillent.css'
import Squelette from '../squelette/Squelette';
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
function ModifierMatiere() {
    let surId = useParams(); 
    let navigate = useNavigate();
  // const APP_KEY = "base64:1eVr0Ulj38dFmPI2OCbecG8wZzIcftMZeO8gz898sdk="
  const [identifiant, setIdentifiant] = useState('')
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }

      const getSurveiilentById = async (id) => {
        await
        axios
        .get(`${APP_ENV.baseUrl}/getSurveillentById/${surId.id}`, {
          headers: header,
        })
                  .then(res =>{
                    setIdentifiant(res.data.donnees[0])

                    
                  })
                  .catch(er =>{
                   
                           setError(er)
                       })
        
      }
     useEffect(() => {
      getSurveiilentById();
      }, []);
      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
            axios.post(`${APP_ENV.baseUrl}/editSurveillent/`+surId.id,identifiant, {
            headers: header
            })
          .then(res=>{
            setSuccess(res.data);
             navigate('/listSurveillent')
            
          })
          .catch(er =>{
           // console.log(er);
                  let ereur = er.response.data.errors.messag
                  //console.log(ereur)
                  setError(ereur)
              })
       
      }
  return (
    <Squelette>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
            <fieldset className = 'p-2'>
                <h5 className='title-form'>Modification Surveillent</h5> 
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                    <div className="inputboite">
                        <input type="text" placeholder="Nom Complet" name='nomComplet' value={identifiant.nomComplet} onChange = {hundeChange}/>
                    </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                    <div className="inputboite">
                        <input type="number" placeholder="Telephone" name='telephone' value={identifiant.telephone} onChange = {hundeChange}/>
                    </div>
                    </div>
                  </div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div class="form-group ">
                    
                        <select class="form-control" id="sel1" name="type"  value={identifiant.type} onChange = {hundeChange} >
                        {  ( identifiant.type === 'AR')?
                         <option value="AR">ARABE</option>:
                         <option value="FR">FRANCAIS</option>
                        }
                            <option value="FR">FRANCAIS</option>
                            <option value="AR" >ARABE</option>
                        
                        </select>
                    </div>
                </div>
                </div>
                
    
           
            {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
            : '' 
            } 
            
            <div className="col-md-12 conteneur-btn ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </Squelette>
  )
}
 
export default ModifierMatiere;