import React, {useState} from 'react';

import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import {FormatDate} from '../helpers/FormatDate'
import { AiFillPrinter } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function ListeElevesParClasse({data, niveau, typeClasse, classe, annee, state}) {

    const [postsParPages, setPostsParPages] = useState(100);
    const [pageCourant , setPageCourant] = useState(1);
    const dernierPost = pageCourant * postsParPages;
    const premierePage = dernierPost - postsParPages;
    const numberOfPage = Math.ceil(data?.length / postsParPages)
    const postsCourrent = data.slice(premierePage, dernierPost);
    const [value, setValue] = useState('') 

    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = 'Liste des niveaux';
        window.print();
       document.body.innerHTML = originalContents; 
    }

  return (
    <>
    <Link className='btn-classe font-weight-bold' onClick={Print} style={{cursor: "pointer", color: "white"}}> <AiFillPrinter/> Imprimer</Link>
    <div id="printablediv">
        <div className="herder-bulletin">
            <div className="col-md-4">
                <h6>Classe: {classe} ({typeClasse})</h6>
            </div>
            <div className="col-md-4 justify-content-end">
                <h6>Année scolaire: {annee.code}</h6>
            </div>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-4">
                <h6>Enseignant: </h6>
            </div>
            <div className="col-md-4">
                <h6>Matière: </h6>
            </div>
            <div className="col-md-4">
                <h6 style={{paddingRight: "100px"}}>Semestre: </h6>
            </div>
            
        </div>
            
        <div className="table-responsive">
        <table className='table table-bordered table-hover' >
        <thead>
            <tr style={{background :'#268d36', color:'#fff'}}>
                <th style={{width: '5px'}}>N°</th>
                <th>Matricule</th>
                <th>Prénom</th>
                <th>Nom </th>

                {(niveau !== 'Primaire')&&
                    <>
                      <th>Dev N°1 </th>
                      <th>Dev N°2</th>
                      <th>Composition</th>
                    </>
                }
                
                {(niveau === 'Primaire' && typeClasse === "FR") &&
                // Pour le Primaire français
                <>
                  <th>Ressource</th>
                  <th>compétence</th>
                </>}   
                {(niveau === 'Primaire' && typeClasse === "AR") &&
                // Pour le Primaire français
                <th>note</th>
              }  
            </tr>
        </thead>
        <tbody>
            {postsCourrent != [] ? postsCourrent.filter(el => (
                el.matricule.toLowerCase().includes(value) 
                || el.prenom.toLowerCase().includes(value) 
                || el.nom.toLowerCase().includes(value) 
            ))
            .map((post,index) => (
                state = [],
            <tr key={post.id}>
                <td>{index+1}</td>
                <td>{post.matricule}</td>
                <td>{post.prenom} </td>
                <td>{post.nom}</td>
                
                {(niveau !== 'Primaire')&&
                    <>
                        <th></th>
                        <th></th>
                        <th></th>
                    </>
                    }
                
                {(niveau === 'Primaire' && typeClasse === "FR") &&
                // Pour le Primaire français
                <>
                  <th></th>
                  <th></th>
                </>}
                {(niveau === 'Primaire' && typeClasse === "AR") &&
                // Pour le Primaire français
                  <th></th>
                }
            </tr>
            )): <></>}
        </tbody>
        </table>
        </div>

        <div className="row justify-content-between">
            <div className="col-md-4">
                <div className="text-start"> {pageCourant} / {numberOfPage} de  {data.length} Entres</div>
            </div>

            <div className="col-md-2 ml-5">
                <nav aria-label="Page navigation example"> </nav>
                    <div className="btn-group " role="group" aria-label="Basic example">
                        {
                            pageCourant === 1 ? null : (
                            <button type="button" style={{width :'110px'}} class="btn btn-outline-secondary" onClick={() => setPageCourant(v => v - 1)}>
                            <WiDirectionLeft className=""/>
                            Previous
                            </button>
                            )
                        }
                            <button type="button" class="btn btn-primary" >{pageCourant}</button>
                            {
                            numberOfPage === pageCourant ? null : (
                            <button type="button" class="btn btn-outline-secondary" style={{width :'90px'}} onClick={() => setPageCourant(v => v + 1)}>
                            Next
                            <WiDirectionRight  className=""/>
                            </button>
                            )
                            }
                    </div>
            
                </div>
            </div>

        </div>
    
   </>
   
  )
}