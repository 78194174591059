
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import './AjoutApport.css'
import Squelette from '../squelette/Squelette';
import { HiChevronDown } from 'react-icons/hi';
import APP_ENV from "../../config/.env";

function AjoutApport() {
    let navigate = useNavigate( );
  // const APP_KEY = "base64:1eVr0Ulj38dFmPI2OCbecG8wZzIcftMZeO8gz898sdk="
  const [identifiant, setIdentifiant] = useState({
    type: '',
    valeur: '',
    description : '',
    motif: '',
    nomComplet: '',
  })
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error, setError] = useState('')
  const [ setSuccess] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }
      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
            axios.post(`${APP_ENV.baseUrl}/apport-create`,identifiant, {
            headers: header
            })
          .then(res=>{
             navigate('/accueilBudgetere')
            
          })
          .catch(er =>{
                  let ereur = er.response.data.errors.messag
                  setError(ereur)
              })
      }
  return (
    <Squelette>
    <div className='apporttconteiner mt-5 mr-2'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
            <fieldset className = 'fieldset'>
                <h3  className='tilte-fieldset user mt-1'> Création apport </h3> 

            <div className="row ">
                <div className="col-md-6 ">
                    
                        <select className="form-control " id="sel1" name="type"  style={{marginTop: "10px"}} value={identifiant.type}  onChange = {hundeChange} >
                      
                          <option >Choissez le Type d' Apport</option>
                          <option value="Entrant">Entrant</option>
                          <option value="Sortant" >Sortant</option>
                        </select>
                    
                </div>
                <div className="col-md-6">
                    <div className="inputboite">
                        <input type="text" placeholder="Valeur"  className="form-controluser" name='valeur' value={identifiant.valeur} onChange = {hundeChange}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div  className="inputboite">
                    
                        <textarea type="text" placeholder="description" name='description' value={identifiant.description} onChange = {hundeChange} class="form-control" rows="5" id="comment"></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputboite">
                        <input type="text"  className="form-controluser" placeholder="Motif" name='motif' value={identifiant.motif} onChange = {hundeChange}/>
                    </div>
                    <div className="inputboite">
                    <input type="text"  className="form-controluser" placeholder="Apporteur" name='nomComplet' value={identifiant.nomComplet} onChange = {hundeChange}/>
                </div>
                </div>
            </div>
           
            {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
            : '' 
            } 
            
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </Squelette>
  )
}
 
export default AjoutApport;
