import React from "react";
import { useState, useEffect} from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import "./AffichageEleve.css";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
import BulletinNoteM from "../note/BulletinNoteM";
import BulletinNotePA from "../note/BulletinNotePA";
import LoadingSpinner from '../helpers/LoadingSpinner'
import ListNoteEleveSamestre from "../note/ListNoteEleveSamestre";

function AffichageEleve() {
    let mat = useParams(); 
        const header = {
          Accept : "application/json", 
         "Content-Type": "multipart/form-data",
          WithCredentials : true,
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
        const [eleve , setEleve] = useState([])
        const [dataBultin ] = useState({})
        const [dataNotes , setDataNotes] = useState({})
        const [oki , setOki] = useState(false)
        const [noteOk , setNoteOk] = useState(false)
        const [type , setType] = useState('')
        const [niveau ,setNiveau] = useState('')
        const [isLoading, setIsLoading] = useState(false);
        const anneeScol =  localStorage.getItem("anneeScolaire");

        const getEleveShow = async () => {
            await
            axios.get(`${APP_ENV.baseUrl}/eleve-show/${mat.id}/${anneeScol}`, {
                headers: header
            })
            .then(res=>{
                console.log("res.data.eleve")
                console.log(res.data.eleve)
                setEleve(res.data.eleve)
                dataBultin.eleve_id = res.data.eleve.id
                dataBultin.annee_id = res.data.eleve.annee_id
                
                setIsLoading(false)
            }).catch(er =>{
            })
        }

        let profil = localStorage.getItem('profil')  

        useEffect(()=>{
            setIsLoading(true)
            getEleveShow();

        } ,[])

    function click(classe,semestre,type,niveau) {
        dataBultin.annee_id = anneeScol
        dataBultin.semestre = semestre;
        dataBultin.classe_id =classe; 
        setType(type)
        setNiveau(niveau)
        setOki(true)  
    }

    function clickNote(eleve_id, classe_id, semestre){
        dataNotes.eleve_id = eleve_id;
        dataNotes.classe_id = classe_id;
        dataNotes.semestre = semestre;
        setDataNotes(dataNotes)
        setNoteOk(true)
    }

    return (

        <Squelette>
            
        {(oki)? 
        
            niveau === 'Primaire'?
                type === 'AR'?
                    <BulletinNotePA donnee={dataBultin} />:
                        // c'est a modifier
                    <BulletinNotePA donnee={dataBultin} />

            :
                <BulletinNoteM datas={dataBultin} />

        : noteOk ?
            <ListNoteEleveSamestre datas={dataNotes}/>
        : 
        <>

          {isLoading ? <LoadingSpinner/> : 
            <>
          <div className="container">
           
            <div className="entete">
                <div className="col-md-12 enteteVert">
                    <div className="row">
                        <div className=" divavatarEleve">
                          
                            <img className="avatarEleve" src={process.env.PUBLIC_URL + '/images/avatar.jpg'} width="90"/>
                        </div>
                    </div>
                </div>
               

                <div className="row p-3 content-link-eleve">          
                    <div className="col-md-5 divDonneEleve">
                      <h5 className="mt-3 font-weight-bold">{eleve.prenom} &nbsp; {eleve.nom}</h5>
                      <div className="row mb-1 justify-content-lg-start">
                        <div className="col-md-6  text-info">Classe arabe:</div>
                        <div className="col-md-5 font-weight-bold">{eleve.classeAR}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-6  text-info">Classe Français:</div>
                        <div className="col-md-5 mb-2 font-weight-bold">{eleve.classeFR}</div>
                      </div>
                    </div>
                </div>

                <div className="row justify-content-center mx-2">
                    <div className="col-md-12 content-link-eleve d-flex justify-content-between">
                     <div className="buton " style={{marginLeft: 10}}>
                         <Link className="link m-2"  style={{color: 'black',textAlign: 'center'}} to ={`/emplois-du-temps-eleve/${eleve.id}`}> Emploie du temps</Link>
                    </div>
                            {(eleve.extrait)?
                            <div className="buton ">
                            <Link className="link" style={{color: 'black',textAlign: 'center'}}to ={`/extrait/${eleve.matricule}`} >Voir l'extrait</Link>
                            </div>:
                            <div className="buton " >
                            <Link className="link m-2" style={{color: 'black',textAlign: 'center'}} to ={`/extrait/${eleve.matricule}`} disabled >Voir l'extrait</Link>
                        </div>
                            }
                    <div className="dropdown show">
                    <Link className="link  btn btn-secondary dropdown-toggle" 
                            to="#" role="button" id="dropdownMenuLink" 
                            data-bs-toggle="dropdown" aria-haspopup="true" 
                            aria-expanded="false"
                            style={
                                {backgroundColor: "#e1efe3",
                                    color :'#000', padding:'0 40px',
                                    brderRadius:'20px',
                                    outline : 'none',
                                    border : 'none'
                                }}>
                                Notes
                            </Link>
                            
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <Link className="dropdown-item"
                                    onClick={()=>clickNote(eleve.id, eleve.classeAR_id)}>
                                    {"Classe: "+eleve.classeAR}
                                </Link>
                                <Link className="dropdown-item"
                                    onClick={()=>clickNote(eleve.id, eleve.classeFR_id)}>
                                    {"Classe: "+eleve.classeFR}
                                </Link>
                                
                            </div>
                            </div>
                            
                            <div className="dropdown show">
                                <Link className="link  btn btn-secondary dropdown-toggle" 
                                    to="#" 
                                    data-bs-toggle="dropdown" aria-haspopup="true" 
                                    aria-expanded="true"
                                    style={
                                        {backgroundColor: "#e1efe3",
                                            color :'#000', padding:'0 40px',
                                            brderRadius:'20px',
                                            outline : 'none',
                                            border : 'none'
                                        }}>
                                    Bulletin
                                </Link>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <Link 
                                    className="dropdown-item" to="#"
                                    onClick={()=>click(eleve.classeAR_id, 1, eleve.typeClasseAR,eleve.niveauClasseAR)}>
                                        {eleve.classeAR+' Semestre 1'}
                                </Link>
                                <Link 
                                    className="dropdown-item" to="#"
                                    onClick={()=>click(eleve.classeAR_id, 2, eleve.typeClasseAR,eleve.niveauClasseAR)}>
                                        {eleve.classeAR+' Semestre 2'}
                                </Link>
                                <Link 
                                    className="dropdown-item" to="#"
                                    onClick={()=>click(eleve.classeFR_id, 1, eleve.typeClasseFR,eleve.niveauClasseFR)}>
                                        {eleve.classeFR+' Semestre 1'}
                                </Link>
                                <Link 
                                    className="dropdown-item" to="#"
                                    onClick={()=>click(eleve.classeFR_id, 2,eleve.typeClasseFR,eleve.niveauClasseFR)}>
                                        {eleve.classeFR+' Semestre 2'}
                                </Link>
                                
                            </div>
                        </div>
                        
                        {(profil !== "Enseignent" || profil !== "Parent") &&
                            <div class="buton " >
                                <Link className="link m-2" to = {`/modificationEleve/${eleve.matricule}`}> Modification</Link>
                            </div>
                        }
                    </div>    
                    
                            
                    {/* row */}
                </div>   
                     
            </div>
            </div>
            <br />
            <div className="row justify-content-between mx-0 " >
                <div className="cardEnBat" style={{paddingLeft: '0' ,width: '69%'}}>
                    <h3 className="font-weight-bold ml-3 mx-3">Données Personnelles</h3>
                    <div className="ml-6 ddd">
                    <div className="row ml-3">
                        <div className="col-md-5 ml-3">Matricule:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.matricule}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Prénom et nom:</div>
                        <div className="col-md-7  ml-3 font-weight-bold">{eleve.prenom +' '+eleve.nom}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Date de naissance:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.dateNaiss}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Lieu de naissance:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.lieuNaiss}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Sexe:</div>
                        {(eleve.sexe === "F")?
                          <div className="col-md-7 font-weight-bold">Femme</div>:
                          <div className="col-md-7 font-weight-bold">Homme</div>
                        }
                       
                    </div>
                    <div className="row">
                        <div className="col-md-5">Nom complèt du père:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.nomCompletPere}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Nom complèt de la mère:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.nomCompletMere}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Nom complèt du tuteur:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.nomCompletTuteur}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">Téléphone du tuteur:</div>
                        <div className="col-md-7 font-weight-bold">{eleve.telephoneTuteur}</div>
                    </div>
                </div>               
                </div>
                <div className="cardEnBat" style={{paddingRight: '0', width: '30%'}}>
                    <h3 className="font-weight-bold pl-2">Etat de l'éléve</h3>
                    { (eleve.typeEleve === "Normale") && 
                        <p className="pl-2">Cet élève est non prise en charge
                        
                        </p>
                          
                        // "Avec inscription"
                    }
                    { (eleve.typeEleve === "Prise en charge") && 
                        <p className="pl-2">Cet élève est totalement prise en charge</p>                            
                    }
                    { (eleve.typeEleve === "Avec inscription") && 
                        <p className="pl-2">Cet élève ne paye que les frais d'inscription</p>                            
                    }
                    { (eleve.typeEleve === "En charge personalise") && 
                        <p className="pl-2">Cet élève à une réduction sur le paiement des frais de mensualités</p>                            
                    }
                </div>
                
            </div>
                </>}
            </>}
            <br />
          </Squelette>
    )
}

export default AffichageEleve;