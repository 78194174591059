import React, { useEffect,useState } from 'react'
import './AccueilParent.css'
import { Link,useNavigate} from 'react-router-dom';
import axios from 'axios'
import APP_ENV from "../../config/.env";
import LoadingSpinner from '../helpers/LoadingSpinner';
import {AccountService} from '../services/AccountService'
import { FaPhoneAlt,FaUserAlt} from 'react-icons/fa';
import { AiOutlineMail } from "react-icons/ai";
import {FaBars} from 'react-icons/fa';

function AccueilParent() {
  const [listeEleves, setListeEleves] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const navigate = useNavigate()
    let prenom = localStorage.getItem('prenom')    
    let nom = localStorage.getItem('nom')    
    let profil = localStorage.getItem('profil')  
    let idUser = localStorage.getItem('idUser')  
    let image = localStorage.getItem('avatar')  
    const Logout = () =>{
      AccountService.logout()
      navigate('/')
    }
        useEffect(()=>{
          setIsLoading(true);
            axios.get(`${APP_ENV.baseUrl}/infoEleve/`+idUser, 
            {  headers: header
            })
            .then(res =>{
             setListeEleves(res.data.donnees)
              setIsLoading(false)
            })
        }, [])
  return(
    <>
    {isLoading ? <LoadingSpinner/> : 
    
    <div className='container containerParent mt-5'>

            {/* #### LE PROFIL ##### */}

  {/* ###### Navebar ####### */}
  <div className="navebar" style={{left : '0', right : '0', width : '100%'}}>
 
 <div className="content-navebar-title">
     <FaBars 
     className="icon-humburger" 
    
     />
     <h4 className="navebar-title mx-2">Ecole <span>Abou Oubayda</span> </h4>
 </div>
 
     {/* #### Barre de Recherche ##### */}
       <div className='form-group recherche-content'>
           <input type="text" className='form-control recherche' />
       </div>

 <div className=" section-profil">
     {/* #### LES LANGUES ##### */}
     <div className='content-langue mx-2'>
         <select className="form-select langue"
                 aria-label="Default select example"
                 style ={{
                   outline : 'none',
                   width: '70px',
                   border: 'none',
                   }}>
               <option value ="fr">FR</option>
               <option value ="ar">AR</option>
         </select>
     </div>

         {/* #### Profil ##### */}
       <div className="dropdown profil ">
             <div className=" dropdown-toggle avatar mt-2" type="button" data-bs-toggle="dropdown" aria-expanded="true">
             {(image === "null" || image === '') ?
                 // <img className="profilUser" src= {imageProfil} />
                 <img className="profilUser" 
                       src={process.env.PUBLIC_URL + '/images/pp.png'} width="90" />:
                 <img className="profilUser" 
                       src={`${APP_ENV.urlAvatar}/avatar/${image}`} width="90" /> 
                 }
             </div>
             <ul className="dropdown-menu">
               <li>
                   <Link className="dropdown-item" to = {`/profilUser/${idUser}`}>
                     Mon profil
                   </Link>
               </li>
               <li>
                 <button onClick = {Logout} className="dropdown-item">
                     Déconnexion
                 </button>
               </li>
           
             </ul>
         </div>
         <div className='profil-nom'>
             <p className='nomProfil'>{prenom.substr(0,1).toUpperCase() +". "}{nom}</p>
             <p className='profil-profil'>{profil}</p>
         </div>
     </div>

</div>
     
        <div className="row mt-5">
           
                    <div className='col-md-12 baniereAccueilParent mt-3 '>
                        <h2 className='title'>BIENVENUE CHEZ GROUPE SCOLAIRE ISLAMIQUE <br />FRANCO - ARABE ABOU OUBAYDA </h2>
                        <p className='adresse'>Parcelles Assainies <span>Unité : 26</span></p>
                    </div>
        </div>
  <fieldset className =" fieldset mt-5" >
  <div className="row ">

          <h3 className='tilte-fieldset-parant'
          style = {{width : 'fit-content', padding: '0'}}>Liste  des eleves</h3>
          <table className='table'>
            <thead>
            <tr style={{background :'#268d36', color:'#fff'}}>
            <th style={{width: '5px'}}>N°</th>

            <th>Matricule</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Cl Arabe</th>
            <th>Cl Frabe</th>
            <th>Detail</th>
            </tr>
            </thead>
              <tbody>
              {
              listeEleves
              .map((post,index) => ( 
              <tr>
                  <td>{index+1}</td>
                  <td>{post.matricule}</td>
                  <td>{post.prenom}</td>
                  <td>{post.nom}</td>
                  <td>{post.classeAR}</td>
                  <td>{post.classeFR}</td> 
                  <td>

                  <Link 
                        to ={`/eleve-profil/${post.matricule}`} 
                        title = 'Profil Eleve'
                        >
                        <FaUserAlt className='action-icon'/>
                      </Link>
                  </td>
                  {/* <td style={{width: 100}}><input type="number" name={index} placeholder="0" onChange={handleChange} className="form-control" style={{width: 70}}/></td> */}
              </tr>
              ))} 
                
              </tbody>
          </table>
        </div>
  </fieldset>
  <div className="row mt-4 content-contact"  >
    <p  className = 'contact-title' style={{width:'100%'}}>
      Nos Contacts
    </p>

  </div>
  <div className="row mt-4" >
    <div className="col-md-6">
        <p style={{fontSize:'30px', color:'#268d36'}}> 
          <FaPhoneAlt className='action-icon' style={{color:'#268d36',height:'100px',width:'30px'}}/>
          33 876 54 32
        </p>
    </div>
    <div className="col-md-6 d-flex justify-content-end">
      <p style={{fontSize:'30px', color:'#268d36'}} >   
          <AiOutlineMail className='action-icon' style={{color:'#268d36',height:'100px',width:'30px'}}/> 
          abououbayda26@gmail.com
      </p>
    </div>

  </div>
      </div>
       } 
        
    </>
  )
}
export default AccueilParent;
