import React from 'react'
import './DetailAccueil.css'
import { AiFillCaretLeft } from "react-icons/ai";

import { Link } from 'react-router-dom';


export default function DetailAccueil() {
  return (
    <div className='container-fluid'>
        <div className="row">
            <div className='col-md-6 left'>
                <Link to='/accueil' title = "Retour a l'accueil">
                    <AiFillCaretLeft className='icon-polygon'/>
                </Link>
                <h2>GROUPE SCOLAIRE ISLAMIQUE <br /> FRANCO  ARABE <br />Abou Oubayda</h2>
            </div>
            <div className='col-md-6 right'>
                <h2>Les informations</h2>
                <p><span>Nom de l’école: </span>Abou Oubayda "As sakina"</p>
                <p><span>Adresse:</span> Parcelles Assainies U-26</p>
                <p><span>Nom du directeur: </span> Moussa ka</p>
                <p><span>Téléphone:</span> 77 703 86 12 / 78 184 97 75 / 78 466 10 53 </p>
                <p><span>Email:</span> abououbayda26@gmail.com</p>
            </div>
        </div>

    </div>
  )
}
