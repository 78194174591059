import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Classe_create.css'
import { HiChevronDown } from "react-icons/hi";
import APP_ENV from "../../config/.env";


function UpdateClasse() {
  // get id on url 
  let cl = useParams();
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
    let navigate = useNavigate( );
    const [listeNiveaux, setListNiveaux] = useState([]);        
    const [formulaire, setFormulaire] = useState({
      code : '',
      libelle : '',
      niveau_id : '',
      type : '',
    })
  // Recuperation des niveau

  const getClasse = async () => {
    await 
          axios(`${APP_ENV.baseUrl}/find-classe/${cl.id}`,
      
            {headers: header}
            ).then(res=>{
              if(res.status == 200){
                setFormulaire(res.data.classe)
              }
            })
            .catch(er =>{
            })
  }

  const listNiveau = async () => {
    const res = await fetch(`${APP_ENV.baseUrl}/niveau-list`,

     { headers: header}
      )
      const data = await res.json()
      if(data){
        setListNiveaux(data.niveaux)
      }else{
      }
    }

    useEffect(()=>{
      getClasse();
      listNiveau()
    },[])

    const [error, setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire ,[name]:value})
    }
    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{
      e.preventDefault();
    axios.post(`${APP_ENV.baseUrl}/classe-update/${cl.id}`,formulaire,

      {headers: header}
      ).then(res=>{
        if(res.status === 200){
          navigate('/classe-liste')
      
        }
      })
      .catch(er =>{
        let ereur = er.response.data.error
        setError(ereur)

      })
    }     
    return (

<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
      <fieldset className = 'fieldset'>
        <h3 className='tilte-fieldset user'>Modification classe </h3>
        <div className="row justify-content-between">
          
          <div className="inputboite " style={{width: '46%'}}>
                <input type="text" placeholder="Libelle de la classe" name='libelle' value={formulaire.libelle} onChange = {hundeChange} required/>
          </div>
          <div className="inputboite  " style={{width: '46%', marginRight: '40px'}} >
              <input type="text" placeholder="Code" name='code' value={formulaire.code} onChange = {hundeChange} required/>
          </div>

          <div className="inputboite col-md-11" >
              <div className="row">
                <div className="col-md-12 m-2 " >
                  {/* <HiChevronDown className='icon-chevron'/> */}
                  <select name="niveau_id" onChange = {hundeChange} className = '' required style={{marginLeft : 15}}>
                    {listeNiveaux.map((niveau, index ) => (
                      (formulaire.niveau_id === niveau.id) ? <option value={niveau.id} selected>{ niveau.libelle}</option>
                      : <option value={niveau.id} >{ niveau.libelle}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 m-2">
                  {/* <HiChevronDown className='icon-chevron'/> */}
                  <select name="type" onChange = {hundeChange} className = '' required>
                    {(formulaire.type === "FR") && <option value='FR' selected>Classe français</option>}
                    {(formulaire.type === "AR") && <option value='AR' selected>Classe arabe</option>}
                     <option value='FR' selected>Classe français</option>
                     <option value='AR' selected>Classe arabe</option>
                  </select>
                </div>
              </div>                      
          </div>                    
        </div>
        {error !== '' ? 
          <h2 className='alert alert-danger text-center'>{error}</h2>
        : '' 
        }   
        <div className="col-md-12 conteneur-btn btnAjoutUser ">
            <button className='btn1-connexion enregistrer'>Modifier</button>
            <button className='btn1-connexion annuler'>Annuler</button>
        </div>

      </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default UpdateClasse;