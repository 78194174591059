import React, { useEffect,useState } from 'react'
import axios from 'axios'
import APP_ENV from "../../config/.env";
import { WiDirectionLeft, WiDirectionRight } from 'react-icons/wi';
import { HiChevronDown } from 'react-icons/hi';
import LoadingSpinner from '../helpers/LoadingSpinner'

export default function ListNoteEleveSamestre({datas}) {
  
    const [listNotes , setListeNotes ] =  useState([])
    const [postsParPages, setPostsParPages] = useState(10);
    const [pageCourant , setPageCourant] = useState(1);
    const numberOfPage = Math.ceil(listNotes?.length / postsParPages)
    // const postsCourrent = dataNotes?.slice(premierePage, dernierPost);
    const [value, setValue] = useState('') 
    
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [isLoading, setIsLoading] = useState(false);
    const [recherche, setRecherche] = useState('')   
    const [niveau, setNiveau] = useState('')   
    const [nomComplet, setNomComplet] = useState('')   
    
    const [timeOut, setTimeOut] = useState(null)
    
    const listNoteEleveSemestre = async () => {
        setIsLoading(true);
        await 
        axios(`${APP_ENV.baseUrl}/listNoteEleveSemestre/${datas.eleve_id}/${datas.classe_id}`,
        {headers: header}
        ).then(res=>{
            //if(res.status === 200){
                setListeNotes(res.data.data)
                setNomComplet(res.data.data[0])
             
               setNiveau(res.data.data[0].niveau);  
           // }
            setIsLoading(false);
        })
        .catch(error =>{
        })
    }
      useEffect(()=>{
        
        listNoteEleveSemestre();
       
    },[])

    const hundeRecherche =(ev)=>{
        const name = ev.target.name;
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
    }
    const hundleRecherche = (ev) =>{    
        ev.preventDefault();
        axios(`${APP_ENV.baseUrl}/listNoteEleveSemestre/${datas.eleve_id}/${datas.classe_id}/${recherche.semestre}`
        ,{headers: header}
        ).then(res=>{
            
            setListeNotes(res.data.data)
        })
        .catch(er =>{
        //   let ereur = er.response.data.error
          //setError(ereur)
        })
    }

  return (
    
       <>
       {isLoading ? <LoadingSpinner/> : 
            <>
      <form onSubmit={hundleRecherche} >
        <>
        <div className='row d-flex justify-content-between ml-3 mr-3'>
            <div>
                <h5>Nom complet: {nomComplet.prenom} {nomComplet.nom}</h5>  
            </div>
            <div className=''>
                { (nomComplet.type === "AR") ? 
                    <h5>{"Classe: "+nomComplet.classe +" / Arabe"}</h5>
                  : <h5>{"Classe: "+nomComplet.classe +" / Français"}</h5>
                }
            </div>
        </div>
        </>
            <div className="row">
                <div className="col-md-3 inputSelect">
                    <HiChevronDown className='icon-chevrone mt-3'/>
                    <select name="semestre" value={recherche.semestre} onChange = {hundeRecherche}>
                    <option>Choisir une semestre</option>
                        <option value="1">Première semestre</option> 
                        <option value="2">Deuxième semestre</option> 
                    </select>
                </div>  
                
                <div className=" inputboite col-md-4 mt-4 d-flex justify-content-center">
                    <button className='btn-classe h-75'>Rechercher</button>
                </div>  
            </div> 
    
        </form>

        <br/>
        <div className="col-md-12" id='printablediv'>
          <fieldset className = 'fieldset'>
        <div className="row">
            <div className="col-md-6 form-group">
            
                <select aria-label="Default select example" className="w-25 form-control" 
                        onChange={(e) => setPostsParPages(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
            <div className="col-md-6">
                <div className="form-group row justify-content-end mb-4">
                <input  type="text" 
                    className="form-control col-md-5" 
                    placeholder='Rechercher...'
                    value = {value}
                    onChange={(e)=>setValue(e.target.value)}
                    />
                </div> 
            </div>
        </div>

            <h3 className='tilte-fieldset user'>Fiche des notes </h3>
            <table className='table table-bordered table-hover' >
        <thead>
            <tr style={{textAlign: "center", background :'#268d36', color:'#fff'}}>
            <th style={{width: '5px'}}>N°</th>
            
           
            <>
            {/* {listNotes.map((post) => (  */}

            {  (niveau === 'Primaire')? <>
    
            <th>Matière</th>
             <th>Composition</th>
             {/* <th> Note semestre</th> */}
             </>:
             <>
             
            <th>Matière</th>
            <th>Dev N°1</th>
            <th>Dev N°2</th>
            <th>Composition</th>
            <th>Note semestre</th>
        </>
          
        }
        </>  
            </tr>
        </thead>
        <tbody>
            {
             listNotes ? listNotes.filter(el => (
                el.matricule.toLowerCase().includes(value) 
                || el.prenom.toLowerCase().includes(value) 
                || el.nom.toLowerCase().includes(value) 
            ))
            .map((post,index) => ( 
            <tr style={{textAlign: "center"}}>
                <td>{index+1}</td>
               
                <td>{post.matiere}</td> 
                {(post.niveau !== 'Primaire')&&
                <>
                {(post.dev1 === null )?
                 <td>-</td>:
                 <td>{post.dev1}</td>
                }
                 {(post.dev2 === null )?
                 <td>-</td>:
                 <td>{post.dev2}</td>
                }
                {(post.compo === null )?
                 <td>-</td>:
                 <td>{post.compo}</td>
                }
                <td>{post.Notesemestre} </td>
                </>
                 }
                 
                {(post.niveau === 'Primaire')&&
                     <td>{post.compo} </td>
                }
              
            </tr>
             )): <></>} 
              
        </tbody>
        </table>
                {/* <DataTableListNoteParClasse data ={dataNotes}/>       */}
          </fieldset>
          </div>
          {listNotes?
          <div className="row justify-content-between">
            <div className="col-md-4">
                <div className="text-start"> {pageCourant} / {numberOfPage} de  {listNotes.length} Entres</div>
            </div>

            <div className="col-md-2 mr-5">
                <nav aria-label="Page navigation example"> </nav>
                    <div className="btn-group " role="group" aria-label="Basic example">
                        {
                            pageCourant === 1 ? null : (
                            <button type="button" style={{width :'110px'}} class="btn btn-outline-secondary" onClick={() => setPageCourant(v => v - 1)}>
                            <WiDirectionLeft className=""/>
                            Previous
                            </button>
                            )
                        }
                            <button type="button" class="btn btn-primary" >{pageCourant}</button>
                            {
                            numberOfPage === pageCourant ? null : (
                            <button type="button" class="btn btn-outline-secondary" style={{width :'90px'}} onClick={() => setPageCourant(v => v + 1)}>
                            Next
                            <WiDirectionRight  className=""/>
                            </button>
                            )
                            }
                    </div>
            
                </div>
            </div>
            : <></>}
            </>}
            </>
  )

}
