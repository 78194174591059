import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import DataTableEleve from './DataTableEleve';
import { AiFillPrinter } from "react-icons/ai";
import { Link } from "react-router-dom";
import LoadingSpinner from '../helpers/LoadingSpinner'
import './Eleve.css'


export default function ListEleves() {
    const [dataEleve, setDataEleve] = useState([])
    const [isLoading, setIsLoading] = useState(false);
       
   
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    
       const eleveList = async () => {
        await
           axios
           .get(`${APP_ENV.baseUrl}/eleves-liste`, {
             headers: header,
           })
           .then(res =>{
             setDataEleve(res.data.eleves)
            setIsLoading(false)
           })       
       }
       useEffect(() => {
        setIsLoading(true)
        eleveList();
       },[]);

      const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = 'Liste des niveaux';
        window.print();
       document.body.innerHTML = originalContents; 
      }
      
  return (
    <Squelette>
        {isLoading ? <LoadingSpinner/> : 
        <>
      <div className="container-fluid content-tab col-md-12 mt-5">
          <div className='row justify-content-end' >
              <div className="col-md-8 content-link-eleve d-flex justify-content-end">
            <div className="mb-4">
              <Link to="/eleveArchive" className='btn-classe mb-2 mr-3'>Élèves archivés</Link>
              <Link to="/inscription" className='btn-classe mb-2 mr-3'>Nouvelle enregistrement</Link>
              <Link className='btn-classe font-weight-bold' onClick={Print} style={{cursor: "pointer", color: "white"}}> <AiFillPrinter/> Imprimer</Link>
              </div>
          </div>
        </div>
        <div className="col-md-12 mb-5" id='printablediv'>
          <fieldset className = 'fieldset'>
            <h3 className='tilte-fieldset user'>Liste des élèves</h3>
                <DataTableEleve data ={dataEleve}/>      
          </fieldset>
        </div>
    </div>
          
      </>}

    </Squelette>
  )

}
