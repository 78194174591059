import React, {useState} from 'react';
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";

export default function DataTableTarification({data}) {

    const [postsParPages, setPostsParPages] = useState(10);
    const [pageCourant , setPageCourant] = useState(1);
    const dernierPost = pageCourant * postsParPages;
    const premierePage = dernierPost - postsParPages;
    const numberOfPage = Math.ceil(data?.length / postsParPages)
    const postsCourrent = data.slice(premierePage, dernierPost);
    const [value, setValue] = useState('') 

  return (
    <div>
        <div className="row justify-content-between">
            <div className="col-md-6 form-group">
            
                <select aria-label="Default select example" className="w-25 form-control" 
                        onChange={(e) => setPostsParPages(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                </select>
            </div>
            <div className="col-md-2">
                <div className="form-group row justify-content-end mb-4">
                <input  type="text" 
                    className="form-control col-md-5" 
                    placeholder='Rechercher...'
                    value = {value}
                    onChange={(e)=>setValue(e.target.value)}
                    />
                </div> 
            </div>
        </div>
            
            <div className="table-responsive">
        <table className='table table-bordered table-hover' >
        <thead>
            <tr style={{background :'#268d36', color:'#fff'}}>
            <th style={{width: '5px'}}>#</th>
            
            <th style={{width: '40px'}}>Année scolaire</th>
            <th style={{width: '80px'}}>Montant inscription</th>
            <th style={{width: '20px'}}>Montant mensualité</th>
            </tr>
        </thead>
        <tbody>
            {postsCourrent
            .filter(el => (
                el.annee_scolaire.toLowerCase().includes(value) 
            ))
            .map((post,index) => (
            <tr key={index}>
                <td>{index+1}</td>
                <td>{post.annee_scolaire}</td>
                <td>{post.montantInscription}</td>
                <td>{post.montantMensualite}</td>
            </tr>
            ))}
        </tbody>
        </table>
        </div>

        <div className="row justify-content-between">
            <div className="col-md-4">
                <div className="text-start"> {pageCourant} / {numberOfPage} de  {data.length} Entres</div>
            </div>

            <div className="col-md-2">
                <nav aria-label="Page navigation example"> </nav>
                    <div className="btn-group " role="group" aria-label="Basic example">
                        {
                            pageCourant === 1 ? null : (
                            <button type="button" style={{width :'110px'}} class="btn btn-outline-secondary" onClick={() => setPageCourant(v => v - 1)}>
                            <WiDirectionLeft className=""/>
                            Previous
                            </button>
                            )
                        }
                            <button type="button" class="btn btn-primary" >{pageCourant}</button>
                            {
                            numberOfPage === pageCourant ? null : (
                            <button type="button" class="btn btn-outline-secondary" style={{width :'90px'}} onClick={() => setPageCourant(v => v + 1)}>
                            Next
                            <WiDirectionRight  className=""/>
                            </button>
                            )
                            }
                    </div>
                </div>
            </div>

        </div>
    
   
   
  )
}
