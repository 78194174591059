import React, {useState, useEffect } from 'react'
import axios from 'axios'
import Squelette from '../squelette/Squelette'
import BudgetLibelle from './BudgetLibelle'
import './DetailPaiement.css'
import TitreDetailPaiement from './TitreDetailPaiement'
import { AiFillPrinter } from "react-icons/ai";
import APP_ENV from "../../config/.env";
// import { useReactToPrint } from 'react-to-print'

export default function DetailPaiement(anneeID) {
    const [detailBudget, setDetailBudget] = useState({})
  
        const header = {
          Accept : "application/json", 
          "Content-Type" : "application/json",
          WithCredentials : true,
          Authorization: "Bearer " + localStorage.getItem("token"),
        }

        const detailBudgets = async () => {
          if(anneeID.anneeID > 0){
            await axios(`${APP_ENV.baseUrl}/detail-budget/${anneeID.anneeID}`,{
              method: "GET",
              headers: header
              })
              .then(res =>{
                const detailBudget = res.data.datas
                setDetailBudget(detailBudget)
              }) 

          }else{
            
            await axios(`${APP_ENV.baseUrl}/detail-budget`,{
              method: "GET",
              headers: header
              })
              .then(res =>{
                const detailBudget = res.data.datas
                setDetailBudget(detailBudget)
              }) 
          }
           
        }
        useEffect(()=>{
                detailBudgets();              
                    
        }, [])

    const title1 = 'Détails paiements des trois trimestres'
    const title2 = 'Détails des apport entrants/ sortants'
    const title3 = 'Détails inscriptions et réinscriptions'
    const Print = () =>{     
      let printContents = document.getElementById('printablediv').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      document.title = 'Liste des niveaux';
      window.print();
     document.body.innerHTML = originalContents; 
    }
  //   const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });


  return (
    <Squelette>
      {/* <button onClick={handlePrint}>Print this out!</button> */}
    <div className='container-fluid bb' 
          id='printablediv' 
          // ref={componentRef}
          >
       <div className="row">
            <div className="col-md-12 entete-detail">
                <h3 className='title_entete-detail'>Détail sur les inscriptions et mensualités</h3>
            </div>
            <TitreDetailPaiement title={title1} classname ='ligne-detail detail1'/>
           
                  
           
            <BudgetLibelle
                classname = 'card-libelle trimeste' 
                titleCard={'Première Trimestre'} 
                montant = {detailBudget.trimestre1}
                classBs = 'col-md-4'
            />
            <BudgetLibelle
                classname = 'card-libelle trimeste' 
                titleCard={'Deuxième Trimestre'} 
                montant ={detailBudget.trimestre2}
                classBs = 'col-md-4'
            />
            <BudgetLibelle
                classname = 'card-libelle trimeste' 
                titleCard={'Troisième Trimestre'} 
                montant ={detailBudget.trimestre3}
                classBs = 'col-md-4'
            />
         

            <TitreDetailPaiement title={title2} classname ='ligne-detail detail2'/>
            <BudgetLibelle
                classname = 'card-libelle aport' 
                titleCard={'Apports entrants'} 
                montant ={detailBudget.apportEntrant}
                classBs = 'col-md-5'
            />
            <div className="col-md-2"></div>
            
             <BudgetLibelle
                classname = 'card-libelle aport' 
                titleCard={'Apports sortants'} 
                montant ={detailBudget.apportSortant}
                classBs = 'col-md-5'
            /> 
            <TitreDetailPaiement title={title3} classname ='ligne-detail detail3 justify-content-center '/>
            <BudgetLibelle
                classname = 'card-libelle insc justify-content-center' 
                titleCard={' Inscriptions / Réinscriptions'} 
                montant ={detailBudget.totalInscrption}
                classBs = 'col-md-5'
            />
            <TitreDetailPaiement  classname ='ligne-detail detail4 '/>

            <div className="col-md-12 mt-4 pt-2 footer-detail ">
                <h4 className='title_footer-detail'>Budget réel (entrant - sortant)</h4>
                <h4 className='somme_entete-detail'>
                  {detailBudget.totalInscrption+
                   detailBudget.apportEntrant +
                   detailBudget.trimestre1+
                   detailBudget.trimestre2+
                   detailBudget.trimestre3} - {detailBudget.apportSortant}
                
                </h4>
                <h4 className='montant_entete-detail'>{`${detailBudget.budgetReel} Fcfa`} </h4>
            </div>

            <div className ='groupeBtn mt-4'>
                    <button type = 'button' className='btn-detail btn-imprimer' onClick={Print} >
                      <AiFillPrinter/> Imprimer
                    </button> 
                    {/* <button type = 'button' className='btn-detail btn-reset'>Annuler</button> */}
            </div>
            
       </div>
    </div>
    </Squelette>
  )
}
