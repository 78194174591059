import React from 'react'
import './BulletinNoteP1.css'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
import BulletinNoteClassePA from './BulletinNoteClassePA';
import { BsFillPrinterFill } from "react-icons/bs";
import { Link} from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';


export default function BulletinNoteClasseMA({donnee}) {
    //getBulletinMoyenSecondaire
    // let classe_id = useParams();

    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [classe, setClasse] = useState([]);
    const [data] = useState({});
    const [niveau, setNiveau] = useState({});
    const [donnees, setDonnees] = useState([]);
    const anneeScol =  localStorage.getItem("anneeScolaire");

    // const [isLoading, setIsLoading] = useState(false);
    
    useEffect(()=>{
        lastAnneeScol()
    } ,[])
    const lastAnneeScol = async() =>{
        await  axios(`${APP_ENV.baseUrl}/lastAnnee`,
        {headers: header}
        ).then(res=>{
        
            donnee.annee_id = res.data.annee.id
       
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
            // console.log(ereurs)
        })
       
    }  

const bulletineleveclasse = async() =>{
    donnee.annee_id = anneeScol

    await
    axios.post(`${APP_ENV.baseUrl}/bulletin-eleve-classe`,donnee, 
    {headers: header}
    ).then(res=>{
  
       setDonnees(res.data.data)
           
        setClasse(res.data.data[0].classe.type)
        setNiveau(res.data.data[0].niveau)
        setAnneeScolaire(res.data.data[0].anneeScolaire.code)     
    })
    .catch(error =>{
    })
}
   
    useEffect(()=>{
        lastAnneeScol();
        bulletineleveclasse();
     
    },[])
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }
    
    let profil = localStorage.getItem('profil')  
    
  return (
      <Squelette >
        {( niveau === 'Primaire' && classe === 'AR') ?
            <BulletinNoteClassePA donnees={donnees}/>
            :
            <>
        <div>
        <div className ='container'>
            {(profil === 'Admin') &&
                <div className="Rectangle-180 pl-3 pr-3 mb-4 " style={{marginRight: '300px'}} >
                    <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
                </div>
            }
        <div id="printablediv" >

        {donnees ? donnees.map((donnee,index) => (
        
        // <div className="" style={{height:'1050px', borderBottom: '10px solid black;'}} >
        <div className="page" >
        
        <div className="herder-bulletin ">
            <div className="col-md-4">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>Ecole Franco-Arabe</small>
            </div>
            <div className="col-md-4">
                  <small className=' d-flex justify-content-end'>السنة الدراسية : {anneeScolaire} </small>
              <small className=' d-flex justify-content-end'> الفترة : {donnee.semestre}</small>
               
            </div>
    
        </div>
        <div className="row borederTitle mb-2 ms-0 pb-1">
            <h4 className='title-bultin'>Bulletin de Note / <span>كشف الدرجات</span></h4>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {donnee.eleve.prenom}</small><br/>
                {/* <small>Né(e): {donnee.eleve.dateNaiss}</small><br/> */}
                <small>Matricule: {donnee.eleve.matricule}</small>
            </div>
            <div className="col-md-4 mt-3">
                <small className="d-flex justify-content-center"> à {donnee.eleve.lieuNaiss}</small>
                <small className="d-flex justify-content-center">Nombre d'élève: {donnee.rang.effectif}</small>
            </div>
            <div className="col-md-4 px-3">
                <small className="d-flex justify-content-end">Nom: {donnee.eleve.nom}</small>
                <small className="d-flex justify-content-end">Classe: {donnee.classe.code}</small>
                <small className="d-flex justify-content-end">Classe Redoublée: 0</small>
            </div>
        </div>
        <table className='table table-bordered '>
            <thead>
                <tr className='entete-tableau' style={{fontSize: '12px', textAlign: 'center'}} >
                    <th>Appréciation / التقدير</th>
                    <th>معدل X</th>
                    <th>Coéfs/معامل</th>
                    <th>معدل/20</th>
                    <th>Comp / إختبار </th>
                    <th>Devoir / واجبات</th>
                    <th>Matières / المواد</th>
                </tr>
            </thead>
            <tbody id='ligneTab' >       
                  {donnee.matieres ? donnee.matieres.map((mat,index) => (
                <>
                 <tr key={index} style={{fontSize: '11px', textAlign: 'center'}} >
                    <td>{mat.appreciationNote}</td>  
                    <td>{mat.moyenx}</td>  
                    <td>{mat.coef}</td>
                    <td>{mat.moyenMatiere}</td>
                    <td>{mat.compo}</td>
                    <td>{mat.devoir}</td>
                    <td>{mat.libelle}</td>
                 </tr>
                 </> 
                    )): <></>} 
                <tr style={{fontSize: '11px', fontWeight: 'bold', textAlign: 'center'}} >
                   <td></td>
                   <td >{donnee.sommeNotes}</td> 
                   <td>{donnee.sommeCoefs}</td> 
                   <td colSpan={2}></td>
                   <td colSpan={2}>مجموع الدرجات </td> 
                </tr> 
                <tr style={{fontSize: '11px', fontWeight: 'bold', textAlign: 'right'}} >
                    <td colSpan ={''}>0 : الغيابات</td>
                    <td colSpan ={2}>0 : التأخرات</td>
                    <td colSpan ={'2'}>
                    { donnee.rang.position >1 ?
                        <>  { " "+donnee.rang.position + ' ème '} </>:
                        <> 
                           { " "+donnee.rang.position + ' ère '}
                        </>
                    }
                    : Rang / الترتيب
                    </td>
                    <th colSpan ={2}>{donnee.moyen.valeur+" / "+donnee.moyen.damander} : Moyenne/معدل </th>
                </tr>
            </tbody>
        </table>
        <div className='row justify-content-between '>
            <div className="col-4">
            {donnee.semestre === "الاولى" ?
            <table className='table table-bordered col-md-4'>
                <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                    <tr>
                        {(donnee.moyen.valeur >= 14 && donnee.moyen.valeur <= 20)?
                        <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                        <th>يجب أن تستمر الجهود</th>
                    </tr>   
                    <tr>
                        {(donnee.moyen.valeur >= 10 && donnee.moyen.valeur < 14 )?
                        <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                        <th>يمكن أن تفعل ما هو أفضل</th>
                    </tr>
                    <tr>
                        {(donnee.moyen.valeur >= 9 && donnee.moyen.valeur < 10 )?
                        <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                        <th>غير كافٍ</th>
                    </tr>
                    <tr>
                        {(donnee.moyen.valeur < 9 && donnee.moyen.valeur >= 6 )?
                        <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                        <th>في خطر تكرار الفصل</th>
                    </tr>
                    <tr>
                        {(donnee.moyen.valeur < 6)?
                        <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                        <th>في خطر طرض من المدرسة</th>
                    </tr>
                </tbody>
            </table>
            : <table className='table table-bordered col-md-4'>
                <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                    <tr style={{fontSize: '12px'}} >
                        <td>{donnee.moyenSemestre1+" / 20"}</td>
                        <th> معدل فترة الأولى</th>
                    </tr>   
                    <tr style={{fontSize: '12px'}} >
                        <td>{donnee.moyen.valeur+" / "+donnee.moyen.damander}</td>
                        <th> معدل فترة الثانية </th>
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <td>{donnee.moyenAnnuelle+" / 20"}</td>
                        <th>معدل العام</th>
                    </tr>
                </tbody>
            </table>
            }
            </div>
                <div className="col-4">
                  
                <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 14 && donnee.moyen.valeur <= 20 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>Félicitations/ تهنئة</th>
                        </tr>   
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 11 && donnee.moyen.valeur < 14 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>ينفخ فيه الحيوية و النشاط</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.rang.position <= 5 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>في قائمة الشرف</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 8 && donnee.moyen.valeur < 11 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>تحذير لإعادة الفصل</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur < 8 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>اللوم لنتيجته سيئة </th>
                        </tr>
                    </tbody>
                </table> 
                
                </div>
                <div className='signature'>
                    <div style={{margin: 0}} className=''>
                        <p className=''>قرار مجلس المدرسين <br/>Observation du conseil professeurs</p>
                        <textarea rows="3" cols="36">
                        </textarea>
                    </div>
                    <div style={{textAlign: 'end', paddingLeft: '100px'}} className=''>
                        <p className=''>مدير المدرسة <br/>Le Chef d'Etablissement </p>
                    </div>
                </div>
        </div>
        </div>
        )): <></>}
      </div>
    </div>
    </div>
 </>
 }
</Squelette>
  )
}
