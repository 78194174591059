
import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import DataTableSurveillent from "./DataTableSurveillent";
import { Link} from 'react-router-dom';
import LoadingSpinner from '../helpers/LoadingSpinner'

export default function ListSurveillent() {

    const [dataSurveillent, setDataSurveillent] = useState([])
    const [isLoading, setIsLoading] = useState(false);
        
      const header = {
        Accept : "application/json",
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      const getListSurveillents  = async () => {
        await 
        axios
        .get(`${APP_ENV.baseUrl}/listSurveillent`, {
          headers: header,
        })
          .then(res =>{
            setDataSurveillent(res.data.donnees)    
            setIsLoading(false)    
        })
      }
      useEffect(() => {
        setIsLoading(true)
        getListSurveillents();
        
      }, []);
  return (
    <Squelette>
        {isLoading ? <LoadingSpinner/>: 
      <>
        <div className="row d-flex justify-content-end "> 
          <div className="col-md-6 d-flex justify-content-end content-link-eleve">
              <Link to="/creerSurveillent" className=' btn-classe mr-3 '> Création Surveillent</Link>
          </div>
        </div>
             
        <div className="container-fluid content-tab ">
          <fieldset className = 'fieldset'>
            <h3 className='tilte-fieldset user'>Liste des  Surveillent</h3>
                <DataTableSurveillent data ={dataSurveillent}/>      
          </fieldset>
        </div>

      </>}
    </Squelette>
  )

}
