import React from "react";
import { useState, useEffect} from 'react';
import axios from "axios";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom'; 
import Iframe from 'react-iframe';

export default function Extrait({data}) {
    let id = useParams();
    const header = {
        Accept : "application/json", 
       "Content-Type": "multipart/form-data",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      const [eleve , setEleve] = useState([])
      const [ok , setOk] = useState(false)

   
        const getEleveShow = async () =>{
            await 
                    axios.get(`${APP_ENV.baseUrl}/eleve-show/${id.id}`, {
                        headers: header
                    })
                    .then(res=>{
                    if(res.data.eleve.extrait){
                        setEleve(res.data.eleve.extrait);
                        setOk(true);
                    } 
                })
                .catch(er =>{
                        // console.log(er);
                        //   let ereur = er.response.data.errors.messag
                    })
        }
        useEffect(()=>{
            getEleveShow();
        } )
    return (
        <Squelette>
            {(ok)?
             <Iframe url={`${APP_ENV.urlAvatar}/extrait/${eleve}`}
             width="100%"
             height="920px"
             id=""
             className=""
             display="block"
             position="relative"/> :
             <p>
              <div className="col-md-10 m-3 p-3 border border-success">
                            <p className="d-flex justify-content-center ">
                                Cette élève n'as pas encore d' extrait de naissance dans la base de donné
                            </p>
                        </div> 
             </p>
        
        }
                   
        </Squelette>
    )
}