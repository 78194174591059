import React from 'react'
import AjoutUser from './AjoutUser'
import Squelette from '../squelette/Squelette'


export default function SqAjoutUser() {
  return (
    <div>
        <Squelette>
            <AjoutUser/>
        </Squelette>

    </div>
  )
}
