import React, {useEffect, useState} from 'react';
import { BsPencilSquare } from "react-icons/bs";
import { Link} from 'react-router-dom';
import axios from 'axios'
import { FaUserAlt} from 'react-icons/fa';
import { AiFillDelete } from "react-icons/ai";
import APP_ENV from "../../config/.env";
import ModalConfirm from './ModalConfirm'
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import ReactPaginate from 'react-paginate';

export default function DataTableEleveArchive({data}) {








  function filteration(datas, values) {
    const newarr = datas.filter((item) => {
      if (!values) return true;
      if (
        item.prenom.toLowerCase().includes(value.toLowerCase()) ||
        item.telephone.toLowerCase().includes(value) ||
        item.profil.toLowerCase().includes(value)||
        item.nom.toLowerCase().includes(value.toLowerCase()) 
      ) {
        return true;
      }
      return false;
    });
    return newarr;
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('') 
  const donne = filteration(data,value)
  const [postsParPages, setPostsParPages] = useState(10);
  const [pageCourant , setPageCourant] = useState(0);
  const numberOfPage = Math.ceil(donne?.length / postsParPages)
  const offset = pageCourant * postsParPages;
   const hundleChange=(e)=>{
    setValue(e.target.value)
   }
  function handlePageClick({ selected: selectedPage }) {
    setPageCourant(selectedPage);
  }
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }

  const desarchiveEleve=(userId)=> {
    axios.delete(`${APP_ENV.baseUrl}/desarchive/`+userId, {
      headers: header
    })
    .then(res =>{
      window.location.reload(false)
    })
  }

  const suppressionEleveDefinitif = (eleveID)=> {
  
    setShow(true)
    axios(`${APP_ENV.baseUrl}/suppressionEleveDefinitif/`+eleveID, {
      headers: header
    })
    .then(res =>{
      window.location.reload(false)

      setShow(false)
     
    })
  }

  return (
<div>
             <div className="row justify-content-between">
                <div className="col-md-6 form-group">
                    {/* <div className="mx-3">Entres par page</div> */}
                    <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                </div>
                <div className="col-md-2">
                   <div className="form-group row justify-content-end mb-4">
                    <input  type="text" 
                            className="form-control col-md-5" 
                            placeholder='Rechercher...'
                            value = {value}
                            onChange={hundleChange}
                            />
                  </div> 
                </div>
            </div>
            
                <div className="table-responsive">
         <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff'}}>
                <th style={{width: '50px'}}>N°</th>
                <th style={{width: '120px'}}>Matricule</th>
                <th>Prénom</th>
                <th style={{width: '60px'}}>Nom</th>
                <th style={{width: '120px'}}>Cl Arabe</th>
                <th style={{width: '120px'}}>Cl Français</th>
                <th style={{width: '140px'}}>Actions</th>
              </tr>
            </thead>
            <tbody>
            {(donne.length === 0) ? (
                <tr className={'tablerow'}>
                  <td colspan="7" className={'tabledata'}
                  style = {{textAlign: 'center'}}>
                   Aucun enregistrements trouvés
                  </td>
                </tr>): 
              donne.slice(offset, offset + postsParPages)
              .map((post,index) => (
                <tr key={post.id}>
                  <td>{index+1}</td>
                  <td>{post.matricule}</td>
                  <td>{post.prenom}</td>
                  <td>{post.nom}</td>
                  <td>{post.classeAR}</td>
                  <td>{post.classeFR}</td>
                  <td>
                      <Link 
                        to ={`/eleve-profil/${post.matricule}`} 
                        title = 'Profil Eleve'
                        >
                        <FaUserAlt className='action-icon'/>
                      </Link>
                      <Link 
                        to = {`/modificationEleve/${post.matricule}`}
                        title = 'Modification Eleve'
                        >
                          <BsPencilSquare  className='action-icon'/>
                      </Link>
                      <Link onClick={() => desarchiveEleve(post.id)}
                        title ='Désactivé un(e) élève'
                        style={{color:'#000'}}>
                          <label class="switch">
                            <input type="checkbox"/>
                            <span class="slider round"></span>
                          </label>
                      </Link>
                      <Link 
                        title = "Suppression définitif d'un(e) élève" 
                        onClick={() =>handleShow()}
                        >
                         
                          <AiFillDelete className='action-icon'  data-toggle="modal" data-target="#exampleModal"
                          style={{color:'#c30010', fontSize: 23}}/>
                      </Link>
                      <ModalConfirm
                              show={show}
                              handleClose={handleClose}
                              handleDelete ={()=>suppressionEleveDefinitif(post.id)} 
                            >
                              Etes vous sûr de vouloir supprimer déffinitivement
                      </ModalConfirm>
                 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
               
                  {/* Pagination */}
                  <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
   
        </div>


  )
}
