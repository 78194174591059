import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import './UpdateUser.css'
import '../annee_scolaire/Annee_scolaire_create.css'
import APP_ENV from "../../config/.env";
import ImageUploading from 'react-images-uploading';
import LoadingSpinner from '../helpers/LoadingSpinner'


export default function UpdateUser() {
    let user = useParams();
    const header = {
        Accept : "application/json", 
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }

    const [images, setImages] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formulaire, setFormulaire] = useState({
        nom: '',
        prenom : '',
        email : '',
        telephone : '',
        profil_id : '',
        avatar : '',
    })
    const [erroruser, setErrorUser] = useState('')
    const [profils, setProfils] = useState([])
    const [avatar, setAvatar] = useState('')
    const [previewAvatar, setPreviewAvatar] = useState('')
    //##### Changement des inputs #####
    const maxNumber = 69;
    const onChange = (imageList) => {
      setImages(imageList[0].file);
      setPreviewAvatar(imageList[0])
      
    }
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire, avatar : images})
      if (e.target.type != "file") {
        setFormulaire({...formulaire ,[name]:value})
    } else {
        formulaire.avatar = images
        setPreviewAvatar(e.target.files[0]);
      }
    }
    let navigate = useNavigate( );
    // Recuperation des niveau
    const filUploade = (id) => {
        const formData = new FormData();
        formData.append("avatar", images); // append: (ajout du file)
        axios.post(`${APP_ENV.baseUrl}/uplodeFile/${id}`,formData,{
            headers: header,
        })
    };


    const getProfil = async ()=>{
        setIsLoading(true)
        await 
        axios
        .get(`${APP_ENV.baseUrl}/profils`, 
            {headers: header}
        ).then(res=>{
            setProfils(res.data.donnees)
        })
        .catch(er =>{
            // let ereur = er.response
        })
    }
            // Get user
            const getUsers = async ()=>{
             
                await 
                    axios
                    (`${APP_ENV.baseUrl}/user/${user.id}`, 
                        {headers: header}
                    ).then(res=>{
                        if(res.status === 200){
                            setAvatar(res.data.user.avatar);
                            setFormulaire(res.data.user)
                            setIsLoading(false);
                        }
                    }).catch(er =>{
                        // let ereur = er.response.data.error
                    })
                }
    useEffect(()=>{
        setIsLoading(true)
        getProfil();
        getUsers();
    }, [])
    const hundleSubmit = (e) =>{    
        e.preventDefault();
        setFormulaire({...formulaire, avatar : images})
      // Mis a jour utilisateur 
      axios.post
      (`${APP_ENV.baseUrl}/updateUser/${user.id}`, formulaire,
      {headers: header}
      ).then(res=>{
        filUploade(user.id)
             navigate('/utilisateur-liste')
      })
      .catch(er =>{
        let ereur = er
        setErrorUser(ereur)
      })
    }

    return (
    <div>
        <br/>
        {isLoading ? <LoadingSpinner/> : 
        <>
        <form onSubmit={hundleSubmit} className='container mt-4 formulaire'>
            <fieldset className = 'fieldset'>
         <h3 className='tilte-fieldset user'>Modifier un utilisateur</h3>
                <div className="row">
                <div className="col-md-8">
                    <div className="row">
                        <div className="inputboite col-md-6 ">
                            <input type="text" placeholder="Prénom" className="form-controluser "
                                name='prenom' value={formulaire.prenom} onChange = {hundeChange} required/>
                        </div>
                        <div className="inputboite col-md-6">
                            <input type="text" placeholder="Nom" className="form-controluser "
                                name='nom' value={formulaire.nom} onChange = {hundeChange} required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="inputboite col-md-12 " >
                            <input type="text" placeholder="Email" className="form-controluser "
                                name='email' value={formulaire.email} onChange = {hundeChange} required/>
                        </div>
                    </div>
                    <div className="row">
                            <div className=" inputboite col-md-6">
                                <input type="text" placeholder="Téléphone" className="form-controluser "
                                name='telephone' value={formulaire.telephone} onChange = {hundeChange} required/>
                            </div>
                            <div className="col-md-6 inputboite">
                                    {/* <HiChevronDown className='icon-chevron mt-2'/> */}
                                      {formulaire.profil_id === 4 ?
                                      <>
                                            <select name="profil_id" onChange = {hundeChange} className="form-controluser " >
                                            <option selected disabled>Parent</option>
                                            </select>
                                       </>:
                                       <>
                                      
                                   
                                     <select name="profil_id" onChange = {hundeChange} className="form-controluser " >
                                     
                                    {(profils) ? profils.map((prof, index) => (
                                        
                                        (prof.id === formulaire.profil_id) ? <option value={prof.id} selected>{ prof.libelle}</option>:
                                        <option value={prof.id} >{ prof.libelle}</option>
                                    )) 
                                    : <h4>Pas de profils trouvés</h4>}
                                    </select>
                                    </>
                                    }
                                </div>
                           
                            </div>
                </div>
                <div className='col-md-4 ml-4 d-flex justify-content-center'>

                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    name="avatar"
                    dataURLKey="data_url"
                    acceptType={["jpg","png","jpeg"]}
                >
        {({
            onImageUpdate,
          onImageUpload,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <a  onChange={onChange}
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
             
            </a>
            &nbsp;
              <div  className="image-item mt-4 ml-3">
              {  previewAvatar ?
              <>
              
                <img className="profil-user" src={previewAvatar.data_url} alt="" onChange={onChange}
                    style={{width: '150px', height: '150px',objectFit: 'cover',cursor: 'pointer'}} 
                    title="Cliquer ici pour choisir une image" 
                    onClick={onImageUpload}
                    {...dragProps}
                
                    />

                </>
                    :
                    <img className="profil-user" src={avatar?`${APP_ENV.urlAvatar}/avatar/${avatar}`:process.env.PUBLIC_URL + '/images/pp.png'}
                    style={{width: '150px', height: '150px',objectFit: 'cover', cursor: 'pointer'}}
                    title="Cliquer ici pour choisir une image" 
                    onClick={onImageUpload}
                    {...dragProps}
                    /> 
                }
                    <div className="image-item__btn-wrapper">
                    </div>
                </div>
                {/* ))} */}
            </div>
            )}
                    </ImageUploading>
                </div>
                   
                    <div className="inputboite col-md-8 " >  
                          
                        </div>
                        
                    </div>
                    {erroruser !== '' ? 
                        <h2 className='alert alert-danger text-center'>{erroruser}</h2>
                    : '' 
                    }
                    <div className="col-md-12 conteneur-btn btnAjoutUser">
                        <button className='btn1-connexion enregistrer'>Modifier</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
                      
        
            </fieldset>
        </form>
        </>}
    </div>
  )
}
