import React from 'react'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";

export default function ReclamationNote() {

    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      const [listeClasses, setListClasses] = useState([]);  
      const [listeMatieres, setListMatieres] = useState([]);  
      const [errorClasse, setErrorClasse] = useState('')
      const [errorMatiere, setErrorMatiere] = useState('')
      const [errorNote, setErrorNote] = useState('')
      const [error, setError] = useState('')
      const [success, setSuccess] = useState('')
      const [errorMatricule, setErrorMatricule] = useState('')
      const [errorSemestre, setErrorSemestre] = useState('')
      const [errorEvaluation, setErrorEvaluation] = useState('')
      const [formulaire, setFormulaire] = useState({
        note: '',
        classe_id : '',
        matiere_id : '',
        evaluation : '',
        semestre : '',
        matricule : '',
      })
    // Recuperation infos classe
    const listMatiere = async () => {
        await 
        axios(`${APP_ENV.baseUrl}/listMatiere`, {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setListMatieres(res.data.donnees)
            }
        })
    }

    const  classesliste = async () => {
        await 
    axios(`${APP_ENV.baseUrl}/classes-liste`, {headers: header}
    ).then(res=>{
        if(res.status === 200){
            setListClasses(res.data.classes)
        }
    })
}
    useEffect(()=>{
        listMatiere();
        classesliste();

    },[])

    //##### Changement des inputs #####
    const hundleChange =(e)=>{
        const name = e.target.name;
        const value = e.target.value
        setFormulaire({...formulaire ,[name]:value})
    }
    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
        e.preventDefault();

        if (formulaire.note === '') {
            setErrorNote("Veillez saisir la note")
        }else{
            setErrorNote('')
        }
        if (formulaire.matricule === '') {
            setErrorMatricule("Veillez saisir le matricule")
        }else{
            setErrorMatricule('')
        }
        if (formulaire.semestre === '') {
            setErrorSemestre("Veillez choisir le semestre")
        }else{
            setErrorSemestre('')
        }
        if (formulaire.evaluation === '') {
            setErrorEvaluation("Veillez choisir l'evaluation")
        }else{
            setErrorEvaluation('')
        }
        if (formulaire.classe_id === '') {
            setErrorClasse("Veillez choisir la classe")
        }else{
            setErrorClasse('')
        }
        if (formulaire.matiere_id === '') {
            setErrorMatiere("Veillez choisir le matière")
        }else{
            setErrorMatiere('')
        }
        
        axios.post(`${APP_ENV.baseUrl}/reclamation-note`, formulaire,
        {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setSuccess(res.data.message)
            }
        })
        .catch(error =>{
            setError(error.response.data.message)
        })
    }
    return (
    <Squelette>
        <div>
        <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-10'>
        <fieldset className='fieldset mt-2'>
            {/* Les message d'erreur */}
        {(error) ? <p className='ml-4 text-danger'>{error}</p> : '' } 
        {(success) ? <p className='ml-4 text-success'>{success}</p> : '' } 

        <h3 className='tilte-fieldset user'>Réclamation de note</h3>
            <div className="row ml-5 justify-content-between">
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    <input type="text" placeholder="Matricule de l'élève" name='matricule' value={formulaire.matricule} onChange = {hundleChange} required/>
                    {(errorMatricule)?
                        <p className=' text-danger'>{errorMatricule
                    }</p>:''}  
                </div>
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    {/* <HiChevronDown className='icon-chevron  mt-3'/> */}
                      
                    <select name="evaluation" onChange = {hundleChange} className = '' >
                        <option value="">Type d'évaluation</option>
                        <option value="dev1">Devoir 1</option>
                        <option value="dev2">Devoir 2</option>
                        <option value="compo">Composition</option>
                        <option value={'ressource'}>Ressource</option>
                        <option value={'competence'}>Compétence</option>
                    </select>
                    
                    {(errorEvaluation )  ?
                            
                    <p className=' text-danger'>{errorEvaluation}</p>:
                    ''
                    }
                </div>
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    {/* <HiChevronDown className='icon-chevron  mt-3'/> */}
                    <select name="matiere_id" onChange = {hundleChange} className = '' >
                    <option value="">Choisir matière</option>
                        {listeMatieres.map((matiere, index) => (
                            <option value={matiere.id}>{matiere.libelle+" / "+matiere.type}</option>
                        ))}
                    </select>
                    {(errorMatiere )  ?
                            
                    <p className=' text-danger'>{errorMatiere}</p>:
                    ''
                    }  
                </div>
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    {/* <HiChevronDown className='icon-chevron '/> */}
                    <select name="classe_id" onChange = {hundleChange} className = '' >
                    <option value="">Choisir classe</option>
                        {listeClasses.map((classe, index) => (
                            <option value={classe.id}>{classe.code +" / "+classe.type}</option>
                        ))}
                    </select>
                    {(errorClasse )  ?
                            
                    <p className=' text-danger'>{errorClasse}</p>:
                    ''
                    }  
                </div>
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    {/* <HiChevronDown className='icon-chevron  mt-3'/> */}
                    <select name="semestre" onChange = {hundleChange} className = '' >
                    <option value="">Choisir semestre</option>
                    <option value="1">Première semestre</option>
                    <option value="2">Deuxièle semestre</option>
                    </select>
                    {(errorSemestre )  ?
                            
                    <p className=' text-danger'>{errorSemestre}</p>:
                    ''
                    }  
                </div>
                
                <div className="inputboite col-md-6 d-flex justify-content-between ">
                    <input type="number" placeholder="Note" name='note' value={formulaire.note} onChange = {hundleChange} required/>
                    {(errorNote )  ?
                    <p className=' text-danger'>{errorNote}</p> :
                    ''
                    }
                </div>
                
                
            </div>
            
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                <button className='btn1-connexion enregistrer'>Enregistrer</button>
                <button className='btn1-connexion annuler'>Annuler</button>
            </div>
        
            </fieldset>
        </form>
        </div>
    </Squelette>
    )
}