import React, {useState} from 'react';
import { Link} from 'react-router-dom';
import { FaUserAlt} from 'react-icons/fa';

import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import {FormatDate} from '../helpers/FormatDate'
import ReactPaginate from 'react-paginate';
import { AiFillPrinter } from "react-icons/ai";
import ListeElevesParClasse from './ListeElevesParClasse';

export default function DataTableEleveCl({data}) {

    function filteration(datas, values) {
        const newarr = datas.filter((item) => {
          if (!values) return true;
          if (
            item.prenom.toLowerCase().includes(value.toLowerCase()) ||
            item.matricule.toLowerCase().includes(value.toLowerCase()) ||
            item.nom.toLowerCase().includes(value.toLowerCase()) 
      
          ) {
            return true;
          }
          return false;
        });
        return newarr;
      }
      const [value, setValue] = useState('') 
      const donne = filteration(data,value)
      const [postsParPages, setPostsParPages] = useState(10);
      const [pageCourant , setPageCourant] = useState(0);
      const numberOfPage = Math.ceil(donne?.length / postsParPages)
      const offset = pageCourant * postsParPages;
       const hundleChange=(e)=>{
        setValue(e.target.value)
       }
      function handlePageClick({ selected: selectedPage }) {
        setPageCourant(selectedPage);
      }
  return (
    <div>
        <div className="row justify-content-between">
            <div className="col-md-6 form-group">
            
                <select aria-label="Default select example" className="w-25 form-control" 
                        onChange={(e) => setPostsParPages(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                </select>
            </div>
            
            <div className="col-md-2 justify-content-end">
                <div className="form-group row justify-content-end mb-4">
                <input  type="text" 
                    className="form-control col-md-5" 
                    placeholder='Rechercher...'
                    value = {value}
                    onChange={hundleChange}
                    />
                </div> 
            </div>
        </div>
            
            <div className="table-responsive">
        <table className='table table-bordered table-hover' >
        <thead>
            <tr style={{background :'#268d36', color:'#fff'}}>
            <th>N°</th>
            <th>Matricule</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Date naissance</th>
            <th></th>
            </tr>
        </thead>
        <tbody>
             {donne.slice(offset, offset + postsParPages)
            .map((post,index) => (
            <tr key={post.id}>
                <td>{index+1}</td>
                <td>{post.matricule }</td>
                <td>{post.prenom }</td>
                <td>{post.nom }</td>
                <td>{FormatDate(post.dateNaiss)}</td>
                <td>
                  <Link 
                    to ={`/eleve-profil/${post.matricule}`} 
                    title = 'Profil Eleve'
                    >
                    <FaUserAlt className='action-icon'/>
                  </Link>
                </td>
            </tr>
            ))
            }
        </tbody>
        </table>
        </div>
        {donne.length > 0 ?
                      
            <div className={'datatablefooter'}>
                <div className={'datatableshowing'}>
                  <span>
                    Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                  </span>
                </div>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next "}
                  pageCount={numberOfPage}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  previousLinkClassName={''}
                  nextLinkClassName={''}
                  disabledClassName={'paginationDisabled'}
                  activeClassName={'paginateActive'}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                />
          </div> 
        :<h4 style={{padding: '10px',textAlign:"center"}}>Pas encore d'élève dans cette classe</h4>
        }
    </div>
    
    
   
   
  )
}
