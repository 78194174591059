import React, { useEffect,useState } from 'react'
import './ListeUser.css'
import axios from 'axios'
import DataTablePerant from './DataTablePerant';
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";
import Squelette from '../squelette/Squelette';



export default function ListParent() {
    const [listeUtilisateur, setListeUtilisateur] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }

      const getData = async () => {
     
         await   axios(`${APP_ENV.baseUrl}/getParent`, {
          method: "GET",
          headers: header
          })
          .then(res =>{
            const dataUser = res.data.donnees
            setListeUtilisateur(dataUser)
            setIsLoading(false)
          })       
      }
      useEffect(() => {
        setIsLoading(true)
        getData();
      },[]);


  return (
    <Squelette>
      <div className="container-fluid content-tab ">
         {isLoading ? <LoadingSpinner/>: 
           <>
          
          <fieldset className = 'fieldset'>
         
            <h3 className='tilte-fieldset user'>Liste des parents d'élève</h3>
                <DataTablePerant data ={listeUtilisateur}/>      
          </fieldset>
          </>
        }  
      </div>
      </Squelette>
  )
}