import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
// import './ListeClasse.css'
import Descripteur from '../accueil/Descripteur';
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";


function ListeClasse() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    const [listeClasses, setListeClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Recuperation liste des classes
    
        const listClasse = async () => {
            setIsLoading(true)
            await 
                    // axios(base_url + 'classes-liste',{headers: header}
                axios(`${APP_ENV.baseUrl}/classes-liste`,{headers: header}
            
                    ).then(res=>{
                        if(res.status === 200){
                            setListeClasses(res.data.classes)
                            setIsLoading(false)
                        }
                    })
                    .catch(error =>{
                        // let ereurs = error.response.data.error
                    })
        }
        useEffect(()=>{
            listClasse();
                 },[])
    // Action apres click sur une classe
    function clickClasse(choix){
        if(choix){
            navigate('/emplois-du-temps-classe/'+choix)
        }
    }
    return (
    <Squelette>
         {isLoading ? <LoadingSpinner/> : 
        <>
        <div className=''>
        
            {/* classe-create */}
            <div className="row justify-content-between">
                <div className="col-md-5 ml-5">   <h1>Liste des classes</h1></div>
          
                 <div className="col-md-5 ml-3 d-flex justify-content-center">
            </div>
            </div>
            <div className='row dx-flex justify-content-center'>
                  
                {listeClasses.map((classe, index ) => (   
                    <>
                  {( classe.niveau !== "Primaire" ) &&           
                    // <div className='col-md-2 m-1  p-2 shadow border border-success bloc-classe' onClick={e => clickClasse(classe.id)}>
                    <div className='col-md-2 m-1  p-2 shadow border border-success ' onClick={e => clickClasse(classe.id)}>
                        <Descripteur texForm ={classe.code.substr(0,1).toUpperCase()} text = {classe.code +" / "+classe.type}  color = '#000'
                        colorBg = {classe.couleur} />
                    </div>
                    } 
                    </>
                ))}

            </div>
        </div>
        </>}
    </Squelette>
  )
}

export default ListeClasse;
