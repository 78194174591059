import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
// import "./ProfilUser.css";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
import Descripteur from '../accueil/Descripteur';
import {AccountService} from '../services/AccountService'
import LoadingSpinner from '../helpers/LoadingSpinner'

function ProfilEnseignent() {
    const navigate = useNavigate()
    const Logout = () =>{
        AccountService.logout()
        navigate('/')
    }
    let id = useParams(); 

        const header = {
            Accept : "application/json", 
            "Content-Type" : "application/json",
            WithCredentials : true,
            Authorization: "Bearer " + localStorage.getItem("token"),
        }
        const [profilUser , setProfilUser] = useState('')
        const [avatar , setAvatar] = useState('')
        const [listMatiere , setListMatiere] = useState([])
        const [isLoading, setIsLoading] = useState(false);
        const getElementById = async()=>{
            setIsLoading(true);
            await 
                axios.get(`${APP_ENV.baseUrl}/getEnseignetById/`+id.id, {
                headers: header
            })
            .then(res=>{
             setProfilUser(res.data)

             setAvatar(res.data.avatar)
             setIsLoading(false)

             
        })
        .catch(er =>{
            // let ereur = er.response.data
        })
        }

        const listclassedunenseignent = async()=>{
            setIsLoading(true);
            await 
            axios.get(`${APP_ENV.baseUrl}/list-classe-d-unenseignent/`+id.id, {
                headers: header
                })
            .then(res=>{
                setListMatiere(res.data.datas.classes)
                // setIsLoading(false)
            }).catch(er =>{
                // console.log(er);
                // let ereur = er.response.data.errors.messag
            }) 
        }


        useEffect(()=>{
            listclassedunenseignent();
            getElementById();
           
    },[])

    return (
        <Squelette>
           
          <div className="container">
          {isLoading ? <LoadingSpinner/> : <>
            <div className="entete">

                <div className="col-md-12 enteteVert">
                    <div className="row">
                    
                        <div className="col-md-3">
                        {(avatar === null || avatar === '') ?
                                
                                <img className="profil-user" src={process.env.PUBLIC_URL + '/images/pp.png'} width="90"/>:
                                    <img className="profil-user" src={`${APP_ENV.urlAvatar}/avatar/${avatar}`} width="90" /> 
                            }
                                {/* {(avatar != null) */}

                                {/* } */}
                        </div>
                        <div className="col-md-7">
                            
                        </div>
                        <div className="col-md-2" style={{marginTop: '40px'}}>
                            <button onClick = {Logout} className="dropdown-item">
                                Déconnexion
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mx-2">
                        
                    <div className="col-md-4 ml-3 mt-4">
                        <h5>{profilUser.prenom} &nbsp; {profilUser.nom}</h5>
                         <p style={{ color: '#2287c0'}}>{profilUser.profil}</p>
                    </div>
                     
                </div>
               
                
                    <div className="row">
                    
                
                   
                    <div className="col-md-2">
                        <div class="buton mx-3">
                        <Link className="link" to="/affectation"> Affectation</Link>
                            
                        </div>
                    </div>
                 </div>
                 <div className='row justify-content-center '>
                  
                  {(listMatiere) ? listMatiere.map((cl, index ) => (    
                    <>
                             
                     <div className='col-md-3 m-3 mr-3 p-2 shadow border border-success bloc-classe' >
                         <Descripteur  texForm ={cl.classe.code.substr(0,1).toUpperCase()} text = {cl.classe.code} 
                             detail={cl.matieres.map((matiere, i)=>(
                                 <>
                                {(cl.matieres.length - 2 === i) ?
                                    <> {matiere +" et "}</>:<>{matiere+" ."}</>
                                     }
                                    
                                     {(cl.matieres.length  === i) ?
                                    <> .</>:<>
                                    </>
                                     }   
                                 </>
                             ))}
                         color = '#000' 
                         ml = '4'
                         colorBg = {cl.couleur} />
                     </div>
                     </> )) : <>
                     <br />
                     <div className="col-md-10 m-3 p-3 border border-success">
                            <p className="d-flex justify-content-center ">
                                Cette enseignant n'as pas encore de matières 
                            </p>
                        </div>                    
                         </>
                     }
             </div>
            </div>
            <br />
            <div className="row">
            <div className="col-md-12 cardEnBat">
                   <h3>Donnees Personnel</h3>

                        <span className="text">Nom et prénom:  {profilUser.prenom}   {profilUser.nom}</span><br />
                    <span className="text">Email : <span style={{ color: '#2287c0'}}> {profilUser.email}</span></span><br />
                     <span className="text">Telephone : {profilUser.telephone}</span><br />
                     <span className="text">Role : {profilUser.profil}</span>
             </div>
            </div>
            </>}
        </div>


          </Squelette>
    )
}

export default ProfilEnseignent;