import React, {useState} from 'react';
import {FormatDate} from '../helpers/FormatDate'
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import axios from 'axios'
import APP_ENV from "../../config/.env";
import { useParams,useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

export default function DataTableEleveAyantPayeUnMoi({data}) {

  function filteration(datas, values) {
    const newarr = datas.filter((item) => {
      if (!values) return true;
      if (
        item.prenom.toLowerCase().includes(value.toLowerCase()) ||
        item.matricule.toLowerCase().includes(value.toLowerCase()) ||
        item.nom.toLowerCase().includes(value.toLowerCase()) 
  
      ) {
        return true;
      }
      return false;
    });
    return newarr;
  }
  const [value, setValue] = useState('') 
  const donne = filteration(data,value)
  const [postsParPages, setPostsParPages] = useState(10);
  const [pageCourant , setPageCourant] = useState(0);
  const numberOfPage = Math.ceil(donne?.length / postsParPages)
  const offset = pageCourant * postsParPages;
   const hundleChange=(e)=>{
    setValue(e.target.value)
   }
  function handlePageClick({ selected: selectedPage }) {
    setPageCourant(selectedPage);
  }

  return (
<div>
            <div className="row justify-content-between">
                <div className="col-md-6 form-group">
             
                    <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                </div>
                <div className="col-md-2">
                <div className="form-group row justify-content-end mb-4">
                    <input  type="text" 
                            className="form-control col-md-5" 
                            placeholder='Rechercher...'
                            value = {value}
                            onChange={hundleChange}
                            />
                  </div> 
                </div>
            </div>
             
                <div className="table-responsive">
         <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff'}}>
                <th style={{width: '5px'}}>N°</th>
                <th style={{width: '120px'}}>Matricule</th>
                <th style={{width: '120px'}}>Prénom</th>
                <th style={{width: '40px'}}>Nom</th>
             
                <th style={{width: '1px'}}>Date Naissance</th>
              </tr>
            </thead>
            <tbody>
            
              { donne?donne.slice(offset, offset + postsParPages)
              .map((post,index) => (
                <tr key={post.id}>
                  {/* <td>{post.id}</td> */}
                  <td>{index+1}</td>
                  <td>{post.matricule}</td>
                  <td>{post.prenom}</td>
                  <td>{post.nom}</td>
                  <td>{FormatDate(post.dateNaiss)}</td>
                 
                </tr>

              )):<></>
          }
            </tbody>
          </table>
          </div>

                 {/* Pagination */}
          <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
        </div>
  )
}
