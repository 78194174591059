
import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { Link} from 'react-router-dom';
import DataTableMatiere from './DataTableMatiere'
import LoadingSpinner from '../helpers/LoadingSpinner'
export default function ListMatiere() {
    const [dataEleve, setDataEleve] = useState([])
    const [isLoading, setIsLoading] = useState(false);
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
     
     // console.log(listeEleve);
     const getListMatieres =  async() => {
    
      await 
      axios
      .get(`${APP_ENV.baseUrl}/listMatiere`, {
        headers: header,
      })
      .then(res =>{                
        setDataEleve(res.data.donnees)
        setIsLoading(false)
      })
      
    }
      useEffect(() => {
        setIsLoading(true)
        getListMatieres();
        
      },[]);
     
       
  return (
    <Squelette>
       {isLoading ? <LoadingSpinner/> : 
        <>
        <div className="row d-flex justify-content-end mb-4"> 
         
            <div className="col-md-8 d-flex justify-content-end linkUser">
                <Link to="/affectation" className="btn-classe mr-3 mb-3"> 
                Affectation
                </Link>
           
                <Link to="/creationMatirer" className="btn-classe mr-3 mb-3"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="28" color=" #268d36" fill="currentColor" class="bi bi-plus" className="Ellipse-46" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>Création Matiere
                </Link>
            </div>
        </div>
      <fieldset className='fieldset'>
     
            <h3 className='tilte-fieldset user'>Liste des matières</h3>
                <DataTableMatiere data ={dataEleve}/>      
          </fieldset>
          </>}
    </Squelette>
  )

}
