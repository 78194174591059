import React from 'react'
import '../user/AjoutUser.css'
import { useEffect, useState} from 'react';
import axios from 'axios';
import APP_ENV from "../../config/.env";


function TableRows({rowsData, deleteTableRows, handleChange}) {

    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }

    const [enseignents, setEnseignents] = useState([]);
    const [classes, setClasses] = useState([]);
    const [matieres, setMatieres] = useState([]);
    const [ setError] = useState('')


    const classeListe = async () => {
        await 
        axios(`${APP_ENV.baseUrl}/classes-liste`,
            {headers: header}
        ).then(res => {
            setClasses(res.data.classes)
        })
        .catch(er =>{
        //   let ereur = er.response.data.error
          setError(er)
        })
    }

    const listMatiere = async () => {
        await 
        // Liste des matieres
        axios(`${APP_ENV.baseUrl}/listMatiere`,
            {headers: header}
        ).then(res => {
            setMatieres(res.data.donnees)
        })
        .catch(er =>{
            let ereur = er.response.data.error
            setError(ereur)
        })
    }
    // Liste des enseignents
    const enseignentListe = async () => {
        await 
        axios(`${APP_ENV.baseUrl}/enseignent-list`,
        {headers: header}
    ).then(res => {
        setEnseignents(res.data.enseignents)
        // console.log(res.data.enseignents)
    })
    .catch(er =>{
        let ereur = er.response.data.error
        setError(ereur)
    })
    }
   
    useEffect(()=>{
        // Liste des classe
        classeListe();
        listMatiere();
        enseignentListe();  
    },[])

    return(
        
        rowsData.map((data, index)=>{
            const {}= data;
            return(
                <tr key={index}>
                    <td>
                        <select name="classe_id" onChange={(evnt)=>(handleChange(index, evnt))} className="form-control" >
                            <option value={''}>Choisir une classe</option>
                            {classes.map((cl, index) => (
                                <option value={cl.id}>{cl.code+" / "+cl.type}</option>
                            ))}
                        </select>
                    </td>
                    <td>
                        <select name="matiere_id" onChange={(evnt)=>(handleChange(index, evnt))} className="form-control" >
                            <option value={''}>Choisir une matière</option>
                            {matieres.map((mat, index) => (
                                <option value={mat.id}>{mat.libelle +" / "+mat.type}</option>
                            ))}
                        </select>
                        
                    </td>
                    <td>
                        <select name="enseignent_id" onChange={(evnt)=>(handleChange(index, evnt))} className="form-control" >
                            <option value={''}>Choisir un enseignent</option>
                            {enseignents.map((ens, index) => (
                                <option value={ens.enseignent_id}>{ens.prenom + " " +ens.nom+ " / " +ens.type}</option>
                            ))}
                        </select>
                        
                    </td>
                    <td className=''><input type="number" style={{width: 100}} onChange={(evnt)=>(handleChange(index, evnt))} name="coef" placeholder="Coéf" className="form-control"/> </td>
                    <td><button className="btn btn-outline-danger ml-2" onClick={()=>(deleteTableRows(index))}>x</button></td>
                </tr>

            )
        })
   
    )
    
}

export default TableRows;