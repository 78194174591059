import React from 'react'

export default function Accordion({heading, children, icon1,icon2,title, collapse,collapsed,sousListe}) {
  return (
        <div className="accordion-item" >
        <h2 className="accordion-header" id = {heading}>
            <button className= {`accordion-button ${collapsed} ${sousListe}` } type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#${collapse}`}
                    aria-expanded="true" 
                    aria-controls= {collapse}
                    >
                {icon1}
                {title}
                {icon2}
            </button>
        </h2>
        <div id= {collapse} className="accordion-collapse collapse show" 
            aria-labelledby={heading} data-bs-parent="#accordionExample">
            <div className="accordion-body body-accordion">
                {children}
            </div>
        </div>
        </div>
  )
}
