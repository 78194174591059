import React from 'react'
import {  useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './EmploisDuTempsClasse.css'
import APP_ENV from "../../config/.env";
import UpdateEmploisDuTemps from './UpdateEmploisDuTemps';

export default function EmploisDuTempsClasse() {

    let cl = useParams();
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
   
    const [classe, setClasse] = useState('');
    const [annee, setAnnee] = useState('');
    const [update, setUpdate] = useState(false);
    const [dataEmplois, setDataEmplois] = useState('');
    const [lundis, setLundis] = useState([]);
    const [mardis, setMardis] = useState([]);
    const [mercredis, setMercredis] = useState([]);
    const [jeudis, setJeudis] = useState([]);
    const [vendredis, setVendredis] = useState([]);
    const [samedis, setSamedi] = useState([]);
    
    // Recuperation infos classe
    const emploisDuTempsClasse =async () =>{
        await 
                axios(`${APP_ENV.baseUrl}/emplois-du-temps-classe/${cl.id}`, {headers: header}
                ).then(res=>{
                    if(res.status === 200){
                        setAnnee(res.data.annee)
                        setClasse(res.data.classe)
                        setLundis(res.data.lundi)
                        setMardis(res.data.mardi)
                        setMercredis(res.data.mercredi)
                        setJeudis(res.data.jeudi)
                        setVendredis(res.data.vendredi)
                        setSamedi(res.data.samedi)
                        
                    }
                })
                .catch(error =>{
                    // let ereurs = error.response.data.error
                })
    }
    let profil = localStorage.getItem('profil')

    useEffect(()=>{

        emploisDuTempsClasse();
    },[])
    // Action apres click sur une emplois du temps
    function clickEmploisDuTemps(emploisDuTemps){
        if(emploisDuTemps){
            setDataEmplois(emploisDuTemps)
            setUpdate(true)
        }
    }
    // Generation couleur emplois du temps
    function generedColors(){
        var tabColors = ["#ED40FB","#D500F9","#AA00FF","#7B1FA2","#2196F3","#90CAF9","#42A5FS","#4DB6AC","#26A69A","#00796B","#2979FF","#40D0E1"];       
        var rand = Math.floor(Math.random()*tabColors.length);
        return tabColors[rand];
    }

    return (
        <Squelette>
            { (update) ? 
            <UpdateEmploisDuTemps emploisDuTemps={dataEmplois} parent={'classe'}/> : 
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row  justify-content-between">
                            <div className="col-md-4 d-flex justify-content-start">
                                <label className='font-weight-bold'>Classe : {classe.code+" / "+classe.type}</label>
                            </div>
                            <div className="col-md-4 d-flex justify-content-end">
                                <label className='font-weight-bold'>Année scolaire : {annee.code}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 ml-0 mt-2 ">
                        <div className=" content-empoloiTemp row">
                            {/* Lundi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0" >                              {/* <div className="headerEmplois d-flex justify-content-center pt-2"> */}
                                        <h4 className='titleEmplois'>Lundi</h4>
                                    {lundis ? lundis.map((lundi, i)=>(
                                        <div key={i} className="headerEmplois p-2 " 
                                            style={{cursor: "pointer", backgroundColor: generedColors()}} 
                                            onClick={e => clickEmploisDuTemps(lundi)}
                                        >
                                            <span className="contentEmplois">{lundi.matiere.libelle +" : "+lundi.enseignent.prenom.substr(0,1).toUpperCase()+" "+lundi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{lundi.heureDebut+"h - "+lundi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                                : <div className="headerEmplois col-md-2 px-0" >                              {/* <div className="headerEmplois d-flex justify-content-center pt-2"> */}
                                        <h4 className='titleEmplois'>Lundi</h4>
                                    {lundis ? lundis.map((lundi, i)=>(
                                        <div key={i} className="headerEmplois p-2 " 
                                            style={{backgroundColor: generedColors()}} 
                                        >
                                            <span className="contentEmplois">{lundi.matiere.libelle +" : "+lundi.enseignent.prenom.substr(0,1).toUpperCase()+" "+lundi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{lundi.heureDebut+"h - "+lundi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            }
                            {/* Mardi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0">
                                        <h4 className='titleEmplois'>Mardi</h4>                                {/* </div> */}
                                    {mardis ? mardis.map((mardi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{cursor: "pointer", backgroundColor: generedColors()}}
                                         onClick={e => clickEmploisDuTemps(mardi)}>
                                            <span className="contentEmplois">{mardi.matiere.libelle +": "+mardi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mardi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{mardi.heureDebut+"h - "+mardi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            :   <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Mardi</h4>                                {/* </div> */}
                                    {mardis ? mardis.map((mardi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{backgroundColor: generedColors()}} >
                                            <span className="contentEmplois">{mardi.matiere.libelle +": "+mardi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mardi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{mardi.heureDebut+"h - "+mardi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            }
                            {/* Mercredi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0">
                                        <h4 className='titleEmplois'>Mercredi</h4>
                                    {mercredis ? mercredis.map((mercredi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{cursor: "pointer", backgroundColor: generedColors()}} 
                                        onClick={e => clickEmploisDuTemps(mercredi)}>
                                            <span className="contentEmplois">{mercredi.matiere.libelle +": "+mercredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mercredi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{mercredi.heureDebut+"h - "+mercredi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            :  <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Mercredi</h4>
                                    {mercredis ? mercredis.map((mercredi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{ backgroundColor: generedColors()}} >
                                            <span className="contentEmplois">{mercredi.matiere.libelle +": "+mercredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mercredi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{mercredi.heureDebut+"h - "+mercredi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            }
                            {/* Jeudi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0">
                                        <h4 className='titleEmplois'>Jeudi</h4>
                                    {jeudis ? jeudis.map((jeudi, i)=>(
                                        <div key={i} className="headerEmplois p-2 " 
                                        style={{cursor: "pointer", backgroundColor: generedColors()}} 
                                        onClick={e => clickEmploisDuTemps(jeudi)}>
                                            <span className="contentEmplois">{jeudi.matiere.libelle +": "+jeudi.enseignent.prenom.substr(0,1).toUpperCase()+" "+jeudi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{jeudi.heureDebut+"h - "+jeudi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            : <div className="headerEmplois col-md-2 px-0">
                                <h4 className='titleEmplois'>Jeudi</h4>
                                    {jeudis ? jeudis.map((jeudi, i)=>(
                                        <div key={i} className="headerEmplois p-2 " 
                                        style={{backgroundColor: generedColors()}} >
                                            <span className="contentEmplois">{jeudi.matiere.libelle +": "+jeudi.enseignent.prenom.substr(0,1).toUpperCase()+" "+jeudi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{jeudi.heureDebut+"h - "+jeudi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            }
                            {/* Vendredi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0">
                                        <h4 className='titleEmplois'>Vendredi</h4>
                                    {vendredis ? vendredis.map((vendredi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{cursor: "pointer", backgroundColor: generedColors()}} 
                                        onClick={e => clickEmploisDuTemps(vendredi)}>
                                            <span className="contentEmplois ">{vendredi.matiere.libelle +": "+vendredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+vendredi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{vendredi.heureDebut+"h - "+vendredi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            : <div className="headerEmplois col-md-2 px-0">
                                <h4 className='titleEmplois'>Vendredi</h4>
                                {vendredis ? vendredis.map((vendredi, i)=>(
                                    <div key={i} className="p-2 headerEmplois" 
                                    style={{ backgroundColor: generedColors()}}>
                                        <span className="contentEmplois ">{vendredi.matiere.libelle +": "+vendredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+vendredi.enseignent.nom}</span><br/>
                                        <span className="contentEmplois font-weight-bold d-flex justify-content-center">{vendredi.heureDebut+"h - "+vendredi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                            }
                            {/* Samedi */}
                            {profil !== "Enseignent" || profil !== "Parent" ?
                                <div className="headerEmplois col-md-2 px-0">
                                        <h4 className='titleEmplois'>Samedi</h4>
                                    {samedis ? samedis.map((samedi, i)=>(
                                        <div key={i} className="p-2 headerEmplois" 
                                        style={{cursor: "pointer", backgroundColor: generedColors()}}  
                                        onClick={e => clickEmploisDuTemps(samedi)}>
                                            <span className="contentEmplois ">{samedi.matiere.libelle +": "+samedi.enseignent.prenom.substr(0,1).toUpperCase()+" "+samedi.enseignent.nom}</span><br/>
                                            <span className="contentEmplois font-weight-bold d-flex justify-content-center">{samedi.heureDebut+"h - "+samedi.heureFin+"h"}</span>
                                        </div>
                                    )):""}
                                </div>
                            : <div className="headerEmplois col-md-2 px-0">
                                <h4 className='titleEmplois'>Samedi</h4>
                                {samedis ? samedis.map((samedi, i)=>(
                                    <div key={i} className="p-2 headerEmplois" 
                                    style={{ backgroundColor: generedColors()}} >
                                        <span className="contentEmplois ">{samedi.matiere.libelle +": "+samedi.enseignent.prenom.substr(0,1).toUpperCase()+" "+samedi.enseignent.nom}</span><br/>
                                        <span className="contentEmplois font-weight-bold d-flex justify-content-center">{samedi.heureDebut+"h - "+samedi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>}
        </Squelette>
    )
}