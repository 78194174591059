import React from 'react'
import './BulletinNoteP1.css'
// import './BulletinNotePA.css'
import { useState } from 'react';
import { BsFillPrinterFill } from "react-icons/bs";
import { Link} from 'react-router-dom';
import './BulletinNoteClassePA';


export default function BulletinNoteClassePA({donnees}) {
    const [etat] = useState('');
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '';
        window.print();
        document.body.innerHTML = originalContents; 
    }
    
    let profil = localStorage.getItem('profil')  
    const [val, setVal] = useState(0); 

    return (
      <div>
        {(profil === 'Admin') &&
            <div className="Rectangle-180 pl-3 pr-3 mb-4 " style={{marginRight: '300px'}} >
                <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
            </div>
        }

      <div className ='container'>
      <div id="printablediv">

        {donnees ? donnees.map((donnee, index) => (
            
        // <div className="" style={{height: '1050px', borderBottom: '10px solid black;',}}>
        <div className="page" >
            
            <div className="herder-bulletin">
                <div className="col-md-6">
                    <small>Abou Oubayda As Sakina</small><br/>
                    <small>IEF Dakar</small><br/>
                    <small>École Franco-Arabe</small>
                </div>
                <div className="col-md-6 ">
                    <small className='d-flex justify-content-end'>  السنة الدراسية :  {donnee.anneeScolaire.code}</small>
                    <small className='d-flex justify-content-end'> الفترة : {donnee.semestre}</small>
                </div>
            </div>
        <div className="row borederTitle" >
            <h4 className=''>Bulletin de Note / <span>كشف الدرجات</span></h4>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-3">
                <small>Nom: {donnee.eleve.nom}</small><br/>
                {/* <small>Né(e): {donnee.eleve.dateNaiss}</small><br/> */}
                <small>Matricule: {donnee.eleve.matricule}</small>
            </div>
            <div className="col-md-3 le-centre mt-3" style={{textAlign: "center"}}>
                <small> à {donnee.eleve.lieuNaiss}</small><br/>
                <small>Nombre d'élève: {donnee.rang.effectif}</small>
            </div>
            <div className="col-md-3">
                <small>Prénom: {donnee.eleve.prenom}</small><br/>
                <small>Classe: {donnee.classe.libelle}</small><br/>
                <small>Classe Redoublée: 0</small>
            </div>
           
        </div>
        <div className="row">
            
            <div className="col-md-12 col-sm-10 px-0">
                    <table className='table table-bordered tableNote'>
                    <thead >
                        <tr className='entete-tableau' >
                            <th style={{fontSize: '15px', borderBottom: '1px solid #eee'}} >
                                <label className='title1'>الملاحظات</label><br/>
                                <label className='title2'>Observations</label>
                            </th>
                            <th style={{fontSize: '16px'}} >
                                <small> الدرجات</small><br/>
                                <small>Notes</small>
                            </th>
                            <th style={{fontSize: '15px'}}  className='entete-matiereF'>Matières</th>
                            <th style={{fontSize: '15px'}}  className='entete-matiereA'>المواد</th>
                        </tr>
                    </thead>
                    <tbody id="tabPrimaire" className='corp-tab'>
                        {donnee.matieres ? donnee.matieres.map((mat,index) => (
                        <tr>
                            <td style={{borderTop: '1px solid white',borderLeft: '1px solid #eee', borderBottom: '1px solid white'}}> {index === (Math.floor(donnee.matieres.length/2)) ?
                             <>
                                { (donnee.etat === "راسب" || donnee.etat === "راسبة") ? 
                                <h6>{donnee.etat}</h6> :
                                    <>
                                        <h6> {donnee.etat} بتقدير :  {donnee.appreciation}</h6>
                                    </>
                                }
                             </>
                             :<> </>
                            }
                            </td>  
                            <td style={{fontSize: '16px'}} >{mat.note}</td>  
                            <td style={{fontSize: '16px'}} >{mat.description}</td>  
                            <td style={{fontSize: '16px'}} >{mat.libelle}</td>    
                        </tr>
                        )): <></>} 
                        <tr>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>{donnee.sommeNote}</td>
                            <td className = 'total' colSpan={2}>
                                <h5> مجموع الدرجات</h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </div>   
        <div className="row justify-content-between">
        {(donnee.semestre === "الثانية") ?
            <div className="col-4" >
                <table className='table table-bordered col-md-4'>
                    <tbody style={{fontSize: '13px', textAlign: "right"}} >
                        <tr style={{fontSize: '13px', textAlign: "right"}} >
                            <td > { donnee.moyenSemestre1} / { donnee.moyen.damander } :  معدل فترة الأولى 
                            </td>
                        </tr> 
                        <tr> 
                            <td> { donnee.moyen.valeur} / { donnee.moyen.damander } : معدل فترة الثانية  
                            </td>
                        </tr> 
                        <tr> 
                            <td> { donnee.moyenAnnuelle +" / 10 "} : المعدل العام  
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
            : <div className="col-4" >  </div>
            }
            <div className='col-4'  style={{textAlign: 'right'}}>
            <table className='table table-bordered col-md-5'>
                <tbody>
                    <tr style={{fontSize: '13px'}} >
                        <td > { donnee.moyen.valeur} / { donnee.moyen.damander } : المعدل</td>
                    </tr>
                    <tr>
                        <td>  { donnee.rang.position } : الترتيب </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div className='signature mt-3'>
            <div className="">
                <h6 className=''>الولي</h6>
                <h6 className='text-decoration-underline'>le parent</h6>
            </div>
            <div className="">
                <h6 className=''>المعلم</h6>
                <h6 className='text-decoration-underline'>le maitre</h6>
            </div>
            <div className="">
                <h6 className=''>الإدارة</h6>
                <h6 className='text-decoration-underline'>la direction</h6>
            </div>
            
          </div>
          
    </div>
    )):<></>}
  </div>
</div>
</div>
  )
}
