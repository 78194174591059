import React, {useState} from 'react';
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import ReactPaginate from 'react-paginate';
export default function DataTableEleveAvecPaiement({data}) {
  function filteration(datas, values) {
    const newarr = datas.filter((item) => {
      if (!values) return true;
      if (
        item.eleve.prenom.toLowerCase().includes(value.toLowerCase()) ||
        item.eleve.nom.toLowerCase().includes(value.toLowerCase()) 
  
      ) {
        return true;
      }
      return false;
    });
    return newarr;
  }
  const [value, setValue] = useState('') 
  const donne = filteration(data,value)
  const [postsParPages, setPostsParPages] = useState(10);
  const [pageCourant , setPageCourant] = useState(0);
  const numberOfPage = Math.ceil(donne?.length / postsParPages)
  const offset = pageCourant * postsParPages;
   const hundleChange=(e)=>{
    setValue(e.target.value)
   }
  function handlePageClick({ selected: selectedPage }) {
    setPageCourant(selectedPage);
  }

  return (
<div>
            <div className="row justify-content-between">
                <div className="col-md-6 form-group">
             
                    <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                </div>
                <div className="col-md-2">
                <div className="form-group row justify-content-end mb-4">
                    <input  type="text" 
                            className="form-control " 
                            placeholder='Rechercher...'
                            value = {value}
                            onChange={hundleChange}
                            />
                  </div> 
                </div>
               
            </div>
             
                <div className="table-responsive">
         <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff'}}>
                <th>N°</th>
                <th>Nom complet</th>             
                <th>Oct</th>
                <th>Nov</th>
                <th>Déc</th>
                <th>Jan</th>
                <th>Fév</th>
                <th>Mars</th>
                <th>Avril</th>
                <th>Mai</th>
                <th>Juin</th>
                <th>juill</th>
              </tr>
            </thead>
            <tbody>
              {
              /* {postsCourrent */}
             { donne?donne.slice(offset, offset + postsParPages)
              .map((post,index) => (
                <tr key={post.id}>
                  <td>{index+1}</td>
                  <td>{post.eleve.prenom +" "+post.eleve.nom}</td>                 
                  <td>
                     {  ( post.octobre != null)?
                      <p   className="box"  type="checkbox" checked/>:
                       <p  className="box1" type="checkbox" />
                     }
                  </td>
                  <td> {  ( post.novembre != null)?
                      
                      <p  className="box"  type="checkbox" checked/>:
                       <p  className="box1" type="checkbox" />
                      }
                      </td>
                      <td>
                      {  ( post.decembre != null)?
                            <p   className="box"  type="checkbox" checked/>:
                          <p  className="box1" type="checkbox" />
                            }
                      </td>
                      <td>
                         {  ( post.janvier != null)?
                          <p   className="box"  type="checkbox" checked/>:
                           <p  className="box1" type="checkbox" />
                          }
                      </td>
                      <td>
                         {  ( post.fevrier != null)?
                          <p  className="box"   type="checkbox" checked/>:
                        <p  className="box1" type="checkbox" />
                          }
                      </td>
                      <td>
                         {  ( post.mars != null)?
                          <p  className="box"   type="checkbox" checked/>:
                           <p  className="box1" type="checkbox" />
                          }
                      </td>
                      <td>
                        {  ( post.avril != null)?
                          <p  className="box"   type="checkbox" checked/>:
                        <p  className="box1" type="checkbox" />
                          }
                          </td>
                      <td>
                      {  ( post.mai != null)?
                          <p  className="box"   type="checkbox" checked/>:
                        <p  className="box1" type="checkbox" />
                          }
                      </td>
                      <td>
                      {  ( post.juin != null)?
                          <p   className="box"  type="checkbox" checked/>:
                        <p  className="box1" type="checkbox" />
                          }
                      </td>
                      <td>
                      {  ( post.juillet != null)?
                          <p   className="box"  type="checkbox" checked/>:
                        <p  className="box1" type="checkbox" />
                          }
                      </td>
                 
                </tr>
              )):<></>}
            </tbody>
          </table>
          </div>

                  {/* Pagination */}
          <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
   
   
        </div>
    
   
   
  )
}
