import React from 'react'
import { useState} from 'react';
import Squelette from '../squelette/Squelette'
import './Mail.css'
import Liste from './Liste';


    function Mail() {
        const header = {
            Accept : "application/json", 
            "Content-Type" : "application/json",
            WithCredentials : true,
            Authorization: "Bearer " + localStorage.getItem("token"),
        }

        const [formulaire, setFormulaire] = useState([])
        const [oki, setOki] = useState( false)
        const [datas, setDatas] = useState([])
        const hundeChange =(e)=>{
            const name = e.target.name;
            const value = e.target.value;
            setFormulaire({...formulaire ,[name]:value})
        }
        const hundleSubmit = (e) =>{    
            e.preventDefault();
            setDatas(formulaire);
            setOki(true)
        }
            
  return (
    <Squelette>
        {(oki)? <Liste  data={datas} />:
         <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
            <fieldset  className = 'fieldset'>
            <h3 className='tilte-fieldset user'>Nouveaux message</h3>
                <div className="row justify-content-center">
                    <div className="col-md-3">
                        <div className="form-check radioMail">
                            <input 
                                className="form-check-input"
                                type="radio"
                                name="type"
                                value="mail"
                                onChange={hundeChange} 
                            />
                            <label className="form-check-label">Email</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-check radioMail">
                            <input 
                                className="form-check-input"
                                type="radio"
                                name="type"
                               value="sms"
                                onChange={hundeChange} 
                            />
                            <label className="form-check-label">S.M.S</label>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mail">
                    
                    <textarea type="text" placeholder="Veuillez saisir votre message ici" name='message'  onChange = {hundeChange} className="form-control " rows="5" ></textarea>
                    
                </div>
                <div className="col-md-12 conteneur-btn  btnAjoutUser">
                    <button className='btn1-connexion enregistrer'>Suivant</button>
                </div>
            </fieldset>
         </form>
         }
    </Squelette>
  )
}

export default Mail;