import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Affichage_classe.css'
import Descripteur from '../accueil/Descripteur';
import CardList from '../accueil/CardList';
import { } from 'react-router-dom';
import DataTableEleveCl from './DataTableEleveCl';
import { formatTelephone} from "../helpers/Traitement";
import APP_ENV from "../../config/.env";
import { FaEllipsisV } from 'react-icons/fa';
import BulletinNoteClasseM from '../note/BulletinNoteClasseM';
import BulletinNoteClasseMA from '../note/BulletinNoteClasseMA';
import BulletinNoteClassePA from '../note/BulletinNoteClassePA';
import BulletinNoteClassePF from '../note/BulletinNoteClassePF';

export default function AffichageClasseTempon(classeAf, anneeAf) {

    let cl = useParams();
    
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [listeEleves, setListeEleves] = useState([]);
    const [listEnseignents, setListEnseignents] = useState([]);
    const [classe, setClasse] = useState('');
    const [effectif, setEffectif]= useState('');
    const [surveillent, setSurveillent] = useState('');
    const [nivaeu, setNivaeu] = useState('');
    const [oki , setOki] = useState(false)
    const [dataBultin ] = useState({})
    
    const anneeScolaire =  localStorage.getItem("anneeScolaire");
    // Recuperation infos classe
    const getClasseShow = async()=>{
        await
        axios(`${APP_ENV.baseUrl}/classe-show/${classeAf.classe}/${classeAf.annee}`,
        {headers: header}

        ).then(res=>{
            if(res.status === 200){
                setNivaeu(res.data.datas.classe.niveaux.libelle)
                setClasse(res.data.datas.classe)
                setListeEleves(res.data.datas.eleves)
                setSurveillent(res.data.datas.surveillent)
                setListEnseignents(res.data.datas.enseignents)
                setEffectif(res.data.datas.effectif)
            }
        })
        .catch(error =>{
      
            // let ereurs = error.response.data.error
        })
    }
    
    let profil = localStorage.getItem('profil')

    useEffect(()=>{
        // anneeScolaire =  localStorage.getItem("anneeScolaire");
        getClasseShow();

    },[])
    
    function click(classe, semestre) {
        dataBultin.semestre = semestre.toString();
        dataBultin.classe_id = classe.toString(); 
        setOki(true)  
    }
    return (
        <>
        {(oki)? 
            <>
                {(nivaeu === "Primaire") ?
                <>
                    {classe.type === "AR" ?
                        <BulletinNoteClasseM donnee={dataBultin} />
                        : <BulletinNoteClassePF donnees={dataBultin} />
                    }
                </> 
                : <>
                    {classe.type === "AR" ?
                    <BulletinNoteClasseMA donnee={dataBultin} />
                    : <BulletinNoteClasseM donnee={dataBultin} />
                    }
                </> }
            </> :
            <Squelette>
            <div className='container mb-4'>
                 <div className="row mb-2">
                    <div className="col-md-6 p-1">
                            
                        <h3>Nom classe: { classe.code} / { classe.type === "AR" ? "Arabe" : "Français"}  </h3> 
                        </div>
                        <div className="col-md-6 p-1">
                        <h3 > Effectifes: { effectif }  élèves </h3> 

                    </div>
                </div>
                <div className="row mb-2">

                    <div className="col-md-12 mt-3"> 
                        <div className="btnAfichageClasse content-link-eleve">
                        {(listeEleves === null)?
                        <div className="">
                        <Link  className='btn-classe mb-2 mr-3'>Voir les notes</Link>
                        </div>
                            :
                            <div className="">
                            <Link to ={`/listdesnotes/${classe.id}`} className='btn-classe mb-2 mr-3'>Voir les notes</Link>
                            </div>
                            
                                }
                              {(listeEleves === null)?  
                                <div className="">
                                <Link to ="#" className='btn-classe mb-2 mr-3'>Voir les bulletins</Link>
                                </div>
                               :
                                <div className="">
                            <div className="">
                                </div>
                                    <div className="dropdown show ">
                                            <Link className="link btn-classe mb-2 mr-3 dropdown-toggle" 
                                                to="#" 
                                                data-bs-toggle="dropdown" aria-haspopup="true" 
                                                aria-expanded="true" style={{color: '#fff'}}>
                                                Bulletins
                                            </Link>

                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <Link to ="#"
                                                className="dropdown-item"
                                                onClick = { () => click(classe.id, 1)}
                                                >
                                                Première semestre
                                            </Link>
                                            <Link to ='#'
                                                className="dropdown-item"
                                                onClick = { () => click(classe.id, 2)}
                                                >
                                                Deuxième semestre
                                            </Link>
                                        </div>
                                    </div>


                                </div>
                                
                              }

                            {(profil !== "Enseignent" || profil !== "Parent")  &&
                                <div className="">
                                    <Link to ={`/classe-update/${classe.id}`} className='btn-classe mb-2 '>Modification</Link>
                                </div>
                            }
                  
                         <div className="">
                            {(nivaeu !== "Primaire") &&
                             <>
                                 {(listeEleves === null)?
                                  <div className="">
                                  <Link  className='btn-classe mb-2 mr-3 btn2'>Emploi du temps</Link>
                                   </div>
                                    :
                                    <div className="">
                                        <Link to={`/emplois-du-temps-classe/${classe.id}`} className='btn-classe mb-2 mr-3 '>Emploi du temps</Link>
                                    </div>
                                   
                                 }
                                 </>
                            }
                   
                   </div>
                        {(profil !== "Enseignent" || profil !== "Parent" ) &&
                            <div className="">
                                <Link to ="/inscription" className='btn-classe mb-2 mr-3'>Nouvelle élève</Link>
                            </div>
                        }
                        {(listeEleves === null)?
                            <div className="">
                                <Link  className='btn-classe mb-2 mr-3  mr-2'>Rang élèves</Link>
                            </div>
                        :
                            <div className="">
                                <Link to ={`/classe-rang/${classe.id}`} className='btn-classe  mr-2'>Rang élèves</Link>
                            </div>
                            
                        }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8"> 
                        <fieldset className = 'fieldset'>
                            <h3 className='tilte-fieldset user'> Listes des élèves</h3>
                            <DataTableEleveCl data ={listeEleves ? listeEleves : [] }/>      
                        </fieldset>
                    </div>
                    <div className="col-md-4 ">
                        <div className="styleCard">
                        <CardList title = 'Le surveillent'>
                            <div className="dropdown detail">
                                <Link to="#" className="accueil-detail dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true"  >
                                    <FaEllipsisV style={{marginTop:15}}/>
                                </Link>
                                <div className="dropdown-menu tx-13" aria-labelledby="dropdownMenuButton" >
                                    <Link to="/listSurveillent" className="dropdown-item">Voir Plus</Link>
                                    <Link to="/creerSurveillent" className="dropdown-item">Ajouter</Link>
                                </div>
                            </div>
                            
                        {
                            (surveillent) ? <Descripteur texForm ={surveillent.nomComplet.substr(0,1).toUpperCase()} text = {surveillent.nomComplet } detail = {formatTelephone(surveillent.telephone)} colorBg = 'rgba(237, 251, 78, 0.69)'/>
                            : <h5 className='mx-5'>Pas de surveillent</h5>
                        }
                        </CardList>
                        </div>
                        <div className="styleCard mt-1">
                        <CardList title = 'Listes des enseignents'>
                            <div className="dropdown detail">
                                <Link to="#" className="accueil-detail dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true">
                                    <FaEllipsisV  style={{marginTop:15}}/>
                                </Link>
                                <div className="dropdown-menu tx-13" aria-labelledby="dropdownMenuButton" >
                                    <Link to="/utilisateur-liste" className="dropdown-item">Voir Plus</Link>
                                    <Link to="/utilisateur-ajout" className="dropdown-item">Ajouter</Link>
                                </div>
                            </div>
                            
                            {
                            listEnseignents !== null ? 
                            listEnseignents.map((enseign, index ) => (
                            (index<=2) && 
                            <Descripteur texForm ={enseign.prenom.substr(0,1).toUpperCase()} text = {enseign.prenom + ' ' + enseign.nom }  detail = {enseign.telephone}  colorBg = 'rgba(65, 215, 248, 0.6)'/>
                            )) : <h5 className='mx-5'>Pas d'enseignents</h5>
                            }
                        </CardList>
                        </div>
                    </div>
                </div>
            </div>
            </Squelette>
        }
        </>
    );
}