import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import LoadingSpinner from '../helpers/LoadingSpinner'
import { HiChevronDown } from 'react-icons/hi';
import './TableauHoneure.css';

export default function TableauHoneure() {
   
    const [tableauHoneures , setTableauHoneures] = useState([]);
    const [listAnneeScolaire, setListAnneeScolaire] = useState([]) 
    const [anneeScolaire, setAnneeScolaire] = useState({}) 
    const [recherche, setRecherche] = useState('')  
    const [isLoading, setIsLoading] = useState(false);
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      
      const lastAnnee = async () => {
        await
        axios(`${APP_ENV.baseUrl}/lastAnnee`, {headers: header}
        ).then(res=>{
            if(res.status === 200){ 
                setAnneeScolaire(res.data.annee)     
            }
            setIsLoading(false);
        })
    }
    const tableauhonneurs = async () => {
        await
        axios(`${APP_ENV.baseUrl}/tableau-honneurs/1`, {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setTableauHoneures(res.data.data)
            }
        })
    }
  
    const anneesliste = async () => {
        await
        axios(`${APP_ENV.baseUrl}/annees-liste`, {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setListAnneeScolaire(res.data.annee_scolaires)     
            }
            setIsLoading(false);
        })
    }

      useEffect(()=>{
        setIsLoading(true);
        tableauhonneurs();
        lastAnnee();
        anneesliste();
        

    },[])

    const hundeRecherche =(ev)=>{
        const name = ev.target.name;
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
    }
    const hundleRecherche = (ev) =>{ 
        ev.preventDefault();
        axios(`${APP_ENV.baseUrl}/tableau-honneurs/${recherche.semestre}/${recherche.anneeScolaire_id}`, {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setTableauHoneures(res.data.data)
                console.log("tableau-honneurs");
            }
            setIsLoading(false);
        })
    }

  return (
    <Squelette>
        <div className="container">
        <div className="row">
            <div className="col-md-6">
                <p >
                    <h5> Abou Oubayda "As sakina"</h5>
                </p>

            </div>
            <div className="col-md-6 d-flex justify-content-end  ">
                <p>Année scolaire: </p> <h5> &nbsp;{anneeScolaire.code}</h5>
            </div>
        </div>
        {isLoading ? <LoadingSpinner/> : 
        <>
        <form onSubmit={hundleRecherche} >
             <div className="row justify-content-between">
                <div className="col-md-3 inputSelect">
                    <HiChevronDown className='icon-select'/>
                    <select name="semestre" value={recherche.semestre} onChange = {hundeRecherche}>
                        <option>Séléction une semestre</option>
                        <option value="1">Première semestre</option> 
                        <option value="2">Deuxième semestre</option> 
                    </select>
                </div>  
                <div className="col-md-3 inputSelect">
                    <HiChevronDown className='icon-select'/>
                    <select name="anneeScolaire_id" value={recherche.anneeScolaire_id} onChange = {hundeRecherche}>
                        <option>Séléctione l'année Scolaire</option>
                        {(listAnneeScolaire) ? listAnneeScolaire.map((list, index) => (
                            <option value={list.id}>{ list.code}</option>
                        )) : <h4>Pas d'année Scolaire trouvés</h4>}
                    </select>
                </div>  
                <div className=" inputboite col-md-4 mt-4 d-flex justify-content-center">
                    <button  className='btn-classe h-75'>Rechercher</button>
                </div>  
            </div> 
            
        </form>
              
    <div className="row">
        <div className="border border-success" style={{textAlign: "center", color: '#268d36'}}>           
            <h5>Liste des élèves les plus méritants par classe dressée à la fin de chaque semestre</h5>
        </div>
        {tableauHoneures ? tableauHoneures.map((classe,index) => ( 
            <> 
                <div key={index} className="col-md-6 " 
                        style={{height:270, marginBottom : '30px'}}>
                    
                   Classe: <span style={{color: 'blue',fontSize: '20px'}}>{classe.classe.code}  ({classe.classe.type})</span>
                    <table className=' table table-bordered'>
                        <thead>
                        <tr className="table-active">
                                <td>Rang</td>
                                <td>Matricule</td>
                                <td>NomComplet</td>
                                <td>Moyenne</td>
                            </tr>
                        </thead>
                        <tbody>
                           
                        {classe ? classe.rang.map((elt,index) => ( 
                            (index < 5) ?
                            <tr key={index}>
                                <td>{elt.rang}</td>
                                <td>{elt.eleve.matricule}</td>
                                <td>{elt.eleve.prenom + " " +elt.eleve.nom}</td>
                                <td>{elt.moyen}</td>
                            
                            </tr>
                            : <></>
                        )) : <></>}
                        </tbody>
                    </table>
                </div>
            </>
        )) : <></>}
       </div>  
     </>}   
    </div>
    </Squelette>
  )

}
