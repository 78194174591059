import React from 'react'
import './BulletinNoteP1.css'
import axios from 'axios';
import { Link} from 'react-router-dom';
import APP_ENV from "../../config/.env";
import { useState,useEffect} from 'react';
import { BsFillPrinterFill } from "react-icons/bs";
import Squelette from '../squelette/Squelette';

export default function BulletinNoteP({donnees}) {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [anneeScolaire, setAnneeScolaire] = useState({});
    const [matiere, setMatiere] = useState([]);
    const [classe, setClasse] = useState([]);
    const [rang, setRang] = useState({});
    const [moyenne, setMoyenne] = useState('');
    const [semestre, setSemestre] = useState('');
    const [datas, setDatas] = useState([]);
    const anneeScol =  localStorage.getItem("anneeScolaire");

    // Recuperation liste des classes
    const getBPA = async () => {
        donnees.annee_id = anneeScol

      await axios.post(`${APP_ENV.baseUrl}/bulletin-eleve-classe`,donnees, 
        {headers: header}
        ).then(res=>{
            // setRang(res.data.data[0].rang)
            // setMoyenne(res.data.data[0].moyen)
            // setMatiere(res.data.data[0].matieres)
            setDatas(res.data.data)
            // console.log(res.data.data);
            setClasse(res.data.data[0].classe.type)
            setAnneeScolaire(res.data.data[0].anneeScolaire.code)     
            setSemestre(res.data.data[0].semestre)
        })
        .catch(error =>{
            
        })
    }
    useEffect(()=>{
        //setIsLoading(true)
        getBPA();
       
    },[])
    let profil = localStorage.getItem('profil')  

    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }

  return (
      
    <Squelette>
      <div className ='container'>
        {(profil === 'Admin') &&
            <div className="Rectangle-180 pl-3 pr-3 mb-4 mt-2" style={{marginRight: '300px'}} >
                <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
            </div>
        }

    <div id="printablediv">
    {datas ? datas.map((data, pos) => (
        
        <div className="page"  >
        {/* <div className="" style={{height: '1050px', borderBottom: '10px solid black;'}} > */}
        
        <div className="herder-bulletin">
            <div className="col-md-5">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>École Franco-Arabe</small>
            </div>
            <div className="col-md-5 ">
                <small className="d-flex justify-content-end">Année Scolaire:  {anneeScolaire} </small>
                <small className="d-flex justify-content-end"> {semestre} semestre</small>
            </div>
        </div>
        
        <div className="row borederTitle ms-0" >
            <h4>Bulletin de Note </h4>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {data.eleve.prenom}</small><br/>
                {/* <small>Né(e): {data.eleve.dateNaiss}</small><br/> */}
                <small>Matricule: {data.eleve.matricule}</small>
            </div>
            <div className="col-md-3" style={{textAlign: "center"}}>
                <small> à {data.eleve.lieuNaiss}</small><br/>
                <small>Nombre d'élèves: {rang.effectif} élèves</small>
            </div>
            <div className="col-md-4" style={{textAlign: "right"}}>
                <small>Nom: {data.eleve.nom}</small><br/>
                <small>Classe: {data.classe.code}</small><br/>
                <small>Classe Redoublée: 0</small>
            </div>
        </div>
        <table className='table table-bordered tableNote'>
            <thead>
                <tr className='entete-tableau'>
                    <th style={{fontSize: '15px'}}>Matières</th>
                    <th style={{width: '100px', fontSize: '15px'}}>Notes</th>
                    <th style={{fontSize: '15px'}}>Appréciation</th>
                </tr>
            </thead>
            <tbody>
            {datas[pos].matieres ? datas[pos].matieres.map((matier,index) => (
                <tr>
                    <td>
                        <h6 style={{fontWeight: 'bold'}}>{matier.libelle}</h6>                     
                        { (matier.libelle.toUpperCase() === "ARTS PLASTIQUES" || matier.libelle.toUpperCase() === "CONDUITE") ?
                            <> </> : 
                            <>
                                <h6 style={{fontSize: '15px', paddindTop: '-15px'}}>Ressource</h6>
                                <h6 style={{fontSize: '15px', paddindTop: '-15px'}} className=''>compétence</h6>
                            </>
                        }
                    </td>
                    <td>
                    { (matier.libelle.toUpperCase() === "ARTS PLASTIQUES" || matier.libelle.toUpperCase() === "CONDUITE") ?
                            <h6 className =' mt-0'>{matier.ressource}/{matier.demande.ressource} </h6>
                        :
                        <>
                            <h6 style={{paddingTop: '27px'}}>{matier.ressource}/{matier.demande.ressource} </h6>
                            <h6 style={{paddingTop: '0px'}}>{matier.competence}/{matier.demande.competence}</h6>
                        </>
                    }
                    </td>
                       
                    {(index === 1 ) &&
                    
                    <td rowSpan={datas[pos].matieres.length} style={{borderTop: '1px solid white',borderRight: '1px solid #eee', textAlign:'center'}}>
                        <h6 className="mt-5" style={{textAlign:'center'} }>DÉCISION DU CONSEIL PÉDAGOGIQUE</h6>
                        <h6 style={{textAlign:'center'} }>passé en classe supérieur</h6>
                        <input type="checkbox" className='inputBulletin'/>
                        <h6>Redoublé de classe </h6>
                        <input type="checkbox" className='inputBulletin' />
                    </td>
                    
                    }
                   
                </tr>
               )):<></>}
               
                <tr style={{borderTop: '1px  solid black'}}>
                    <th>
                        <h6>TOTAL</h6>
                    </th>
                    <td>{data.sommeNote  + ' / ' + data.totalDemande}</td>
                </tr>
            </tbody>
        </table>
        <div className="" style={{display: 'flex', justifyContent: 'space-between', }}>
            <div className="">
                <h6 style={{fontSize: '13px'}} className='footer-bulletin'>Moyenne de l'élève:  {datas[pos].moyen.valeur +" / 10"}</h6>
            </div>
            <div className="">
                <h6 style={{fontSize: '13px'}} className='footer-bulletin'>Rang de l'élève: 
                    {datas[pos].rang.position >1 ?
                    <>  {" "+datas[pos].rang.position + ' ème'} </>:
                    <> 
                        {" "+datas[pos].rang.position + ' ère'}
                    </>
                    }
                </h6>
            </div>
        </div>
        {(data.semestre === "2 ème")&&
        <div className="col-md-12" >
            <table className='table table-bordered col-md-12'>
                <tbody>
                    <tr style={{fontSize: '13px'}} >
                        <td>Moyenne première semestre : {data.moyenSemestre1+" / 10"}</td>
                        <td>Moyenne deuxième semestre :  {datas[pos].moyen.valeur+" / "+datas[pos].moyen.damander}</td>
                        <td>Moyenne annuelle : {data.moyenAnnuelle+" / 10"}</td>
                    </tr> 
                </tbody>
            </table>
        </div>
        }
        <div className='' style={{display: 'flex', justifyContent: 'space-around', }}>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    le parent
                </h6>
            </div>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    le maitre
                </h6>
            </div>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    la direction
                </h6>
            </div>

        </div>
        </div>
    )):<></>}
        
    </div>
    </div>
    </Squelette>

  )
}
