import React from 'react'
import Accueil from './Accueil'
import Squelette from '../squelette/Squelette'

export default function SqAccueil() {
  return (
    <div>
        <Squelette>
            <Accueil/>
        </Squelette>
    </div>
  )
}
