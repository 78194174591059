import React from 'react'
import './BulletinNotePA.css'
import axios from 'axios';
import APP_ENV from "../../config/.env";
import { useState,useEffect} from 'react';
import { BsFillPrinterFill } from "react-icons/bs";
import {FormatDate} from '../helpers/FormatDate'
import { Link} from 'react-router-dom';
import BulletinNoteP from './BulletinNoteP';


export default function BulletinNotePA({donnee}) {
  
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    
    const [listeClasses, setListeClasses] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [matiere, setMatiere] = useState([]);
    const [classe, setClasse] = useState([]);
    const [rang, setRang] = useState([]);
    const [moyenne, setMoyenne] = useState([]);
    const [eleves, setEleves] = useState([]);
    const [appreciation, setAppreciation] = useState('');
    const [etat, setEtat] = useState('');
    const [sommeNote, setsommeNote] = useState('');
   
   
    // Recuperation liste des classes
    const getBMA = async () => {
        await 
            axios.post(`${APP_ENV.baseUrl}/bulletin-primaire-ar`,donnee, 
            {headers: header}
            ).then(res=>{
                    setListeClasses(res.data)
                    setAnneeScolaire(res.data.anneeScolaire.code)
                    setEleves(res.data.eleve)
                    setMatiere(res.data.matieres)
                    setClasse(res.data.classe)
                    setRang(res.data.rang)
                    setMoyenne(res.data.moyen )
                    setAppreciation(res.data.appreciation)
                    setEtat(res.data.etat)
                    setsommeNote(res.data.sommeNote)   
            })
            .catch(error =>{
                // let ereurs = error.response.data.error
        
            })
    }
    useEffect(()=>{
        getBMA();
    //    setIsLoading(true)
    },[])
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }
      let profil = localStorage.getItem('profil')  
    
  return (

    <div >
       {(listeClasses.niveau === "Primaire" && listeClasses.classe.type === "FR")?
         <BulletinNoteP donnees={donnee}/>:
         <>
       <div className ='container'>
    <div id="printablediv">
       
        <div className="herder-bulletin mx-0">
            <div className="">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>École Franco-Arabe</small>
            </div>
            <div className="">
                <small className="d-flex justify-content-end">Année Scolaire: {anneeScolaire}</small> 
                <small className="d-flex justify-content-end"> الفترة : {listeClasses.semestre}
                </small>
            </div>
        </div>
        <div className="row borederTitle ms-0" >
            <h4>Bulletin de Note / <span>كشف الدرجات</span></h4>
        </div>       
       
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {eleves.prenom}</small><br/>
                {/* <small>Né(e): {FormatDate(eleves.dateNaiss)}</small><br/> */}
                <small>Matricule: {eleves.matricule}</small>
            </div>
            <div className="col-md-3" style={{textAlign: "center"}}>
                <small> à {eleves.lieuNaiss}</small><br/>
                <small>Nombre d'élève: {rang.effectif}</small>
            </div>
            <div className="col-md-4">
                <small className="d-flex justify-content-end">Nom: {eleves.nom}</small>
                <small className="d-flex justify-content-end">Classe: {classe.code}</small>
                <small className="d-flex justify-content-end">Classe Redoublée: 0</small>
            </div>
           
        </div>
        <div className="row">
            
            <div className="col-md-12 col-sm-10 px-0">
                    <table className='table table-bordered tableNote'>
                    <thead >
                        <tr className='entete-tableau ' >
                            <th style={{fontSize: '15px', borderBottom: '1px solid #eee'}} >
                                <label className='title1'>الملاحظات</label><br/>
                                <label className='title2'>Observations</label>
                            </th>
                            <th style={{fontSize: '15px'}} >
                                <small> الدرجات</small><br/>
                                <small>Notes</small>
                            </th>
                            <th style={{fontSize: '15px'}} className='entete-matiereF'>Matières</th>
                            <th style={{fontSize: '15px'}} className='entete-matiereA'>المواد</th>
                        </tr>
                    </thead>
                    <tbody className='corp-tab'>
                        {matiere.map((mat,index) => (
                            <tr>
                                <td style={{borderTop: '1px solid white',borderLeft: '1px solid #eee', borderBottom: '1px solid white'}}> {index === (Math.floor(matiere.length/2)) ?
                                    <>
                                        {etat === 'راسب' || etat === 'راسبة' ? 
                                        <h6 style={{fontSize: '13px'}}>{etat}</h6> :
                                            <>
                                                <h6 style={{fontSize: '13px'}}> {etat} بتقدير : {appreciation}</h6>
                                            </> }
                                    </> 
                                    : <></>}
                                </td>  
                                <td style={{fontSize: '13px'}} >{mat.note}</td>  
                                <td style={{fontSize: '13px'}} >{mat.description}</td>  
                                <td style={{fontSize: '13px'}} >{mat.libelle}</td>    
                            </tr>
                        ))} 
                    
                        <tr>
                            <td></td>
                            <td>{sommeNote}</td>
                            <td className = 'total' colspan="2" >
                                <h6> مجموع الدرجات</h6>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
            
           
        </div>
       
       
        <div className="row justify-content-between">
        {(listeClasses.semestre === "الثانية") ?
            <div className="col-4" >
                <table className='table table-bordered col-md-4'>
                    <tbody style={{fontSize: '13px', textAlign: "right"}} >
                        <tr style={{fontSize: '13px', textAlign: "right"}} >
                            <td > { listeClasses.moyenSemestre1} / { moyenne.damander } :  معدل فترة الأولى 
                            </td>
                        </tr> 
                        <tr> 
                            <td> { moyenne.valeur} / { moyenne.damander } : معدل فترة الثانية  
                            </td>
                        </tr> 
                        <tr> 
                            <td> { listeClasses.moyenAnnuelle +" / 10 "} : المعدل العام  
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
            : <div className="col-4" >  </div>
            }
            <div className='col-4'  style={{textAlign: 'right'}}>
            <table className='table table-bordered col-md-5'>
                    <tbody>
                        <tr style={{fontSize: '13px'}} >
                            <td > { moyenne.valeur} / { moyenne.damander } : المعدل
                            </td>
                        </tr> 
                        <tr> 
                            <td>  { rang.position } : الترتيب   
                            </td>
                        </tr> 
                    </tbody>
                </table>
               
            </div>
            
        </div>
      
        <div className='' style={{display: 'flex', justifyContent: 'space-around', }}>
            <div className="">
                <h6 className=''>الولي</h6>
                <h6 className='text-decoration-underline'>le Parent</h6>
            </div>
            <div className="">
                <h6 className=''>المعلم</h6>
                <h6 className='text-decoration-underline'>le Maitre</h6>

            </div>
            <div className="">
                <h6 className=''>الإدارة</h6>
                <h6 className='text-decoration-underline'>la Direction</h6>

            </div>

        </div>
        </div>
        </div>
        {(profil === 'Admin') &&
            <div className="Rectangle-180 pl-3 pr-3 mt-5 " style={{marginRight: '300px'}} >
                <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
            </div>
        }
        </>
    }
    </div>
 
  )
}
