// Pour l'importation de ces methodes
// import {formatMontant, formatTelephone} from "../helpers/Traitement";


// Formatage des numero de telephone
export function formatTelephone(telephone) {
    return telephone.toString().replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
}

// Formatage des sommes d'argent (montant)
export function formatMontant (num) {
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/, "$1 ")
}

// Formatage 

