import React from 'react'
import AffichageBudgetere from './AffichageBudgetere'
import Squelette from '../squelette/Squelette'

function SqAffichageBudgetere() {
    return (
        <div>
            <Squelette>
                <AffichageBudgetere/>
            </Squelette>
        </div>
      )
}

export default SqAffichageBudgetere;