import React from 'react'
import {  useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './EmploisDuTempsClasse.css'
import APP_ENV from "../../config/.env";
import UpdateEmploisDuTemps from './UpdateEmploisDuTemps';
import LoadingSpinner from '../helpers/LoadingSpinner'

export default function EmploisDuTempsClasse() {

    let el = useParams();
    let profil = localStorage.getItem('profil')  
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [classe, setClasse] = useState('');
    const [classeAR, setClasseAR] = useState('');

    const [annee, setAnnee] = useState('');
    const [update, setUpdate] = useState(false);
    const [dataEmplois, setDataEmplois] = useState('');
    const [lundis, setLundis] = useState([]);
    const [mardis, setMardis] = useState([]);
    const [mercredis, setMercredis] = useState([]);
    const [jeudis, setJeudis] = useState([]);
    const [vendredis, setVendredis] = useState([]);
    const [samedis, setSamedi] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    
    // Recuperation infos classe
    const emploisDuTempsClasse = async()=>{

    await
            axios(`${APP_ENV.baseUrl}/emplois-du-temps-eleve/${el.id}`, {headers: header}
            ).then(res=>{
                if(res.status === 200){
                    setAnnee(res.data.classeFR.annee)
                    setClasse(res.data.classeFR.classe)
                    setClasseAR(res.data.classeAR.classe)
                    setLundis(res.data.classeAR.lundi)
                    setMardis(res.data.classeAR.mardi)
                    setMercredis(res.data.classeAR.mercredi)
                    setJeudis(res.data.classeFR.jeudi )
                    setVendredis(res.data.classeFR.vendredi)
                    setSamedi(res.data.classeFR.samedi )
                    setIsLoading(false)
                    
                }
            })
            .catch(error =>{
                // console.log(error);
                // let ereurs = error.response.data.error
            })
    } 
    useEffect(()=>{
        setIsLoading(true)
        emploisDuTempsClasse();
    },[])
    function generedColors(){
        var tabColors = ['#00BFFF', 'rgba(65, 215, 248, 0.6)', 'rgba(255, 255, 128, .5)', 'rgb(255, 255, 128)', 'rgb(255, 205, 208)', 'rgb(205, 405, 208)', 'rgb(205, 200, 208)', '#FA8072', '#FF69B4', '#00FA9A', '#EE82EE'];
        var rand = Math.floor(Math.random()*tabColors.length);
        return tabColors[rand];
    }
    // Action apres click sur une emplois du temps
    function clickEmploisDuTemps(emploisDuTemps){
        if(emploisDuTemps){
            setDataEmplois(emploisDuTemps)
            setUpdate(true)
        }
    }
    return (
        <Squelette>
             {isLoading ? <LoadingSpinner/> : 
        <>
            { (update) ? 
            <UpdateEmploisDuTemps emploisDuTemps={dataEmplois} parent={'eleve'}/> : 
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-between">
                            <div className="col-md-4 d-flex justify-content-start">
                                <label className='font-weight-bold'>Classe : 
                                {classe.code+" - "+classe.type} &nbsp; /&nbsp;  {classeAR.code+" - "+classeAR.type}
                                </label>
                            </div>  
                            <div className="col-md-4 d-flex justify-content-end">  
                                <label className='font-weight-bold'>Année scolaire : {annee.code}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 ml-5 mt-2">
                        <div className="content-empoloiTemp d-flex justify-content-center">
                            {/* Lundi */}
                            {(profil === "Parent" || profil === "Enseignent") ?
                            <div className="headerEmplois col-md-2 px-0" >
                            <h4 className='titleEmplois'>Lundi</h4>
                                {lundis ? lundis.map((lundi, i)=>(
                                    <div className="headerEmplois p-2 " style={{backgroundColor: generedColors()}} >
                                        <span key={i} style={{fontSize:20}} className="contentEmplois">{lundi.matiere.libelle +" : "+lundi.enseignent.prenom.substr(0,1).toUpperCase()+" "+lundi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{lundi.heureDebut+"h - "+lundi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                                
                            </div>
                            :
                            <div className="headerEmplois col-md-2 px-0" >
                                    <h4 className='titleEmplois'>Lundi</h4>
                                {lundis ? lundis.map((lundi, i)=>(
                                    <div className="headerEmplois p-2 " style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(lundi)}>
                                    <span key={i} style={{fontSize:20}} className="contentEmplois">{lundi.matiere.libelle +" : "+lundi.enseignent.prenom.substr(0,1).toUpperCase()+" "+lundi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{lundi.heureDebut+"h - "+lundi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                                
                            </div>
                            }
                            
                            {/* Mardi */}
                            {(profil === 'Parent' || profil === 'Enseignent') ?
                            <div className="headerEmplois col-md-2 px-0">
                            <h4 className='titleEmplois'>Mardi</h4>
                        {mardis ? mardis.map((mardi, i)=>(
                                    <div className="p-2 headerEmplois" style={{backgroundColor: generedColors()}} >
                                    <span key={i} style={{fontSize:20}} className="contentEmplois">{mardi.matiere.libelle +": "+mardi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mardi.enseignent.nom}</span><br/>
                                <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{mardi.heureDebut+"h - "+mardi.heureFin+"h"}</span>
                            </div>
                        )):""}
                    </div>
                    :
                    <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Mardi</h4>
                                {mardis ? mardis.map((mardi, i)=>(
                                   <div className="p-2 headerEmplois" style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(mardi)}>

                                        <span key={i} style={{fontSize:20}} className="contentEmplois">{mardi.matiere.libelle +": "+mardi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mardi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{mardi.heureDebut+"h - "+mardi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                    }
                            
                            {/* Mercredi */}
                            {(profil === 'Parent' || profil === 'Enseignent') ?
                             <div className="headerEmplois col-md-2 px-0">
                             <h4 className='titleEmplois'>Mercredi</h4>
                         {mercredis ? mercredis.map((mercredi, i)=>(
                             <div className="p-2 headerEmplois" style={{backgroundColor: generedColors()}} >
                                 <span key={i} style={{fontSize:20}} className="contentEmplois">{mercredi.matiere.libelle +": "+mercredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mercredi.enseignent.nom}</span><br/>
                                 <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{mercredi.heureDebut+"h - "+mercredi.heureFin+"h"}</span>
                             </div>
                         )):""}
                     </div>
                            :
                            <div className="headerEmplois col-md-2 px-0">
                            <h4 className='titleEmplois'>Mercredi</h4>
                        {mercredis ? mercredis.map((mercredi, i)=>(
                             <div className="p-2 headerEmplois" style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(mercredi)}>
                             <span key={i} style={{fontSize:20}} className="contentEmplois">{mercredi.matiere.libelle +": "+mercredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+mercredi.enseignent.nom}</span><br/>
                                <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{mercredi.heureDebut+"h - "+mercredi.heureFin+"h"}</span>
                            </div>
                        )):""}
                    </div>
                    }
                           
                            {/* Jeudi */}
                            {(profil === 'Parent' || profil === 'Enseignent') ?
                            <div className="headerEmplois col-md-2 px-0">
                            <h4 className='titleEmplois'>Jeudi</h4>
                        {jeudis ? jeudis.map((jeudi, i)=>(
                            <div className="headerEmplois  " style={{backgroundColor: generedColors()}} >
                                <span key={i} style={{fontSize:20}} className="contentEmplois">{jeudi.matiere.libelle +": "+jeudi.enseignent.prenom.substr(0,1).toUpperCase()+" "+jeudi.enseignent.nom}</span><br/>
                                <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{jeudi.heureDebut+"h - "+jeudi.heureFin+"h"}</span>
                            </div>
                        )):""}
                    </div>
                            :
                            <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Jeudi</h4>
                                {jeudis ? jeudis.map((jeudi, i)=>(
                                    <div className="headerEmplois  " style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(jeudi)}>
                                        <span key={i} style={{fontSize:20}} className="contentEmplois">{jeudi.matiere.libelle +": "+jeudi.enseignent.prenom.substr(0,1).toUpperCase()+" "+jeudi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{jeudi.heureDebut+"h - "+jeudi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                            }
                            
                            {/* Vendredi */}
                            {(profil === 'Parent' || profil === 'Enseignent') ?
                            <div className="headerEmplois col-md-2 px-0">
                            <h4 className='titleEmplois'>Vendredi</h4>
                        {vendredis ? vendredis.map((vendredi, i)=>(
                            <div className="p-2 headerEmplois" style={{backgroundColor: generedColors()}} >
                                <span key={i} className="contentEmplois ">{vendredi.matiere.libelle +": "+vendredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+vendredi.enseignent.nom}</span><br/>
                                <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{vendredi.heureDebut+"h - "+vendredi.heureFin+"h"}</span>
                            </div>
                        )):""}
                    </div>
                            :
                            <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Vendredi</h4>
                                {vendredis ? vendredis.map((vendredi, i)=>(
                            <div className="p-2 headerEmplois" style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(vendredi)}>
                            <span key={i} className="contentEmplois ">{vendredi.matiere.libelle +": "+vendredi.enseignent.prenom.substr(0,1).toUpperCase()+" "+vendredi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{vendredi.heureDebut+"h - "+vendredi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                            }
                            
                            {/* Samedi */}
                            {(profil === 'Parent' || profil === 'Enseignent') ?
                            <div className="headerEmplois col-md-2 px-0">
                            <h4 className='titleEmplois'>Samedi</h4>
                                     {samedis ? samedis.map((samedi, i)=>(
                            <div className="p-2 headerEmplois" style={{backgroundColor: generedColors()}} >
                                <span key={i} className="contentEmplois ">{samedi.matiere.libelle +": "+samedi.enseignent.prenom.substr(0,1).toUpperCase()+" "+samedi.enseignent.nom}</span><br/>
                                <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{samedi.heureDebut+"h - "+samedi.heureFin+"h"}</span>
                            </div>
                             )):""}
                         </div>
                            :
                            <div className="headerEmplois col-md-2 px-0">
                                    <h4 className='titleEmplois'>Samedi</h4>
                                {samedis ? samedis.map((samedi, i)=>(
                            <div className="p-2 headerEmplois" style={{cursor: "pointer",backgroundColor: generedColors()}} onClick={e => clickEmploisDuTemps(samedi)}>
                            <span key={i} className="contentEmplois ">{samedi.matiere.libelle +": "+samedi.enseignent.prenom.substr(0,1).toUpperCase()+" "+samedi.enseignent.nom}</span><br/>
                                        <span key={i} className="contentEmplois font-weight-bold d-flex justify-content-center">{samedi.heureDebut+"h - "+samedi.heureFin+"h"}</span>
                                    </div>
                                )):""}
                            </div>
                            }
                            
                        </div>
                       
                    </div>
                </div>
            </div>}
            </>}
        </Squelette>
    )
}