import React from 'react'

export default function BudgetLibelle({classname, titleCard,montant,classBs}) {
  return (
   
            <div className={`paiement-trimestre ${classBs}`}>
               <div className={classname}>
               <h3>{titleCard}</h3>
                  <h2>{montant}</h2>
               </div>
            </div>
  
  )
}
