import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom';
import {AccountService} from '../services/AccountService'
import APP_ENV from "../../config/.env";
import { BsEyeFill,BsEyeSlashFill} from "react-icons/bs";

function Connexion() {
  let navigate = useNavigate( );
  const [identifiant, setIdentifiant] = useState({
    email: '',
    password : ''
  })

  const [error, setError] = useState('')
  const [passwordIsVisible, setPasswordIsVisible] = useState(false)
  const autorisation  = "Votre compte est désactivée , Veillez contactez votre administrateur !!! "
 
      //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setIdentifiant({...identifiant ,[name]:value})
    }

   
    // je dois netoyer le DOM (alert error) apres Render avec useEffect()

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
        axios.post(`${APP_ENV.baseUrl}/login`,identifiant)
          .then(res=>{
           
            if (res.data.user.archivage === 1) {
              setError(autorisation)
              
            }else{
              let token = res.data.token;
              let profil = res.data.profil.libelle
              let prenom = res.data.user.prenom
              let nom = res.data.user.nom
              let idUser = res.data.user.id
              let avatar = res.data.user.avatar
              let anneeScolaire = ''
              
            if (profil == "Enseignent") {
              let enseignent_id = res.data.enseignent.id
              AccountService.saveToken(token,prenom,nom,profil,idUser,avatar,enseignent_id ,anneeScolaire)   
              
            }else{
              AccountService.saveToken(token,prenom,nom,profil,idUser,avatar,anneeScolaire)   

            }
              // AccountService.saveToken(token,prenom,nom,profil,idUser,avatar)   
              if (profil === 'Parent') {
                navigate('/Parent')
              }else{
                navigate('/accueil')
              }
            
              window.location.reload();
        }})
        .catch(er =>{
            let ereur = er.response.data.error
           
            setError(ereur)
        })

      }
  return (
    <div className='content-form'>
       <form className="contactform" onSubmit={hundleSubmit}>
            <h1>Connectez-vous</h1>
            <p>Bienvenue sur la plateform <br/> Abou Oubayda "As Sakina"</p>
            <div className="inputboite" style={{width:'95%'}}>
                <input type="email" placeholder="Adresse email" name='email' value={identifiant.email}  onChange = {hundeChange}/>
            </div>
            <div className="inputboite pwd"  style={{width: '95%'}}>
                <input type={passwordIsVisible ? 'text':"password"}
                       placeholder="Mot de passe" name = 'password' 
                       value={identifiant.password} onChange = {hundeChange}
                       className = 'inputPassword'/>
                <button 
                        onClick={()=> setPasswordIsVisible((preveState)=> !preveState)}
                        className = 'buttunPassword'>
                        {passwordIsVisible ? <BsEyeFill className='iconPassword'/>  : <BsEyeSlashFill className='iconPassword'/>}
                </button>
            </div> 
       
            {error !== '' ? 
            <h4 className='alert alert-danger text-center'>{error}</h4>
            : '' 
            } 
            
            <div className="mx-2"  style={{width: '97%'}}>
                <button type = 'submit' className='btn-connexion ml-2' style={{width: '100%'}}>Se Connecter</button>
            </div>
        
            <p><Link to="/reinitialisation" className="dropdown-item">Mot de passe oublié ?</Link>
            </p>
       </form>
     
    </div>
  )
}
 
export default Connexion;
