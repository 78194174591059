
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import './CreerSurveillent.css'
import Squelette from '../squelette/Squelette';
import APP_ENV from "../../config/.env";

function CreerSurveillent() {
    let navigate = useNavigate( );
  const [identifiant, setIdentifiant] = useState([])
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error] = useState('')
  const [ setSuccess] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }
      // je dois netoyer le DOM (alert error) apres Render avec useEffect()
      const hundleSubmit = (e) =>{
        e.preventDefault();
            axios.post(`${APP_ENV.baseUrl}/creeSurveillent`,identifiant, {
            headers: header
            })
          .then(res=>{
             navigate('/listSurveillent')
            
          })
          .catch(er =>{
              })
       
      }
  return (
    <Squelette>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
            <fieldset className = 'p-2'>
                <h3 className='title-form-surveillent'>Création Surveillent</h3> 
                <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="inputboite">
                        <input type="text" placeholder="Nom complet curveillent" name='nomComplet' onChange = {hundeChange}/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center" >
           
                <div className="col-md-10 mb-2">
                    <div className="inputboite">
                        <input type="number" placeholder="Numéro téléphone" name='telephone' onChange = {hundeChange}/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                      <div className="col-md-10 mx-0" >
                    <div class="form-group "  >
                    
                        <select class="form-control" id="sel1" name="type"  style={{marginLeft: 7}} onChange = {hundeChange} >
                        <option >Type  surveillent</option>
                            <option value="FR">Français</option>
                            <option value="AR" >Arabe</option>
                        
                        </select>
                    </div>
                </div>
                </div>
                
    
           
            {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
            : '' 
            } 
            
            <div className="col-md-12 conteneur-btn ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </Squelette>
  )
}
 
export default CreerSurveillent;
