
import React  from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import axios from 'axios';
import './AjoutApport.css'
import Squelette from '../squelette/Squelette';
import { useParams } from 'react-router-dom';
import APP_ENV from "../../config/.env";

function MdifierAport() {

    let id = useParams(); 
    let navigate = useNavigate( );
  const [identifiant, setIdentifiant] = useState({
    type: '',
    valeur: '',
    description : '',
    motif: '',
    nomComplet: '',
  })
    const header = {
      Accept : "application/json", 
     "Content-Type": "multipart/form-data",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error, setError] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }
      // je dois netoyer le DOM (alert error) apres Render avec useEffect()
       
        const getApportById = async () => {
          await 
                        axios(`${APP_ENV.baseUrl}/apport-byId/${id.id}`, {
                        
                      headers: header
                  })
                .then(res=>{
                  setIdentifiant(res.data.apports)
                  
                })
                .catch(er =>{
                        let ereur = er.response.data.errors.messag
                        setError(ereur)
                    })
              
            }
        useEffect(()=>{
          getApportById();
      },[]
       )
      const hundleSubmit = (e) =>{
        e.preventDefault();
            axios.post(`${APP_ENV.baseUrl}/apport-update/${identifiant.id}`,identifiant, {
            headers: header
            })
          .then(res=>{
             navigate('/accueilBudgetere')
            
          })
          .catch(er =>{
                  let ereur = er.response.data.errors.messag
                  setError(ereur)
              })
      } 
    
  return (
    <Squelette>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
       <fieldset className = 'fieldset'>
                <h4 className='title-form'>Modification apport</h4> 
            <div className="row">
                <div className="col-md-6">
                    <div class="form-group ">
                        <select class="form-control" id="sel1" name="type" value={identifiant.type}  onChange = {hundeChange} >
                            <option value="Entrant">Entrant</option>
                            <option value="Sortant" >Sortant</option>
                        
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputboite">
                        <input type="text" placeholder="Valeur" name='valeur' value={identifiant.valeur} onChange = {hundeChange}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div class="form-group">
                    
                        <textarea type="text" placeholder="Valeur" name='description' value={identifiant.description} onChange = {hundeChange} class="form-control" rows="5" id="comment"></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputboite">
                        <input type="text" placeholder="Motif" name='motif' value={identifiant.motif} onChange = {hundeChange}/>
                    </div>
                    <div className="inputboite">
                    <input type="text" placeholder="identifianteur" name='nomComplet' value={identifiant.nomComplet} onChange = {hundeChange}/>
                </div>
                </div>
            </div>
           
            {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
            : '' 
            } 
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button  className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </Squelette>
  )
}
 
export default MdifierAport;
