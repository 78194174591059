import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";


function Tarification_create() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
  
    const [annee, setAnnee] = useState([]);  
    const [formulaire, setFormulaire] = useState({
        montantInscription: '',
        montantMensualite : '',
    })
  // Recuperation des niveau
  const getListAnne = async ( ) => {
    await
    axios(`${APP_ENV.baseUrl}/lastAnnee`,
      {headers: header}
    ).then(res=>{
      if(res.status === 200){
          // console.log(res.data)
          setAnnee(res.data.annee)
      }
    })
    .catch(er =>{
      let ereur = er.response
      setError(ereur)
    })
  }
    useEffect(()=>{
      getListAnne();
    }, [])

    const [error, setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire ,[name]:value})
    }

    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
      e.preventDefault();
      formulaire.annee_id = annee.id
      
      // Les controlle 
      axios.post(`${APP_ENV.baseUrl}/tarif-create`,formulaire,
        {headers: header}
      ).then(res=>{
        if(res.status === 200){
          navigate('/classeDerniereAnnee')
        }
      })
      .catch(er =>{
        let ereur = er.response
        setError(ereur)
      })
  }
     
  return (
<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
        <fieldset className = 'p-2'>
          <h3 className='title-form'>Création de tarif</h3>
          <div className="row">
            <div className="inputboite col-md-10 m-2">
                <input type="text"value={'Année scolaire : '+annee.code} name='annee' onChange = {hundeChange} readOnly/>
            </div>
            
            <div className="inputboite col-md-5 m-2">
                  <input type="number" placeholder="Montant inscription" name='montantInscription' onChange = {hundeChange} />
            </div>
            <div className="inputboite col-md-5 m-2">
                <input type="number" placeholder="Montant mensualité" name='montantMensualite' onChange = {hundeChange} />
            </div>
             
          </div>
          {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
          : '' 
          }   
          <div className="col-md-12 conteneur-btn ">
              <button className='btn1-connexion enregistrer'>Enregistrer</button>
              <button className='btn1-connexion annuler'>Annuler</button>
          </div>
    
        </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default Tarification_create;
