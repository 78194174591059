import TableRows from "./TableRows"
import Squelette from '../squelette/Squelette';
import {  useState} from 'react';
import axios from 'axios';
import '../annee_scolaire/Annee_scolaire_create.css'
import APP_ENV from "../../config/.env";
import { useNavigate } from 'react-router-dom';


function AffectationMatiereClasseEnseignent(){
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    const [ setError] = useState('')
    const [rowsData, setRowsData] = useState([]);
 
    const addTableRows = ()=>{

        const rowsInput = {
            classe_id : '',
            matiere_id : '',
            enseignent_id : '',
            coef : ''
        }
        setRowsData([...rowsData, rowsInput])
      
    }

    const deleteTableRows = (index)=>{
            const rows = [...rowsData];
            rows.splice(index, 1);
            setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
    
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    const hundleSubmit = (e) =>{    
        e.preventDefault();
        const data = {
            datas : rowsData
        }   
        // axios.post(`${APP_ENV.baseUrl}/affectation-matiere-classe-enseignent`,data,
      axios.post(`${APP_ENV.baseUrl}/affectation-matiere-classe-enseignent`,data,

            {headers: header}
        ).then(res => {
            if(res.status === 200){
                navigate('/classe-liste')
            }    
        })
        .catch(er =>{
            // console.log(er)
        //   let ereur = er.response
          setError(er)
        })
    }  
    
    return(
        <Squelette>
        <div className="container">
            <fieldset className = 'fieldset mt-2'>
                <h3 className='tilte-fieldset user'>Affectation de matières à une classe</h3>
                
            <div className="row">
                <form onSubmit={hundleSubmit} className='container formulaire col-md-12'>
                    <div className="col-sm-12">
                        <table className="table mt-3">
                            <thead>
                                <tr>
                                <th>Classe</th>
                                <th>Matière</th>
                                <th>Enseignent</th>
                                <th>Coéficient</th>
                                <th><button className="btn btn-outline-success" onClick={addTableRows}>+ Ligne</button></th>
                                </tr>
                            </thead>
                        <tbody>
                            <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                        </tbody> 
                        </table>
                    </div>
                    <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
                </form>
            </div>
            </fieldset>
        </div>
        </Squelette>
    )
}
export default AffectationMatiereClasseEnseignent
