import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './AffectationNiveau.css'
import APP_ENV from "../../config/.env";


function AffectationNiveau() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    } 
    const [formulaire, setFormulaire] = useState([])
    const [listSurveillent, setListSurveillent] = useState([])
  const [listNiveau, setListNiveau] = useState([])
  let navigate = useNavigate( );
  // Recuperation des niveau niveau-list  listSurveillent
  const niveauList = async () => {
    await axios
    .get(`${APP_ENV.baseUrl}/niveau-list`, {
      headers: header,
    })
    .then(res =>{
      setListNiveau(res.data.niveaux)
      
    })
  }
  const surveillentList = async () => {
    await  axios
    .get(`${APP_ENV.baseUrl}/listSurveillent`, {
      headers: header,
    })
    .then(res =>{
      //console.log(res.data);
      setListSurveillent(res.data.donnees)
      
    })
  }
  useEffect(() => {
    surveillentList();
    niveauList();
    
  }, []);

    const [error, setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire ,[name]:value})
    }

    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
      e.preventDefault();
      // Les controlle 
      axios.post(`${APP_ENV.baseUrl}/niveau-affectation`,formulaire,
        {headers: header}
      ).then(res=>{
        if(res.status === 200){
          navigate('/niveau-liste')
        }
      })
      .catch(er =>{
        let ereur = er.response
        setError(ereur)
       // console.log(er)
      })
  }
     
  return (
<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
        <fieldset className = 'fieldset'>
          {/* <h3 className='title-form-niveau'>Affectation Niveau</h3> */}
          <h3 className='tilte-fieldset user'>Affectation Niveau à un surveillent</h3>
          <div className="row">
            <div className="col-md-10 inputSelect">
                {/* <HiChevronDown className='icon-chevrone'/> */}
                <select name="surveillent_id" onChange = {hundeChange} required>
                <option value="">Selection le Surveillent</option>
                {(listSurveillent) ? listSurveillent.map((sur, index) => (
                    <option value={sur.id}>{ sur.nomComplet} ({sur.type})</option>
                )) : <h4>Pas de Surveillent trouvés</h4>}
                </select>
            </div>     
          </div> 
          <div className="row">
            <div className="col-md-10 inputSelect">
              {/* <HiChevronDown className='icon-chevrone'/> */}
              <select name="niveau_id" onChange = {hundeChange} required>
              <option  value="">Selection le Niveau</option>
              {(listNiveau) ? listNiveau.map((niveau, index) => (
                  <option value={niveau.id}>{ niveau.libelle + " ("+niveau.type+")"}</option>
              )) : <h4>Pas de Niveau trouvés</h4>}
              </select>
            </div>     
          </div>
            
          {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
          : '' 
          }   
          <div className="col-md-12 conteneur-btn btnAjoutUser">
              <button className='btn1-connexion enregistrer'>Enregistrer</button>
              <button className='btn1-connexion annuler'>Annuler</button>
          </div>
    
        </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default AffectationNiveau;
