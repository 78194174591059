
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import './CreationMatiere.css'
import Squelette from '../squelette/Squelette';
import APP_ENV from "../../config/.env";

function CreationMatiere() {
    let navigate = useNavigate( );
  const [identifiant, setIdentifiant] = useState(
    {
      type: '',
      libelle : ''
    })
  const [errorLibelle, setErrorLibelle] = useState('')
  const [errorType, setErrorType] = useState('')
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [ setError] = useState('')
  const [ setSuccess] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();

        if (identifiant.libelle === '') {
          setErrorLibelle("Veillez saisir le libelle")
          }else{
            setErrorLibelle('')
          }
      if (identifiant.type === '') {
        setErrorType("Veillez selectionner type de matière")
          }else{
            setErrorType('')
          }
            axios.post(`${APP_ENV.baseUrl}/matiere`,identifiant, {
            headers: header
            })
          .then(res=>{
             navigate('/listMatiere')
            
          })
          .catch(er =>{
            console.log(er);
                  let ereur = er.response.data.errors.messag
                  setError(ereur)
              })
      }
     
    
  return (
    <Squelette>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
        <fieldset className = 'fieldset'>
          <h4 className='tilte-fieldset user'>Création matière</h4>
            
                <div className="row justify-content-center">
               
               <div className="col-md-10 " style={{marginRight: 15}}>
                   <div className="inputboite ">
                       <input type="text" placeholder="Libelle matière" name='libelle' onChange = {hundeChange}/>
                       {(errorLibelle )  ?
                            
                            <p className=' text-danger'>{errorLibelle}</p>:
                            ''
                            }  
                   </div>
               </div>
           </div>
            <div className="row justify-content-center">
               
                <div className="col-md-10 ">
                    <div class="form-group ">
                        <select class="form-control" id="sel1" name="type"  onChange = {hundeChange} >
                        <option >Type  matière</option>
                            <option value="FR">FRANCAIS</option>
                            <option value="AR" >ARABE</option>
                        
                        </select>
                        {(errorType )  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                    </div>
                </div>
                </div>
               
                {(identifiant.type === 'AR')&&
                <div className="row justify-content-center">
               
                <div className="col-md-10 ">
                    <div className="inputboite">
                        <input type="text" placeholder="Description" name='description' onChange = {hundeChange}/>
                       
                    </div>
                </div>
            </div>
                }

            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
            </fieldset>
       </form>

    </div>
    </Squelette>
  )
}
 
export default CreationMatiere;
