import React, { useEffect,useState } from 'react'
 import './AffichageBudgetere.css'
 import axios from 'axios'
 import { Link} from 'react-router-dom';
import DataTableAffichageBudget from './DataTableAffichageBudget'
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";


function AffichageBudgetere() {
    const [listAports, setListAports] = useState([]);  
    const [budjet, setBudjet] = useState([]);  
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [isLoading, setIsLoading] = useState(false);
  
    const apportliste = async () => {
    
      await
        axios(`${APP_ENV.baseUrl}/apport-liste`,{
        method: "GET",
        headers: header
        })
        .then(res =>{
          const dataAports = res.data.apports
          // console.log(dataAports);
          setListAports(dataAports)
          
        }) 
    }
      // recuperation des budjet ()
      const budget = async () => {
        await
        axios(`${APP_ENV.baseUrl}/budget`,{
        method: "GET",
        headers: header
        })
        .then(res =>{
          const dataBudjet = res.data.datas
          setBudjet(dataBudjet)
          setIsLoading(false)
        })     
      }
    useEffect(()=>{
      setIsLoading(true)
      budget();
      apportliste();
     
    },[])
  return (
    <div class="container">
     {isLoading ? <LoadingSpinner/> : 
        <>
        <div className="row mt-4 mb-2 d-flex justify-content-around">
            <div className="col-md-4">
              <div  className="Rectangle-206">
                <Link to = '/detailpaiement' title='Detail Budgetaire' className='linkLien' >
                  
                <span className="Total-dinscriptionMensualits" style={{fontSize: "16px",textDecoration: 'none'}} >
                  Total d’inscription/Mensualités
                </span>
              <div className="Rectangle-221"></div>
              <div className="AFFICHAGE-BUDGETS">
                <span className="mansualiteFcfa ml-2 px-2" style={{fontSize: "30px"}}>
                    {budjet.totalInscription + budjet.totalMensualite} Fcfa
                </span>
              </div>
              </Link>
              </div>

            </div>
          
            <div className="col-md-4">
              <div className="Rectangle-209">
                  <span className="Total-depence">
                    Total dépence
                  </span>
                  <div className="Rectangle-222"></div>
                <div className="AFFICHAGE-BUDGETS">
                  <span className="depenceFcfa  ml-2 px-2" style={{fontSize: "30PX"}}>
                    {budjet.depenses} Fcfa
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="Rectangle-210">
                <span className="Nos-avoir-rel">
                  Nos avoir réels
                </span>
                <div className="Rectangle-223"></div>
                <div className="AFFICHAGE-BUDGETS">
                  <span className="reelFcfa  ml-2 px-2" style={{fontSize: "30PX"}}>
                    {budjet.budgetReel} Fcfa
                  </span>
                </div>
              </div>
            </div>
        </div>
         
    <div className="row mt-4">

    <div className='row justify-content-end' >
              <div className="col-md-8 content-link-eleve  d-flex justify-content-end">
            <div className="mb-4 ">
            <Link to="/ajoutApport" className='btn-classe mb-2 linkLien'>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="28" color=" #268d36" fill="currentColor" class="bi bi-plus" className="Ellipse-46" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg> Nouvelle Apport
              </Link>
              <Link to="/paiement-create" className='btn-classe mb-2'>Paiement</Link>

              </div>
          </div>
        </div>
       
    </div>
    <div>
      
    </div>
    <fieldset className = 'fieldset'>
         
      <h3 className='tilte-fieldset user'>Liste des Apports</h3>
          <DataTableAffichageBudget datas ={listAports}/>      
    </fieldset>
    </>}
  </div>

  )
}
export default AffichageBudgetere;
