import React, { useEffect,useState } from 'react'
import './ListeUser.css'
import axios from 'axios'
import DataTableUser from './DataTableUser';
import { Link} from 'react-router-dom';
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";

export default function ListeUser() {
    const [listeUtilisateur, setListeUtilisateur] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }

      const getData = async () => {
      
         await  axios(`${APP_ENV.baseUrl}/users`, {
          method: "GET",
          headers: header
          })
          .then(res =>{
            const dataUser = res.data.donnees
        
            setListeUtilisateur(dataUser)
            setIsLoading(false)
          })
        }
      useEffect(() => {
        setIsLoading(true)
          getData();
      }, []);

  return (
      <div className="container-fluid content-tab ">
         {isLoading ? <LoadingSpinner/>: 
           <>
          <div className="row d-flex justify-content-end mb-4"> 
            
              <div className="col-md-8 d-flex justify-content-end linkUser">
                <Link to="/utilisateur-ajout" className='btn-classe mr-3 mb-3'>Nouveau utilisateur</Link>
                <Link to="/userArchive" className='btn-classe mb-3'>Liste utilisateurs Archivés</Link>
              </div>
           
          </div>
          <fieldset className = 'fieldset'>
         
            <h3 className='tilte-fieldset user'>Liste des utilisateurs</h3>
                <DataTableUser data ={listeUtilisateur}/>      
          </fieldset>
          </>
        }  
       
      </div>
      
   
   
  )
}