import React, { useState } from 'react'
import { WiDirectionLeft, WiDirectionRight } from 'react-icons/wi';
import ReactPaginate from 'react-paginate';

export default function ListNoteParClasse({dataNotes,datas}) {
    function filteration(datass, values) {
        const newarr = datass.filter((item) => {
          if (!values) return true;
          if (
            item.prenom.toLowerCase().includes(value.toLowerCase()) ||
            item.matricule.toLowerCase().includes(value.toLowerCase()) ||
            item.nom.toLowerCase().includes(value.toLowerCase()) 
      
          ) {
            return true;
          }
          return false;
        });
        return newarr;
      }
      
      const [value, setValue] = useState('') 
      const donne = filteration(dataNotes.eleves,value)
      const [postsParPages, setPostsParPages] = useState(10);
      const [pageCourant , setPageCourant] = useState(0);
      const numberOfPage = Math.ceil(donne?.length / postsParPages)
      const offset = pageCourant * postsParPages;
       const hundleChange=(e)=>{
        setValue(e.target.value)
       }
      function handlePageClick({ selected: selectedPage }) {
        setPageCourant(selectedPage);
      }
    
  return (
    <>
      <div className="row">
        <div className="col-md-6">
            <p className="mt-2">
                Abou Oubayd AS SAKINA
            </p>
            {/* <p className="mt-5"> Semestre : semestre {dataNotes.semestre}</p> */}
        </div>
        
        <div className="col-md-6 d-flex justify-content-end">
            <p>Année scolaire: {datas.anneeScolaire.code} </p>
        </div>
        </div>
        <div className="row justify-content-between">
            <div className="col-md-4">
                <p> Matière : {datas.matiere.libelle}</p>
            </div>
            <div className="col-md-4">
                <p> Classe : {datas.classe.code} {(datas.classe.type === "AR") ? "Arabe" : "Français"}</p>
            </div>
            <div className="col-md-4">
                <p> Professeur : {datas.enseignent.prenom +" "+ datas.enseignent.nom}</p>
            </div>
        </div>
        <br/>
        <br/>
        <div className="col-md-12" id='printablediv'>
          <fieldset className = 'fieldset' >
        <div className="row">
            <div className="col-md-6 form-group">
            
                <select aria-label="Default select example" className="w-25 form-control" 
                        onChange={(e) => setPostsParPages(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
            <div className="col-md-6">
                <div className="form-group row justify-content-end mb-4">
                <input  type="text" 
                    className="form-control col-md-5" 
                    placeholder='Rechercher...'
                    value = {value}
                    onChange={hundleChange}
                    />
                </div> 
            </div>
        </div>

            <h3 className='tilte-fieldset user'>Fiche des notes</h3>
            <table className='table table-bordered table-hover' >
        <thead>
            <tr style={{background :'#268d36', color:'#fff'}}>
            <th style={{width: '5px'}}>N°</th>
            
            <th>Matricule</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Date de naiss</th>
            <th>Lieux de naiss</th>
            <th>Notes</th>
            </tr>
        </thead>
        <tbody>
           
              {donne.slice(offset, offset + postsParPages)
            .map((post,index) => ( 
            <tr>
                <td>{index+1}</td>
                <td>{post.matricule}</td>
                <td>{post.prenom}</td>
                <td>{post.nom}</td>
                <td>{post.dateNaiss}</td>
                <td>{post.lieuNaiss}</td> 
                <td>{dataNotes[index]}</td>
                {/* <td style={{width: 100}}><input type="number" name={index} placeholder="0" onChange={handleChange} className="form-control" style={{width: 70}}/></td> */}
            </tr>
             ))} 
              
        </tbody>
        </table>
                {/* <DataTableListNoteParClasse data ={dataNotes}/>       */}
          </fieldset>
          </div>
                         {/* Pagination */}
                         <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 

    </>
  )

}
