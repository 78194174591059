import React from 'react'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";
import {FormatDate} from '../helpers/FormatDate'
import { Link, useNavigate } from 'react-router-dom';

function Annee_scolaire_liste() {
  const base_url = `${APP_ENV.baseUrl}/`
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
  const [anneeScolaires, setAnneeScolaires] = useState([]);

  const [ setError] = useState('')
  let navigate = useNavigate( );

  // Recuperation des annees
  const getListAnneScolaire = async () => {
    await 
          axios(base_url + 'annees-liste',
          {headers: header}
          ).then(res=>{
            setAnneeScolaires(res.data.annee_scolaires)
          })
          .catch(er =>{
            let ereur = er.response
            setError(ereur)
          })
  }
    useEffect(()=>{
      getListAnneScolaire();
  
    },[])

    function clickAnneeScolaire(choixCl){
      if(choixCl){
          navigate('/tempnAnneeScolaire/'+choixCl)
      }
    }
  return (
  <Squelette>
    <div className='container'>
        <h3 className='text-success mb-3 ml-0 pl-0'>Nos années scolaires</h3>
            <div className='row'>
            {(anneeScolaires) ? anneeScolaires.map((annee, index ) => (                 

              <div className='col-md-3 m-0 mb-3 pt-0 mx-2 contenu-descrpteur' style={{cursor: "pointer"}} onClick={e => clickAnneeScolaire(annee.id)}>
                <div className="col-md-12 shadow border border-success mt-0" 
                      style ={{paddingLeft :'5px'}}>
                    <h6 className="descripteur-text text-success pb-1 px-0 mx-0">Année scolaire : {annee.code}</h6>
                    <div className='row justify-content-between '>
                      <smaill>Date début:  {FormatDate(annee.dateDebut)}</smaill>
                        {/* <div style ={{fontSize:'14px'}} className='col-md-6'>Date début:  {FormatDate(annee.dateDebut)}</div> */}
                        {/* <div style ={{fontSize:'14px', }} className='col-md-5'>{FormatDate(annee.dateDebut)}</div> */}
                    </div>
                    <div className='row'>
                      <p>Date fin: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{FormatDate(annee.dateFin)}</p>
                        {/* <div style ={{fontSize:'14px'}} className='col-md-5 h6 pt-0 pb-0'>Date fin:</div>
                        <div style ={{fontSize:'14px'}} className='col-md-5 h6 pt-0 pb-0'>{FormatDate(annee.dateFin)}</div> */}
                    </div>
                </div>
              </div>
              
            )) : <h5>Veuillez créer une année scolaire</h5>}

            </div>
        </div>

    </Squelette>
  )
}
 
export default Annee_scolaire_liste;
