import React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";
import APP_ENV from "../../config/.env";
import { NumberToLetter } from "./ToLettre.js";
import { BsFillPrinterFill } from 'react-icons/bs';


export default function RecuPaiement({data,mensialite}) {
     
        const [eleves, setEleve] = useState('');
        const header = {
            Accept: "application/json",
            WithCredentials: true,
            Authorization: "Bearer " + localStorage.getItem("token"),
          };
          const [ setError] = useState("");
    function formatDate() {
      let d = new Date();
        let m = d.getMonth()+1;
        let dey= d.getDate();
        let a= d.getFullYear();
        let h =   d.getHours();
        let mn =   d.getMinutes();
          if (dey<= 9) {
            dey= "0"+dey 
          }
          if (m<= 9) {
            m= "0"+m 
          }
          return dey+"/"+m+"/"+a +" à "+h +"h "+mn+" mn";
  }
  const elevesShow = async () => {
    await
    axios(`${APP_ENV.baseUrl}/eleve-show/${data.matricule}`,
    {headers: header}
    ).then(res=>{
        setEleve(res.data.eleve)
    })
    .catch(er =>{
      let ereur = er.response
      setError(ereur)
    })
  }
    useEffect(() => {
      elevesShow(); 
    },[]);
    const Print = () =>{     
      let printContents = document.getElementById('printablediv').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      document.title = 'Liste des niveaux';
      window.print();
     document.body.innerHTML = originalContents; 
    }

    return (
      <>
      <div className=" container row " id="printablediv">
        <div className="col-md-1"></div>
        <div className="col-md-10 grandCarde">
              <div class="Rectangle-294">
                    <span class="A000-1">
                         {eleves.matricule}
                  </span>
              </div>
              <div class="Rectangle-292">
                <span class="REU">
                  RE<span class="text-style-1">ç</span>U
                </span>
              </div>
              <br />
              <br /><br />
              <div className="row  montant">
                  <div className="">
                  Montant : <input value={(mensialite*data.mois.length) +" FCFA"}  className="input" disabled></input>
                    </div>
              </div>
              <br />  
              <br />
              <div className="row  recude">
                  <div className="">
                  Reçu de  : <input value={eleves.prenom  + " " +  eleves.nom }  className="inputNom" disabled></input>
                    </div>
              </div>
              <br />
              <div className="row">
                 <span class="Classe-6em-A6em-FR">
                 Classe : {eleves.classeAR} Arabe / {eleves.classeFR} Français
                  </span>
              </div>
              <p className="ligne"></p>
              <br />
              <div className="row  lasomme">
                  <div className="">
                 La somme de  : <input placeholder="" value={NumberToLetter(mensialite*data.mois.length) +" FCFA"}  className="inputSomme" disabled></input>
                    </div>
              </div>
              <br />
              <div className="row">
                 <span class="Classe-6em-A6em-FR">
                 Mensualite du mois :     <>
                          {data.mois.map((item, index ) => {
                            if (data.mois.length - 2 === index) {
                              return <span>{item} et </span>
                            }else if (data.mois.length - 1 === index) {
                              return <span>{item}. </span>
                            }else{
                              return <span>{item}, </span>
                            }
                            
                        })
                        }
                </> 
                   </span>
              </div>
              <p className="ligne"></p>
              <br />
              <div className="row">
                 <span class="Classe-6em-A6em-FR">
                   Date :  {formatDate()}
                  </span>
              </div>
              <p className="ligne"></p>
              <br />

              <div className="row mr-5 mb-5" style={{float: "right"}}>
                 <span className="" >
                   Signanture 
                   <hr />
                  </span>
                  
              </div>
              <br />
        </div>
           
      </div>

     <div className=" row justify-content-center">
            
      <div className="Rectangle-180 pl-3 pr-3 mt-5 col-md-2" >
        <a onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</a>
      </div>
     </div>
      </>
 )
};
