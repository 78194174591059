import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { AiFillPrinter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';

export default function RangEleveClasse() {
  
    
    const [listEleves, setListEleves] = useState([])   
    function filteration(datas, values) {
        const newarr = datas.filter((item) => {
          if (!values) return true;
          if (
            item.prenom.toLowerCase().includes(value.toLowerCase()) ||
            item.matricule.toLowerCase().includes(value.toLowerCase()) ||
            item.nom.toLowerCase().includes(value.toLowerCase()) 
      
          ) {
            return true;
          }
          return false;
        });
        return newarr;
      }
      const [value, setValue] = useState('') 
      const donne = filteration(listEleves,value)
      const [postsParPages, setPostsParPages] = useState(10);
      const [pageCourant , setPageCourant] = useState(0);
      const numberOfPage = Math.ceil(donne?.length / postsParPages)
      const offset = pageCourant * postsParPages;
       const hundleChange=(e)=>{
        setValue(e.target.value)
       }
      function handlePageClick({ selected: selectedPage }) {
        setPageCourant(selectedPage);
      }
    let idClasse = useParams();
      const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = 'Rang des élèves par classe';
        window.print();
       document.body.innerHTML = originalContents; 
      }
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    // const [isLoading, setIsLoading] = useState(false);
    const [recherche, setRecherche] = useState('')   
    const [classe, setClasse] = useState('')   
    const [effectif, setEffectif] = useState('')   
    const [annee, setAnnee] = useState('') 
       
    const anneeScolaire =  localStorage.getItem("anneeScolaire");

    const rangParClasse = async () => {
        await
        axios(`${APP_ENV.baseUrl}/rang-par-classe/${idClasse.id}/1/${anneeScolaire}`,
        {headers: header}
        ).then(res=>{
            setListEleves(res.data.rang)
            setClasse(res.data.classe)
            setEffectif(res.data.effectif)
            setAnnee(res.data.anneeScolaire)
            // setIsLoading(false);
        })
        .catch(error =>{

            // let ereurs = error.response.data.error
        })
    }
      useEffect(()=>{
        // setIsLoading(true);
        rangParClasse();
       
    },[])

    const hundeRecherche =(ev)=>{
        const name = ev.target.name;
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
    }
    const hundleRecherche = (ev) =>{ 
        ev.preventDefault();
 
        // setIsLoading(true);
        axios(`${APP_ENV.baseUrl}/rang-par-classe/${idClasse.id}/${recherche.semestre}`,
        {headers: header}
        ).then(res=>{
            setListEleves(res.data.rang)
            setClasse(res.data.classe)
            setEffectif(res.data.effectif)

            // setIsLoading(false);
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
        })
    }

  return (
    <Squelette>
       
        <div className="row d-flex justify-content-between">
            <div className="col-md-4  ml-3">
                Abou Oubayda AS SAKINA
            </div>   
            <div className="col-md-4 d-flex justify-content-end">Année scolaire: <label className=''>{annee.code}</label></div>
        </div>
        <div className="row d-flex justify-content-between">
            <div className="col-md-4 ml-3">Classe: <label className=''>{classe.code + " / "+classe.type}</label></div>
            <div className="col-md-4 d-flex justify-content-end">Effectif: <label className=''>{effectif +" élèves"}</label></div>
        </div> 

        <form onSubmit={hundleRecherche} >
            <div className="row  justify-content-between">
                
                <div className="col-md-3 inputSelect">
                    <HiChevronDown className='icon-chevrone mt-3'/>
                    <select name="semestre" value={recherche.semestre} onChange = {hundeRecherche}>
                        <option value="1">Première semestre</option> 
                        <option value="2">Deuxième semestre</option> 
                    </select>
                </div>   
                <div style={{padding: "33px 0 0 0"}} className="col-md-4 inputboite  content-link-eleve ">
                    <button style={{padding: "12px 30px"}} className='btn-classe'>Rechercher</button>
                </div> 
                <div style={{padding: "38px 0 0 0"}} className="col-md-2 content-link-eleve ">
                    <Link className='btn-classe font-weight-bold' onClick={Print} style={{cursor: "pointer", color: "white"}}> <AiFillPrinter/> Imprimer</Link>
                </div>
 
            </div> 
        </form>
       
     <br/>
     <div id='printablediv' className="mb-2">
        <div className="col-md-12" >
          <fieldset className = 'fieldset mt-2' >
        <div className="row justify-content-between">
            <div className="col-md-4 form-group">
            
                <select aria-label="Default select example" className="w-25 form-control" 
                        onChange={(e) => setPostsParPages(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
            <div className="col-md-2">
                <div className="form-group row justify-content-end mb-4">
                <input  type="text" 
                    className="form-control col-md-5" 
                    placeholder='Rechercher...'
                    value = {value}
                    onChange={hundleChange}
                    />
                </div> 
            </div>
        </div>

            <h3 className='tilte-fieldset user'>Liste des élèves par ordre de mérite </h3>
            <table className='table table-bordered table-hover' >
            <thead>
                <tr style={{background :'#268d36', color:'#fff'}}>
                <th style={{width: '5px'}}>N°</th>
            
                <th>Matricule</th>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Date de naiss</th>
                <th>Moyen</th>
                <th>Rang</th>
                <th>Appréciation</th>
                
                </tr>
            </thead>
            <tbody>
            {donne.slice(offset, offset + postsParPages)
            .map((post,index) => ( 
            <tr>
                <td>{index+1}</td>
                <td>{post.eleve.matricule}</td>
                <td>{post.eleve.prenom}</td>
                <td>{post.eleve.nom}</td>
                <td>{post.eleve.dateNaiss}</td>
                <td>{post.moyen}</td> 
                <td>{post.rang}</td> 
                <td>{post.appreciation}</td> 
            </tr>
             ))} 
              
            </tbody>
            </table>
          </fieldset>
        </div>
      </div>
          {/* Pagination */}
          <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
          
    </Squelette>
  )

}
