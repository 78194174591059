import React from 'react'
import Squelette from '../squelette/Squelette'
import UpdateUser from './UpdateUser'
export default function SqUpdateUser() {
  return (
    <div>
        <Squelette>
            <UpdateUser/>
        </Squelette>
    </div>
  )
}
