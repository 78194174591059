import React from 'react'
import { useNavigate, useParams} from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Creation_niveau.css'
import Descripteur from '../accueil/Descripteur';
import APP_ENV from "../../config/.env";


function Update_niveau() {
    let niv = useParams();

    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    const [listeCouleur] = useState([
        {couleur: 'meuve', code: '#cc41be'},
        {couleur: 'jaune', code: '#e5ca3a'},
        {couleur: 'bleau', code: 'rgba(65, 215, 248, 0.6)'},
        {couleur: 'rouge', code: '#eb3333'},
        {couleur: 'verte', code: '#268d36'}
    ]);  
    const [formulaire, setFormulaire] = useState({
        couleur : '',
        libelle : '',
        type : '',
    })
  // Recuperation des niveau
  const update = async () => {
    await 
        axios(`${APP_ENV.baseUrl}/niveau-get/${niv.id}`,
        {headers: header}
      ).then(res=>{
            setFormulaire(res.data.niveau)
      })
      .catch(er =>{
        let ereur = er.response
        setError(ereur)
        // console.log(er)
      })
  }
    useEffect(()=>{
      update();
    },[])

    const [error, setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setFormulaire({...formulaire ,[name]:value})
    }

    //##### Soumission du Formulaire #####
    const hundleSubmit = (e) =>{    
      e.preventDefault();
      // Les controlle 
      axios.post(`${APP_ENV.baseUrl}/niveau-update/${niv.id}`,formulaire,
        {headers: header}
      ).then(res=>{
        if(res.status === 200){
          navigate('/niveau-liste')
        }
      })
      .catch(er =>{
        let ereur = er.response
        setError(ereur)
        // console.log(er)
      })
  }
     
  return (
<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
        <fieldset className = 'fieldset'>
          <h3 className='tilte-fieldset user'>Modification niveau</h3>
          <div className="row">
            
            <div className="inputboite col-md-11 m-2">
                <input type="text" placeholder="Libelle du niveau" name='libelle' value={formulaire.libelle} onChange = {hundeChange} required/>
            </div>
            <div className="inputboite col-md-11 m-2">
                <select name="type" onChange = {hundeChange} required>
                  { formulaire.type === "AR" ?
                    <option value="AR" selected>Arabe</option> 
                    : <option value="FR" selected>Français</option>
                  }
                    <option value="AR">Arabe</option> 
                    <option value="FR">Français</option>
                </select>
            </div>
            <div className="inputboite col-md-11 ml-2 mt-2">
                <label className='font-weight-bold mx-4 mb-2'>choisir une couleur</label>
            </div>
            <div className="col-md-12 " >
                <div className="row">
                    {
                    listeCouleur.map((item, index ) => (
                        <div className="col-md-2  d-flex align-items-center justify-content-center ml-3">
                          <Descripteur
                              colorBg = {item.code} width = {30} height={30}
                              key={index}
                          />
                            {
                                (item.code === formulaire.couleur)?
                                    <input type="radio" className='ml-1' name="couleur" value={item.code} onChange = {hundeChange} checked/> 
                                :<input type="radio" className='ml-1' name="couleur" value={item.code} onChange = {hundeChange}/> 
                            }
                        </div>
                      ))} 

                </div>                      
            </div>                    
          </div>
          {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
          : '' 
          }   
          <div className="col-md-12 conteneur-btn btnAjoutUser">
              <button className='btn1-connexion enregistrer'>Modifier</button>
              <button className='btn1-connexion annuler'>Annuler</button>
          </div>
    
        </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default Update_niveau;
