import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './ListeClasse.css'
import Descripteur from '../accueil/Descripteur';
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";
import AffichageClasseTempon from './AffichageClasseTempon';

function ListeClasse(anneeID) {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let navigate = useNavigate( );
    const [listeClasses, setListeClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [leChoix, setLeChoix] = useState('');
    // Recuperation liste des classes
    const getListClasse = async()=>{
        if(anneeID.anneeID > 0){
            
            await axios(`${APP_ENV.baseUrl}/classes-liste/${anneeID.anneeID}`,
                {headers: header}
            ).then(res=>{
                if(res.status === 200){
                    setListeClasses(res.data.classes)
                    setIsLoading(false)
                }
            })
            .catch(error =>{
                // let ereurs = error.response.data.error
            })

        }else{
           await axios(`${APP_ENV.baseUrl}/classes-liste`,
                {headers: header}
            ).then(res=>{
                if(res.status === 200){
                    setListeClasses(res.data.classes)
                    setIsLoading(false)
                }
            })
            .catch(error =>{
                // let ereurs = error.response.data.error
            })
        }    
        
    }
    let profil = localStorage.getItem('profil')

    useEffect(()=>{
        setIsLoading(true)
        getListClasse();
    },[])
    // console.log("choix")
    // console.log(anneeID.anneeID)
    // Action apres click sur une classe
    function clickClasse(choix){
        if(anneeID.anneeID > 0){
            setLeChoix(choix)
            if(choix){
                console.log("choix")
                console.log(choix)
                console.log(leChoix)
                // navigate('/classe-affiche-tempon/'+choix+'/'+anneeID.anneeID)
                // navigate('/classe-affiche-tempon/'+choix+'/'+anneeID.anneeID)
                
            } 
        }else{
            if(choix){
                navigate('/classe-affiche/'+choix)
            }
        }
    }

    return (
        <>
         {leChoix > 0 ?
         <AffichageClasseTempon classe={leChoix} annee={anneeID.anneeID}/> 
        :<Squelette>
         
         {isLoading ? <LoadingSpinner/> : 
        <>
        <div className='container'>
        
            {/* classe-create */}
            <div className="row justify-content-between">
                <div className="col-md-6"> 
                    <h2 style={{marginLeft: '115px'}}>Liste des Classes</h2>
                </div>
                {profil !== "Enseignent" &&
                    <div className="col-md-6  d-flex  linkUser" 
                            style={{paddingLeft: '215px',paddingTop: '20px'}}> 
                                <Link to="/classe-create" className='btn-classe mr-3 mb-3 '>  
                                    Création Classe 
                                </Link>          
                    </div>
                }
            </div>
              <div className='row justify-content-center'>
                  
                {listeClasses?listeClasses.map((classe, index ) => (                 
                    <div key={index} className='col-md-3 m-3 mr-3 p-2 shadow border border-success bloc-classe' onClick={e => clickClasse(classe.id, '')}>
                        <Descripteur texForm ={classe.code.substr(0,1).toUpperCase()} text = {classe.code +" / "+classe.type} detail={classe.effectif +" Elèves"} color = '#000'
                        colorBg = {classe.couleur} />
                    </div>  
                )):<></>}

            </div>
        </div></>}
    </Squelette>
    }</>
  )
}

export default ListeClasse;
