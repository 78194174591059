import React from 'react'
import ListeUser from './ListeUser'
import Squelette from '../squelette/Squelette'

export default function SqListeUser() {
  return (
    <div>
        <Squelette>
            <ListeUser/>
        </Squelette>
    </div>
  )
}
