import React from 'react'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Liste_niveau.css'
import APP_ENV from "../../config/.env";
import { Link } from "react-router-dom";
import DataTableNiveau from './DataTableNiveau';
import LoadingSpinner from '../helpers/LoadingSpinner'


function ListeNiveau() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }

    const [listeNiveau, setListeNiveau] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Recuperation liste des classes
    const getListeNiveau = async () => {
        
        await axios (`${APP_ENV.baseUrl}/niveau-list`,{headers: header}
        ).then(res=>{
            if(res.status === 200){
                setListeNiveau(res.data.niveaux)
                setIsLoading(false)  
              
            }
        })
        .catch(error =>{
      
        })
    }
        
    useEffect(()=>{
        setIsLoading(true)  
        getListeNiveau();
    },[])


    return (
    <Squelette>
         {isLoading ? <LoadingSpinner/>: 
           <>
        <div className="row d-flex justify-content-end "> 
            <div className="col-md-6 d-flex justify-content-end content-link-eleve">
                <Link to="/affectationNiveau" className=' btn-classe mb-4 mr-3 '>Affectation Niveau</Link>
                <Link to="/niveau-create" className=' btn-classe mb-4 mr-3 '>Nouvelle enregistrement</Link>
            </div>
    
        </div>
            
        <fieldset className = 'fieldset'>
            <h3 className='tilte-fieldset user'> Liste des niveaux </h3>
            <DataTableNiveau data ={listeNiveau}/>      
        </fieldset>
        </>}
    </Squelette>
  )
}

export default ListeNiveau ;
