import React, { useEffect,useState } from 'react'
import axios from 'axios'
import { useNavigate} from 'react-router-dom';
import LoadingSpinner from '../helpers/LoadingSpinner'
import APP_ENV from "../../config/.env";

export default function ListeUser({data}) {

   
    let navigate = useNavigate();
    const [listeUtilisateur, setListeUtilisateur] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [postsParPages, setPostsParPages] = useState(10);
    const [pageCourant ] = useState(1);
    
    const dernierPost = pageCourant * postsParPages;
    const premierePage = dernierPost - postsParPages;
    // const numberOfPage = Math.ceil(listeUtilisateur?.length / postsParPages)
    const postsCourrent = listeUtilisateur.slice(premierePage, dernierPost);
    const [value, setValue] = useState('') 
      
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      const [checked, setChecked] = useState([]);   
      const handleCheck = (event) => {
          var updatedList = [...checked];
          if (event.target.checked) {
            updatedList = [...checked, event.target.value];
          } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
          }
          setChecked(updatedList);  
      };
    
      const getData = async () => {
         setIsLoading(true);
         await  axios(`${APP_ENV.baseUrl}/users`, {
          method: "GET",
          headers: header
          })
          .then(res =>{
            const dataUser = res.data.donnees
            setListeUtilisateur(dataUser)
            setIsLoading(false)
          })
        }
      useEffect(() => {
          getData()
      }, []);

    const hundleSubmit = (e) =>{    
        e.preventDefault();
       data.email=checked
      // envoyé de message 
      if(data.type === "mail"){
        // Si c'est un email a envoyer
        axios.post(`${APP_ENV.baseUrl}/sendmail`,data,
          {headers: header}
        ).then(res => {
          navigate('/accueil')
        })
        .catch(er =>{
          let ereur = er
        })
      }else if(data.type === "sms"){
        // Si c'est un SMS a envoyer
        axios.post(`${APP_ENV.baseUrl}/envoieSMS`,data,
          {headers: header}
        ).then(res => {
          navigate('/accueil')
        })
        .catch(er =>{
          let ereur = er
        })
      }
    }
  return (
      <div className="container-fluid content-tab ">
         {isLoading ? <LoadingSpinner/>: 
           <>
          <fieldset  className = 'fieldset'>
          <form onSubmit={hundleSubmit} >
            <h3 className='tilte-fieldset user'>Liste de destinataires</h3>
            <div className="row">
                <div className="col-md-6 form-group">
             
                    <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                    </select>
                </div>
                <div className="col-md-6">
                   <div className="form-group row justify-content-end mb-4">
                    <input  type="text" 
                            className="form-control col-md-5" 
                            placeholder='Rechercher...'
                            value = {value}
                            onChange={(e)=>setValue(e.target.value)}
                            />
                  </div> 
                </div>
            </div>
                <div className="">
         <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff'}}>
                {/* <th style={{width:'4px', }}>N°</th> */}
              
                <th >Prénom</th>
                <th >Nom</th>
                <th >Email</th>
                <th >Téléphone</th>
                <th >Profil</th>
              </tr>
            </thead>
             
            <tbody>
              {postsCourrent.filter(el => (
              el.prenom.toLowerCase().includes(value) ||
              el.nom.toLowerCase().includes(value) ||
              el.email.toLowerCase().includes(value) ||
              el.telephone.toLowerCase().includes(value) ||
              el.profil.toLowerCase().includes(value)
              ))
              .map((post,index) => (
                <>
                <tr key={post.id}>
                  {/* <td>{index+1}</td> */}
                  
                  <td>
                     <input  type="checkbox" name= "email[]" value={data.type === "mail" ? post.email : post.telephone}
                                        onChange={handleCheck} /> 
                    &nbsp;&nbsp;&nbsp;{post.prenom}</td>
                  <td>{post.nom}</td>
                  <td>{post.email}</td>
                  <td>{post.telephone}</td>
                  <td>{post.profil}</td>
                </tr>
                    </>
              ))}
                <div className="col-md-12 conteneur-btn btnAjoutUser mb-4">
                  <button className='btn1-connexion enregistrer'>Envoyer</button>
                </div>
            </tbody>
          </table>
          </div>    
              </form>
          </fieldset>
          </>
        }  
       
      </div>
  )
}