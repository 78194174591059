import React, {useState} from 'react';
import { BsPencilSquare } from "react-icons/bs";

import { Link} from 'react-router-dom';
import { FaUserAlt} from 'react-icons/fa';
import axios from 'axios'
import APP_ENV from "../../config/.env";
import { formatTelephone} from "../helpers/Traitement";
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import ReactPaginate from 'react-paginate';

export default function DataTableSurveillent({data}) {
  function filteration(datas, values) {
    const newarr = datas.filter((item) => {
      if (!values) return true;
      if (
        item.prenom.toLowerCase().includes(value.toLowerCase()) ||
        item.matricule.toLowerCase().includes(value) ||
        item.nom.toLowerCase().includes(value.toLowerCase()) 
      ) {
        return true;
      }
      return false;
    });
    return newarr;
  }
  const [value, setValue] = useState('') 
  const donne = filteration(data,value)
  const [postsParPages, setPostsParPages] = useState(10);
  const [pageCourant , setPageCourant] = useState(0);
  const numberOfPage = Math.ceil(donne?.length / postsParPages)
  const offset = pageCourant * postsParPages;
   const hundleChange=(e)=>{
    setValue(e.target.value)
   }
  function handlePageClick({ selected: selectedPage }) {
    setPageCourant(selectedPage);
  }

  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
const deleteSurveillent=(userId)=> {
  axios.delete(`${APP_ENV.baseUrl}/deletSurveillent/`+userId, {
    headers: header
})
.then(res =>{
    window.location.reload(false)
})
 }

  return (
<div>
            <div className="row justify-content-between">
                <div className="col-md-6 form-group">
                    {/* <div className="mx-3">Entres par page</div> */}
                    <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                </div>
                <div className="col-md-2">
                   <div className="form-group row justify-content-end mb-4">
                    <input  type="text" 
                            className="form-control col-md-5" 
                            placeholder='Rechercher...'
                            value = {value}
                            onChange={hundleChange}
                            />
                  </div> 
                </div>
            </div>
            
                <div className="table-responsive">
         <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff'}}>
                <th style={{width: '10px'}}>N°</th>
                <th>Nom Complet</th>
                <th >Telephone</th>
                <th>Type Surveillent</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              
                 {donne?donne.slice(offset, offset + postsParPages)
              // postsCourrent.filter(el => (
              // el.nomComplet.toLowerCase().includes(value) ||
              // el.telephone.toLowerCase().includes(value) ||
              // el.type.toLowerCase().includes(value) 
        
              // ))
              .map((surveillent,index) => (
                <tr key={surveillent.id}>
                  <td>{index+1}</td>
                  <td>{surveillent.nomComplet}</td>
                  <td>{formatTelephone(surveillent.telephone)}</td>
                      {(surveillent.type === "AR")?
                        <td>Arabe</td>:
                        <></>
                       }
                    {(surveillent.type === "FR")?
                        <td>Français</td>:
                        <></>
                    }
                 
                 
                  <td>
                      <Link 
                        to ={`/modifierSurveillent/${surveillent.id}`} 
                        title = 'Modifier Surveillent'
                        >
                        <FaUserAlt className='action-icon'/>
                      </Link>
                      <Link 
                          to ={`/modifierSurveillent/${surveillent.id}`} 
                          title = 'Modifier Surveillent'
                          >
                            <BsPencilSquare  className='action-icon'/>
                      </Link>
                      <Link  
                       onClick={()=>{
                        //  window.location.reload(false)
                         deleteSurveillent(surveillent.id)
                        }}
                            title ='Suppression Surveillent'
                            style={{color:'#000'}}>
                              <label class="switchUser">
                                <input type="checkbox"/>
                                <span class="sliderUser roundUser"></span>
                              </label>
                    </Link>
        
                  </td>
                </tr>
              )):<></>}
            </tbody>
          </table>
          </div>
                 {/* Pagination */}
                 <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
        </div>
    
   
   
  )
}
