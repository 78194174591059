import { useNavigate,useParams } from 'react-router-dom';
import React, { useEffect,useState } from 'react'
import axios from 'axios';
import "./CreationEmploieTemp.css";
import APP_ENV from "../../config/.env";
import LoadingSpinner from '../helpers/LoadingSpinner'

function UpdateEmploisDuTemps({emploisDuTemps,parent}) {
    let navigate = useNavigate();
    let id = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [identifiant, setIdentifiant] = useState({
        jour : "",
        heureDebut : "",
        heureFin : "",
        matiere_id : "",
        enseignent_id : "",
        classe_id : ""
    })
    const jours = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi','samedi'];
    const [errorHeurFin, setErrorHeurFin] = useState('')
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [error, setError] = useState('')
    const [success] = useState('')
    const [errorType] = useState('')
    const [errorLibelle] = useState('')
      //##### Changement des inputs #####
    const hundeChange =(e)=>{
        const name = e.target.name;
        const value = e.target.value
        setIdentifiant({...identifiant ,[name]:value})
    }
      const [listMatiere, setListMatiere] = useState([])
      const [listEnseignent, setListEnseignent] = useState([])
      const [listClasse, setListClasse] = useState([])
      const getListMatiere = async () =>{
        setIsLoading(true)
   
        await  axios.get(`${APP_ENV.baseUrl}/listMatiere`, {
            headers: header,
          })
          .then(res =>{                
              setListMatiere(res.data.donnees)
          /// setIsLoading(false)
          })  
      }
       const getListEnseignent = async () =>{
        await  axios.get(`${APP_ENV.baseUrl}/enseignent-list`, {
            headers: header,
        })
        .then(res =>{                
                setListEnseignent(res.data.enseignents)
            /// setIsLoading(false)
            }) 
      }
     
     
      const getListClasses = async () =>{
        await  axios.get(`${APP_ENV.baseUrl}/classes-liste`,

        {headers: header}
        ).then(res=>{
            if(res.status === 200){
                setListClasse(res.data.classes)
            }
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
            // console.log(ereurs)
        })
      }
     
    
    const getById= async () =>{
        await axios.get(`${APP_ENV.baseUrl}/getById/${emploisDuTemps.id}`,

        {headers: header}
        ).then(res=>{
            setIdentifiant(res.data.data[0])
            setIsLoading(false)
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
            // console.log(ereurs)
        })
      }
      
      useEffect(() => {
       
          getListMatiere();
          getListEnseignent();
          getListClasses();
          getById()
    
      },[]);
     
      // je dois netoyer le DOM (alert error) apres Render avec useEffect()

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
        axios.post(`${APP_ENV.baseUrl}/updateEmploieTemp/${emploisDuTemps.id}`,identifiant, {
            headers: header
        })
        .then(res=>{
            // windows reload revoie a la  page parent (remplace navigate) 
            window.location.reload(false)
            setErrorHeurFin('')
            setError('')
            
        }).catch(er =>{
            let ereur = er.response.data.errors
            setError(ereur)   
        })
       
    }
    const deleteEmploieDuTemp=(userId)=> {
        axios.delete(`${APP_ENV.baseUrl}/deleteEmploieTemp/`+userId, {
          headers: header
      })
      .then(res =>{
          window.location.reload(false)
          navigate('/emplois-du-temps-classe/'+id.id)
      })
      }
  return (
      <div className='apporttconteiner'>
        {isLoading ? <LoadingSpinner/> : 
        <>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
        <fieldset className = 'fieldset'>
          <h4 className='tilte-fieldset user'>Modification emploie du temp</h4>
                {/* <h3 className=' tilte-fieldset mt-1 '>Création matière</h3>  */}
            <div className="row justify-content-center">
            {(error )  ? <>
                <div class="alert alert-danger col-11" role="alert">
                            <p className=' text-danger text-center'>{error}</p>
              </div>
              </> :
                                ''
                           }  
              {(success )  ? 
              <>
              <div class="alert alert-success col-11" role="alert">
                     
                                <p className=' text-black text-center'>{success}</p> 
              </div></>:
                        ''
                        }  
                <div className="col-md-12 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="form-control " id="sel1" name="jour" identifiant={emploisDuTemps.jour} onChange = {hundeChange} >
                        <option value={emploisDuTemps.jour}>{emploisDuTemps.jour}</option>
                        {jours.map((jour, index)=>(
                      
                            <option value={jour}>{jour.toUpperCase()}</option>
                      ))}
                        </select>
                        {(errorType)  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                    </div>
                </div>
                </div>
                <div className="row justify-content-center mt-4">
                <div className="col-md-6">
                    <div className="inputEmploieTemp">
                        <input type="text" placeholder="Heure de début"  name='heureDebut' value={ identifiant.heureDebut} onChange = {hundeChange}/>
                        {(errorLibelle )  ?
                             
                             <p className=' text-danger'>{errorLibelle}</p>:
                             ''
                             }  
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputEmploieTemp">
                        <input type="text" placeholder="Heure de fin" name='heureFin'  value={ identifiant.heureFin} onChange = {hundeChange}/>
                        {(errorHeurFin )  ?
                             
                             <p className=' text-danger'>{errorHeurFin}</p>:
                             ''
                             }  
                    </div>
                </div>
            </div> 

            <div className="row mt-4">
               
                <div className="col-md-6 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="form-control " id="sel1" name="matiere_id" value={identifiant.matiere_id}  onChange = {hundeChange} >
                        <option value={identifiant.matiere_id}>{identifiant.matiere}</option>
                        {listMatiere.map((post,index) => (
                            // emploisDuTemps
                           
                            <option value={post.id}>{post.libelle}</option>
                           
                          ))}
                        </select>
                        {(errorType )  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="form-control " id="sel1" name="enseignent_id"value={ identifiant.enseignent_id}  onChange = {hundeChange} >
                        <option value={identifiant.enseignent_id}>{identifiant.enseignent} </option>
                        {listEnseignent.map((post,index) => (
                           
                            <option value={post.id}>{post.prenom } {post.nom}</option>
                           
                          ))}
                        
                        </select>
                        {(errorType )  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                    </div>
                </div>
                </div>
                <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="form-control " id="sel1" name="classe_id" value={ identifiant.classe_id}  onChange = {hundeChange} >
                        <option value={identifiant.classe_id}>{identifiant.classe}</option>
                        {listClasse.map((post,index) => (
                           
                            <option value={post.id}>{post.libelle}</option>
                           
                          ))}
                        </select>
                        {(errorType )  ?
                             
                             <p className=' text-danger'>{errorType}</p>:
                             ''
                             }  
                    </div>
                </div>
                </div>
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Modifier</button>
                        <button className='btn1-connexion annuler' style={{backgroundColor:'red'}}  onClick={()=>{
                        //  window.location.reload(false)
                        deleteEmploieDuTemp(emploisDuTemps.id)
                        }}>Supprimer</button>
                    </div>
           
            </fieldset>
       </form>
       </>}
    </div>
  )
}
 
export default UpdateEmploisDuTemps;
