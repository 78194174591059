import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import "./ProfilUser.css";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
import Descripteur from '../accueil/Descripteur';
import {AccountService} from '../services/AccountService'

function ProfilUser() {
    const navigate = useNavigate()
    const Logout = () =>{
        AccountService.logout()
        navigate('/')
    }
    let id = useParams(); 
  
        const header = {
          Accept : "application/json", 
         "Content-Type": "multipart/form-data",
          WithCredentials : true,
          Authorization: "Bearer " + localStorage.getItem("token"),
        }
        const [profilUser , setProfilUser] = useState([])
        const [avatar , setAvatar] = useState('')
        const [profil , setProfil] = useState([])
        const [listMatiere , setListMatiere] = useState([])


        const getUsers = async () => {
          await
                      axios.get(`${APP_ENV.baseUrl}/user/`+id.id, {
                      headers: header
                  })
                  .then(res=>{
                  setProfilUser(res.data.user)
                  setProfil(res.data.profil)
                  setAvatar(res.data.avatar)

              })
              .catch(er =>{
                  let ereur = er.response.data
              })
        }
        const listclassedunenseignent = async () => {
          await
              axios.get(`${APP_ENV.baseUrl}/list-classe-d-unenseignent/`+id.id, {
                  headers: header
                  })
              .then(res=>{
                  setListMatiere(res.data.data)
                  
              }).catch(er =>{
                  // let ereur = er.response.data.errors.messag
              }) 
          }
        useEffect(()=>{
            listclassedunenseignent();
            getUsers();
    },[])

    return (
        <Squelette>
          <div className="container">
            <div className="entete">

                <div className="col-md-12 enteteVert">
                    <div className="row">
                    
                        <div className="col-md-3">
                            {(avatar === null || avatar === "") &&
                                <img className="profil-user" src={process.env.PUBLIC_URL + '/images/pp.png'} width="90"/>
                            }
                            {(avatar)&& 
                                <img className="profil-user" src={`${APP_ENV.urlAvatar}/avatar/${avatar}`} width="90" /> 
                            }

                        </div>
                        <div className="col-md-7">
                            
                        </div>
                        <div className="col-md-2 content-link-eleve" style={{marginTop: '40px'}}>
                            <button onClick = {Logout} className="dropdown-item">
                                Déconnexion
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 m-1 ">
                      <span style={{fontSize:'20px'}}>{profilUser.prenom} &nbsp; {profilUser.nom}</span>
                         <p style={{ color: '#2287c0'}}>{profil.libelle}</p>
                    </div>
                </div>
                {  ( profil.libelle == 'Enseignent' )?
                
                    <div className="row">
                    
                    <div className="col-md-4"></div>
                    
                    <div className="col-md-2 content-link-eleve ">
                        <div class="buton ">
                            <Link className="link" to="/mail"> Message</Link>
                        </div>
                    </div>

                 </div>
                  
                    : 
                    
                    <div className="row mx-3">
                        <div className="col-md-2 content-link-eleve">
                            <div class="buton ">
                                <Link className="link" to="/affectation"> Affectation</Link>
                            </div>
                        </div>
                        <div className="col-md-2 content-link-eleve">
                            <div class="buton ">
                                <Link className="link" to="/mail"> Message</Link>
                            </div>
                        </div> 
                    
                    </div> 
                }
                 {  ( profil.libelle == 'Enseignent' )?

                <div className="row">
                    <div className="col-md-1"></div>
                         {(listMatiere) ? listMatiere.map((classe, index ) => (
                                (index<=2) &&
                            <div className="col-md-3 cardZone">
                                <Descripteur texForm ={classe.classe.code.substr(0,1).toUpperCase() } 
                                    text = {classe.classe.code }  
                                    detail = {classe.matiere.libelle}  
                                    colorBg = '#cc41be'
                                    key={index}
                                />
                            </div>
                        )):
                        <div className="col-md-10 m-3 p-3 border border-success">
                            <p className="d-flex justify-content-center ">
                                Cette enseignant n'as pas encore de matières 
                            </p>
                        </div>}
                    </div>:
                    <div></div>
                            }
               
            </div>
            <br />
            <div className="row">
            <div className="col-md-12 cardEnBat">
                  <h2>
                     <span  className="donneePersonell">Données personnelles</span><br />
                    </h2>
                   <span className="text">Nom et prénom:  {profilUser.prenom}   {profilUser.nom}</span><br />
                    <span className="text">Email : <span style={{ color: '#2287c0'}}> {profilUser.email}</span></span><br />
                     <span className="text">Telephone : {profilUser.telephone}</span><br />
                     <span className="text">Role : {profil.libelle}</span>
   
             </div>
            </div>
        </div>
        

          </Squelette>
    )
}

export default ProfilUser;