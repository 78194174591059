import React from 'react'
import './BulletinNotePA.css'
import { BsFillPrinterFill } from "react-icons/bs";
import {FormatDate} from '../helpers/FormatDate'
import { Link} from 'react-router-dom';
import { FiCheck } from "react-icons/fi";


export default function BulletinNoteMA({donnee}) {
    
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = 'Bulletin de Note';
        window.print();
       document.body.innerHTML = originalContents; 
      }
      let profil = localStorage.getItem('profil')  
    
  return (
   
    <div className ='container'>
   
    <div  id="printablediv">
       
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>École Franco-Arabe</small>
            </div>
            <div className="col-md-4">
                <small className='d-flex justify-content-end'>السنة الدراسية : {donnee.anneeScolaire.code}</small>
                <small className='d-flex justify-content-end'> الفترة : {donnee.semestre}</small>
            </div>
        </div>
        <div className="row borederTitle mb-2 pb-1 ms-0" >
            <h4 className='title-bultin'>Bulletin de Note / <span>كشف الدرجات</span></h4>
        </div>
       
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {donnee.eleve.prenom}</small><br/>
                {/* <small>Né(e): {FormatDate(donnee.eleve.dateNaiss)}</small><br/> */}
                <small>Matricule: {donnee.eleve.matricule}</small>
            </div>
            <div className="col-md-3 mt-0">
                <small className='d-flex justify-content-center'> à {donnee.eleve.lieuNaiss}</small>
                <small className='d-flex justify-content-center'>Nombre d'élève: {donnee.rang.effectif}</small>
            </div>
            <div className="col-md-4 ">
                <small className='d-flex justify-content-end'>Nom: {donnee.eleve.nom}</small>
                <small className='d-flex justify-content-end'>Classe: {donnee.classe.code}</small>
                <small className='d-flex justify-content-end'>Classe Redoublee: 0</small>
            </div>
           
        </div>
        <div className="row justify-content-between">
           
            <div className="col-md-12 col-sm-10 px-0">
                    <table className='table table-bordered tableNote'>
                    <thead >
                        <tr className='entete-tableau' style={{fontSize: '12px'}}>
                            <th  style={{fontSize: '13px'}}>الملاحظات
                            </th> 
                            <th style={{fontSize: '13px'}}>  
                                x معدل
                            </th>                 
                            <th style={{fontSize: '13px'}}>  
                                معامل
                            </th>  
                            <th style={{fontSize: '13px'}}>  
                                معدل/20
                            </th>  
                            <th style={{fontSize: '13px'}}>  
                                إختبار
                            </th>                            
                            <th style={{fontSize: '13px'}}>  
                                واجبات
                            </th> 
                            <th  style={{fontSize: '13px'}}>Matières</th>
                            <th  style={{fontSize: '13px'}}>المواد</th>
                        </tr>
                    </thead>
                    <tbody id='ligneTab' className='corp-tab'>
                        {(donnee.matieres) && donnee.matieres.map((mat,index) => (
                        <tr style={{fontSize: '13px'}}>
                            
                            <td>{mat.appreciationNote}</td> 
                            <td>{mat.moyenx}</td> 
                            <td>{mat.coef}</td> 
                            <td>{mat.moyenMatiere}</td>  
                            <td>{mat.compo}</td>  
                            <td>{mat.devoir}</td>  
                            <td>{mat.description}</td>  
                            <td >{mat.libelle}</td>    
                        </tr>
                        ))} 
                    
                        <tr style={{fontSize: '12px', fontWeight: 'bold'}}>
                            <td></td>
                            <td>{donnee.sommeNotes}</td>
                            <td>{donnee.sommeCoefs}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className = 'total' colSpan={2}>
                                <h6 style={{float: 'center', fontWeight: 'bold'}}> مجموع الدرجات</h6>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>    
        <div className='footer-bulletin' style={{marginRight: 0}}>
            <div className="">
                <h6 className=''> <span className='moyenne'>
                    {donnee.moyen.valeur}/{donnee.moyen.damander } :</span> المعدل
                </h6>
            </div>
            <div className="">
                <h6 className=''> <span className='rang'>
                    {donnee.rang.position } : </span> الترتيب
                </h6>
            </div>
          
        </div>
        <div className='row justify-content-between tab2'>
            <div className="col-5">
            {donnee.semestre === "الاولى" ?
                <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                        <tr>
                            {(donnee.moyen.valeur >= 14 && donnee.moyen.valeur <= 20)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>يجب أن تستمر الجهود</th>
                        </tr>   
                        <tr>
                            {(donnee.moyen.valeur >= 10 && donnee.moyen.valeur < 14 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>يمكن أن تفعل ما هو أفضل</th>
                        </tr>
                        <tr>
                            {(donnee.moyen.valeur < 10 && donnee.moyen.valeur >= 9 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>غير كافٍ</th>
                        </tr>
                        <tr>
                            {(donnee.moyen.valeur < 9 && donnee.moyen.valeur >= 6 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>في خطر تكرار الفصل</th>
                        </tr>
                        <tr>
                            {(donnee.moyen.valeur < 6)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>في خطر طرض من المدرسة</th>
                        </tr>
                    </tbody>
                </table>
                : <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                        <tr style={{fontSize: '12px'}} >
                            <td>{donnee.moyenSemestre1+" / 20"}</td>
                            <th> معدل فترة الأولى</th>
                        </tr>   
                        <tr style={{fontSize: '12px'}} >
                            <td>{donnee.moyen.valeur+" / "+donnee.moyen.damander}</td>
                            <th> معدل فترة الثانية </th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            <td>{donnee.moyenAnnuelle+" / 20"}</td>
                            <th>معدل العام</th>
                        </tr>
                    </tbody>
                </table>
                }
            </div>
            <div className="col-5">
                <table className='table table-bordered col-md-4'>
                    <tbody id="tabApreciation" style={{fontSize: '12px', textAlign: "right"}}>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 14 && donnee.moyen.valeur <= 20 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>Félicitations/ تهنئة</th>
                        </tr>   
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 11 && donnee.moyen.valeur < 14 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>ينفخ فيه الحيوية و النشاط</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.rang.position <= 5 )?
                                <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>في قائمة الشرف</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur >= 8 && donnee.moyen.valeur < 11 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>تحذير لإعادة الفصل</th>
                        </tr>
                        <tr style={{fontSize: '12px'}} >
                            {(donnee.moyen.valeur < 8 )?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                            <th>اللوم لنتيجته سيئة </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className='signature'>
            <div className="" style={{textAlign: "center"}}>
                <small className=''>الولي</small><br/>
                <small className=''>le Parent</small>
            </div>
            <div className="" style={{textAlign: "center"}}>
                <small className=''>المعلم</small><br/>
                <small className=''>le Maitre</small>

            </div>
            <div className="" style={{textAlign: "center"}}>
                <small className=''>الادارة</small><br/>
                <small className=''>la Direction</small>

            </div>
            
        </div>
        </div>
        {/* {(profil === 'Admin') &&

        <div className="Rectangle-180 pl-3 pr-3 mt-5 " style={{marginRight: '300px'}} >
            <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
        </div>
        } */}
    </div>
 
  )
}
