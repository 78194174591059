let saveToken = (token,prenom,nom,profil,idUser,avatar,enseignent_id)=>{
    localStorage.setItem('token', token)
    localStorage.setItem("prenom", prenom);
    localStorage.setItem("nom", nom);
    localStorage.setItem("profil", profil);
    localStorage.setItem("idUser", idUser);
    localStorage.setItem("avatar", avatar);
    localStorage.setItem("enseignent_id", enseignent_id);
    // let enseignent_id = res.data.enseignent_id

}

let logout = () =>{
    localStorage.removeItem('token')
    localStorage.removeItem('prenom')
    localStorage.removeItem('nom')
    localStorage.removeItem('profil')
    localStorage.removeItem('idUser')
    localStorage.removeItem('avatar')
    localStorage.removeItem('enseignent_id')
}
let isLogged = () =>{
    let token = localStorage.getItem('token')
    return !!token
}
let isAdmin = () =>{
    let admin = localStorage.getItem('profil')
    if(admin === 'Admin')
        return admin;
}
let isComptable = () =>{
    let comptable = localStorage.getItem('profil')
    if(comptable === 'Comptable')
        return comptable;
}
let isEnseignent = () =>{
    let enseignent = localStorage.getItem('profil')
    if(enseignent === 'Enseignent')
        return enseignent;
}

export const AccountService = {
    saveToken, logout, isLogged,isAdmin,isComptable,isEnseignent

}