
import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { Link } from "react-router-dom";
import '../user/ListeUser.css'
import DataTableEleveArchive from './DataTableEleveArchive';
import LoadingSpinner from '../helpers/LoadingSpinner'


export default function ListElevesArchive() {
    const [dataEleve, setDataEleve] = useState([])
    const [isLoading, setIsLoading] = useState(false);
         
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }

      const eleveArchive = async () => {
      
        await
          axios
          .get(`${APP_ENV.baseUrl}/elevesArchive`, {
            headers: header,
          })
                    .then(res =>{
                     setDataEleve(res.data.eleves)
                     setIsLoading(false)
                    })          
      }
      
       useEffect(() => {
        setIsLoading(true)
        eleveArchive();
        
      },[]);
     
  return (
    <Squelette>
      {isLoading ? <LoadingSpinner/> : 
        <>
      <div className="container-fluid content-tab col-md-12">
      <div className='row' style={{ marginTop: "-50px" }}>
        <div className="col-md-9"></div>
     
                <div className="col-md-3">
               <Link to="/listeEleve"  className='btn btn-success font-weight-bold'style={{cursor: "pointer", color: "white", background :'#268d36', marginTop: "-60px" }} >Liste des élèves</Link>
                </div>
       
        </div>
        <div className="col-md-12" id='printablediv'>
          <fieldset className = 'fieldset'>
            <h3 className='tilte-fieldset user'>Liste des élèves archivés </h3>
                <DataTableEleveArchive data ={dataEleve}/>      
          </fieldset>
          </div>
      </div>
      </>}
    </Squelette>
  )

}
