import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { WiDirectionLeft, WiDirectionRight } from 'react-icons/wi';
import { HiChevronDown } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';

export default function ListNoteParClasseUnSamestre() {
  
    const [listNotes , setListeNotes ] =  useState([])
      function filteration(datas, values) {
        const newarr = datas.filter((item) => {
          if (!values) return true;
          if (
            item.prenom.toLowerCase().includes(value.toLowerCase()) ||
            item.matricule.toLowerCase().includes(value.toLowerCase()) ||
            item.nom.toLowerCase().includes(value.toLowerCase()) 
      
          ) {
            return true;
          }
          return false;
        });
        return newarr;
      }
      const [value, setValue] = useState('') 
      const donne = filteration(listNotes ? listNotes : [], value)
      const [postsParPages, setPostsParPages] = useState(10);
      const [pageCourant , setPageCourant] = useState(0);
      const numberOfPage = Math.ceil(donne?.length / postsParPages)
      const offset = pageCourant * postsParPages;
       const hundleChange=(e)=>{
          setValue(e.target.value)
       }
      function handlePageClick({ selected: selectedPage }) {
        setPageCourant(selectedPage);
      }
    let id = useParams(); 
    
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [ setIsLoading] = useState(false);
    const [recherche, setRecherche] = useState('')   
    const [listMatires, setListMatires] = useState('')   
    const [anneScolaie, setAnneScolaie] = useState('')   
    const [semestre, setSemestre] = useState('')   
    const [classe, setClasse] = useState('')   
    const [niveau, setNiveau] = useState('') 
    const [typeClasse, setTypeClasse] = useState('') 
         
    const [listMatiresCl, setListMatiresCl] = useState('')   
    const anneeScolaire =  localStorage.getItem("anneeScolaire");
    
    // console.log("anneeScolaire")
    // console.log(anneeScolaire)
    
    const listNotesRaporteParClasse = async ()=>{
      await axios(`${APP_ENV.baseUrl}/listNotesRaporteParClasse/${id.id}/1/0/${anneeScolaire}`,
        {headers: header}

        ).then(res=>{
            setListeNotes(res.data.data)
            setAnneScolaie(res.data.data[0].anneeScolaire)
            setSemestre(res.data.data[0].semestre)
            setClasse(res.data.data[0].classe)
            setNiveau(res.data.data[0].niveau)
            setTypeClasse(res.data.data[0].typeCl)
            setListMatires(res.data.lisMatiere)
            setListMatiresCl(res.data.lisMatiere) // (Ne pas touché)Exclusivement pour le filtrage              

            setIsLoading(false);
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
        })
    }
      useEffect(()=>{
       
        listNotesRaporteParClasse();
    },[])

    const hundeRecherche =(ev)=>{
        const name = ev.target.name;
        const value = ev.target.value
        setRecherche({...recherche ,[name]:value})
    }
    const hundleRecherche = (ev) =>{    
        ev.preventDefault();
        axios(`${APP_ENV.baseUrl}/listNotesRaporteParClasse/${id.id}/${recherche.semestre}/${recherche.matiere}/${anneeScolaire}`,{headers: header}
       
        ).then(res=>{
            setListeNotes(res.data.data)
            setListMatires(res.data.lisMatiere)
            setAnneScolaie(res.data.data[0].anneeScolaire)
            setSemestre(res.data.data[0].semestre)
            setClasse(res.data.data[0].classe)
            setNiveau(res.data.data[0].niveau)
            setTypeClasse(res.data.data[0].typeCl)

        })
        .catch(er =>{
        //   let ereur = er.response.data.error
          //setError(ereur)
        })
    }

  return (
    <Squelette>
       
      <div className="row">
            <div className="col-md-6">
              <p >
                <h5> Abou Oubayda As Sakina</h5>
              </p>

            </div>
            <div className="col-md-6 d-flex justify-content-end  ">
                <p>Annee scolaire: </p> <h5> &nbsp;{anneScolaie}</h5>
            </div>
        </div>
        <div className="row d-flex justify-content-center">
            <div className="col-md- 4 d-flex justify-content-between ">
                
                <p style={{fontSize:'20px'}}>
                  Classe : <span style={{fontSize:'20px'}}>
                    {typeClasse === "FR" ?
                      <>{classe.toUpperCase() +" (Français)"}</>
                    : <>{classe.toUpperCase() +" (Arabe)"}</>
                  }</span>
                </p>
                
                <p style={{fontSize:'20px'}}>{semestre} {(semestre === "1" ) ? 
                  " ère": " ème"} semestre 
                </p>
            </div>
        </div>

        <form onSubmit={hundleRecherche} >
        
        <div className="row justify-content-between">
            <div className="col-md-3 inputSelect inputSelectNote">
                <HiChevronDown className='icon-chevrone icon-selectNote mr-5 mt-4'/>
                <select name="semestre" value={recherche.semestre} onChange = {hundeRecherche}>
                    <option>Choisir une semestre</option>
                    <option value="1">Première semestre</option> 
                    <option value="2">Deuxième semestre</option> 
                </select>
            </div>  
            <div className="col-md-3 inputSelect inputSelectNote">
                <HiChevronDown className='icon-chevrone icon-selectNote mt-4 mr-5'/>
                <select name="matiere" value={recherche.matiere} onChange = {hundeRecherche}>
                    <option>Choisir une matière</option>
                    {(listMatiresCl) ? listMatiresCl.map((listMatire, index) => (
                        <option value={listMatire.id}>{ listMatire.libelle} ({listMatire.type})</option>
                    )) : <h4>Pas de Matière trouvés</h4>}
                </select>
            </div>  
            <div className="col-md-5 content-link-eleve" style={{margin: '20px 0px 10px 0'}}>
                <button className='btn-classe' style={{padding: "12px 30px", margin: "13px 0 0 0"}}>Rechercher</button>
            </div>  
            
            </div> 
        </form>
              
        <div className="col-md-12 mt-5" id='printablediv'>
          <fieldset className = 'fieldset' >
            <h3 className='tilte-fieldset user'>Fiche des notes</h3>

            <div className="row justify-content-between mx-0">
              <div className="col-md-6 form-group">
              
                  <select aria-label="Default select example" className="w-25 form-control" 
                          onChange={(e) => setPostsParPages(e.target.value)}>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                  </select>
              </div>
              <div className="col-md-2">
                  <div className="form-group row justify-content-end mb-2">
                  <input  type="text" 
                      className="form-control col-md-5" 
                      placeholder='Rechercher...'
                      value = {value}
                      onChange={hundleChange}
                      />
                  </div> 
              </div>
          </div>

          <table className='table table-bordered table-hover' >
            <thead>
              <tr style={{background :'#268d36', color:'#fff', textAlign: "center"}}>
                <th style={{width: '5px'}}>N°</th>
                
                <th>Matricule</th>
                <th>Prénom (s)</th>
                <th>Nom</th>
              
                <th>Matière</th>
                {(niveau !== 'Primaire')&&
                <>
                  <th>Dev N°1</th>
                  <th>Dev N°2</th>
                  <th>Composition</th>
                </>
                }
                {(niveau === 'Primaire' && typeClasse === "FR") ?
                // Pour le Primaire français
                <>
                  <th>Ressource</th>
                  <th>compétence</th>
                </>
                // Les autres classes
                : <th>Semestre</th>
              }
              </tr>
          </thead>
        <tbody>
        {donne !== null? donne.slice(offset, offset + postsParPages)
            .map((post,index) => ( 
            <tr style={{textAlign: "center"}}>
                <td>{index+1}</td>
                <td>{post.matricule}</td>
                <td>{post.prenom }</td>
                <td>{post.nom}</td>
                <td>{post.matiere}</td>
               
                 {(niveau !== 'Primaire')&&
              <>
                {(post.dev1 === null )?
                 <td>-</td>:
                 <td>{post.dev1}</td>
                }
                 {(post.dev2 === null )?
                 <td>-</td>:
                 <td>{post.dev2}</td>
                }
                 {(post.compo === null )?
                 <td>-</td>:
                 <td>{post.compo}</td>
                }
              </>}
              {(niveau === 'Primaire' && typeClasse === "FR") ?
                // Pour le Primaire français
                 <> 
                  <td>{(post.noteSmestre.ressource == null) ? '-' : post.noteSmestre.ressource}</td>  
                  <td>{(post.noteSmestre.competence == null)? '-' : post.noteSmestre.competence}</td>  
                </>
                // Pour les autres classes
              : <td>{post.noteSmestre}</td>  
              }
            </tr>
            ))
           :<td style={{textAlign: 'center'}} colSpan="9"> 
                Pas encore de notes reportées pour cette classe
            </td>
          }  
         
              
        </tbody>
        </table>
          </fieldset>
          </div>
           {/* Pagination */}
           {donne && 
           <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
        }
    </Squelette>
  )

}