import React, {useState} from 'react';
import { Link} from 'react-router-dom';
import axios from 'axios'
import APP_ENV from "../../config/.env";
import ReactPaginate from 'react-paginate';

export default function DataTableUserArchive({data}) {
  function filteration(datas, values) {
    const newarr = datas.filter((item) => {
      if (!values) return true;
      if (
        item.prenom.toLowerCase().includes(value.toLowerCase()) ||
        item.telephone.toLowerCase().includes(value) ||
        item.profil.toLowerCase().includes(value)||
        item.nom.toLowerCase().includes(value.toLowerCase()) 
  
      ) {
        return true;
      }
      return false;
    });
    return newarr;
  }
  const [value, setValue] = useState('') 
  const donne = filteration(data,value)
  const [postsParPages, setPostsParPages] = useState(10);
  const [pageCourant , setPageCourant] = useState(0);
  const numberOfPage = Math.ceil(donne?.length / postsParPages)
  const offset = pageCourant * postsParPages;
   const hundleChange=(e)=>{
    setValue(e.target.value)
   }
  function handlePageClick({ selected: selectedPage }) {
    setPageCourant(selectedPage);
  }
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
  const deleteUser=(userId)=> {
    axios.delete(`${APP_ENV.baseUrl}/deArchiveUser/`+userId, {
      headers: header
    })
    .then(res =>{
        // navigate('/utilisateur-liste')
        window.location.reload(false)
    })
  }
return (
  <div>
      <div className="row justify-content-between">
          <div className="col-md-6 form-group">
        
              <select aria-label="Default select example" className="w-25 form-control" 
                    onChange={(e) => setPostsParPages(e.target.value)}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
          </div>
          <div className="col-md-2">
              <div className="form-group row justify-content-end mb-4">
              <input  type="text" 
                className="form-control col-md-5" 
                placeholder='Rechercher...'
                value = {value}
                onChange={hundleChange}
              />
            </div> 
          </div>
      </div>
        
          <div className="table-responsive">
    <table className='table table-bordered table-hover' >
      <thead>
        <tr style={{background :'#268d36', color:'#fff'}}>
          <th style={{width: '5px'}}>N°</th>
          <th >Prénom</th>
          <th >Nom</th>
          <th>Téléphone</th>
          <th >Profil</th>
          <th >Actions</th>
        </tr>
      </thead>
      <tbody>
        {(donne.length === 0) ? (
                <tr className={'tablerow'}>
                  <td colspan="6" className={'tabledata'}
                  style = {{textAlign: 'center'}}>
                   Aucun enregistrements correspondants trouvés
                  </td>
                </tr>): 
              donne.slice(offset, offset + postsParPages)
        .map((post,index) => (
          <tr key={post.id}>
            <td>{index+1}</td>
            <td>{post.prenom}</td>
            <td>{post.nom}</td>
            <td>{post.telephone}</td>
            <td>{post.profil}</td>
            <td>
                
                <Link  
                  onClick={()=>{
                    deleteUser(post.id)
                  }}
                      title ='Suppression élève'
                      style={{color:'#000'}}>
                        <label class="switch">
                          <input type="checkbox"/>
                          <span class="slider round"></span>
                        </label>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  {/* Pagination */}
                 <div className={'datatablefooter'}>
              <div className={'datatableshowing'}>
                <span>
                  Showing {offset + 1 } to {offset + postsParPages} of {donne.length}{" "}entries
                </span>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next "}
                pageCount={numberOfPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousLinkClassName={''}
                nextLinkClassName={''}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginateActive'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
        </div> 
  </div>


   
  )
}
