import React from 'react'


function CardList({title, children, color, classname}) {
  return (
    <div className={classname} style={{backgroundColor :color}}>
        <h4 className='card-title'>{title}</h4> 
        {children}
    </div>
  )
};
export default CardList;
