import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Eleve.css";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import Recu from "./Recu"
//Success POPUP
// import Swal from 'sweetalert2'

function AjouEleve() {
  const [ tarif, setTarif] = useState({});
  const [ setError] = useState("");
  const [classeAR, setClasseAR] = useState([]);
  const [classeFR, setClasseFR] = useState([]);
  const [matricule, setMatricule] = useState('');
  const [extrait, setExtrait] = useState("");
  const [paiementOk, setPaiementOk] = useState(false)    
  const [tarification, setTarification] = useState('')    
  const [anneeScolaire, setAnneeScolaire] = useState()

  // useState pour les validation
  const [errorPrenom, setErrorPrenom] = useState('')
  const [errorNom, setErrorNom] = useState('')
  const [errorDateNais, setErrorDateNais] = useState('')
  const [errorLieuNais, setErrorLieuNais] = useState('')
  const [errorSexe, setErrorSexe] = useState('') 
  const [errorClArabe, setErrorClArabe] = useState('') 
  const [errorClFranca, setErrorClFranca] = useState('') 
  const [errorNomCPere, setErrorNomCPere] = useState('') 
  const [errorNomCMere, setErrorNomCMere] = useState('') 
  const [errorNomCTuteur, setErrorNomCTuteur] = useState('') 
  const [errorTelephoneTuteur, setErrorTelephoneTuteur] = useState('') 
  const [errorTypeEleve, setErrorTypeEleve] = useState('') 
  const [errorChargePersonalise, setChargePersonalise] = useState('') 

  const [identifiant, setIdentifiant] = useState({
    prenom: "",
    nom: "",
    dateNaissance: "",
    lieuNaissance: "",
    sexe: "",
    classeAR: "",
    classeFR: "",
    extrait: "",
    nomCompletPere: "",
    nomCompletMere: "",
    nomCompletTuteur: "",
    telephoneTuteur: "",
    emailTuteur: "",
    chargePersonalise: "",
    typeEleveNormal: "Normale",
    typeElevePriseEnCharge: "Prise en charge",
    typeEleveAvecInscription: "Avec inscription",
    typeEleveEnChargePersonalise: "En charge personalise",
    montantInscription: 0,
    // Normal
  });
  const header = {
    Accept: "application/json",
    WithCredentials: true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  //##### Changement des inputs #####
  const hundeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type !== "file") {
      setIdentifiant({ ...identifiant, [name]: value });
    } else {
      setExtrait(e.target.files[0]);
    }
  };
  const filUploade = (id) => {
    const formData = new FormData();
    formData.append("extrait", extrait);
    axios
      .post(`${APP_ENV.baseUrl}/uplodeFile/${id}`,formData,{
        headers: header,
      })
      
  };


  const getClassseAR =  async () => {
      await
      axios
        .get(`${APP_ENV.baseUrl}/classe-ar`, {
          headers: header,
        })
        .then((res) => {
          setClasseAR(res.data.classeAR);
          
        })
        .catch((er) => {
          let ereur = er.response.data.errors;
          setError(ereur);
        });
      }
  const getClassseFR =  async () => {
    await
    axios.get(`${APP_ENV.baseUrl}/classe-fr`, {
        headers: header,
      })
      .then((res) => {
        setClasseFR(res.data.classeFR);
       
      })
      .catch((er) => {
        let ereur = er.response.data.errors;
        setError(ereur);
      });
    }
    // Tarification
    const getTarification =  async () => {
      await axios.get(`${APP_ENV.baseUrl}/tarif-get`, {
          headers: header,
        }).then((res) => {
          setTarif(res.data.tarification);
        }).catch((er) => {
          // let ereur = er.response.data.errors;
          // setError(ereur);
        });
      }
  useEffect(() => {
    getTarification();
    getClassseAR();
    getClassseFR();
  },[]);

  //##### Soumission du Formulaire #####
  const hundleSubmit = (e) => {
    e.preventDefault();
// validation des champs
      if (identifiant.prenom === '') {
        setErrorPrenom("Veillez saisir le  prénom")
      }else{
      setErrorPrenom('')
      }
      if (identifiant.nom === '') {
      setErrorNom("Veillez saisir le  nom")
      }else{
      setErrorNom('')
      }
      if (identifiant.dateNaissance === '') {
        setErrorDateNais("Veillez saisir date de naissance")
        }else{
          setErrorDateNais('')
        }
        if (identifiant.lieuNaissance === '') {
          setErrorLieuNais("Veillez saisir le  nom")
          }else{
            setErrorLieuNais('')
          }
          if (identifiant.sexe === '') {
            setErrorSexe("Veillez saisir le genre")
            }else{
              setErrorSexe('')
            } if (identifiant.classeAR === '') {
              setErrorClArabe("Séléctionnez la classe arabe") 
              }else{
                setErrorClArabe('')
              } if (identifiant.classeFR === '') {
                setErrorClFranca("Séléctionnez la classe franca")
                }else{
                  setErrorClFranca('')
                }
                if (identifiant.nomCompletMere === '') {
                  setErrorNomCPere("Saissir  le nom de père")
                  }else{
                    setErrorNomCPere('')
                  }
                  if (identifiant.nomCompletMere === '') {
                    setErrorNomCMere("Saissir  le nom de la mère")
                    }else{
                      setErrorNomCMere('')
                    }
                    if (identifiant.nomCompletTuteur === '') {
                      setErrorNomCTuteur("Saissir  le nom de la tuteur")
                      }else{
                        setErrorNomCTuteur('')
                      }
                      if (identifiant.telephoneTuteur === '') {
                        setErrorTelephoneTuteur("Saissir le téléphone du tuteur")
                        }else{
                          setErrorTelephoneTuteur('')
                        }
                        if (identifiant.typeEleveNormal === '' && identifiant.typeEleveAvecInscription && identifiant.typeElevePriseEnCharge && identifiant.typeEleveEnChargePersonalise) {
                          setErrorTypeEleve("Choissir le type d'élève ")
                          }else{
                            setErrorTypeEleve('')
                          }
                    if (identifiant.chargePersonalise === '') {
                      setErrorTelephoneTuteur("Saissir la mensualité de cet élève")
                    }else{
                      setErrorTelephoneTuteur('')
                    }
                          
      axios.post(`${APP_ENV.baseUrl}/inscription`, identifiant, {
          headers: header,
        })
        .then((res) => {
          filUploade(res.data.eleve?.id)
          setMatricule(res.data.eleve.matricule)
          setTarification(res.data.tarification.montantInscription)
          setAnneeScolaire(res.data.anneeScolaire.code)
          setPaiementOk(true);
        })
        .catch((er) => {
        });
  };

  return (
    <Squelette>
       {(paiementOk)?
        <>
       < Recu data={matricule} tarification={tarification} anneeScolaire={anneeScolaire} />
        </>:
        <>
      <div className="eleveconteiner ml-4 ">    
        <div className="Rectangle-691">
          <div className="Rectangle-701">
            <button className="inscription">
              Inscription
            </button>
            <button className="reinscription">
              <Link style={{color: "#268d36"}} to="/reinscription" >Réinscription</Link> 
            </button>
          </div>
          
       </div>
        <form className="eleveform formulaire ml-3" onSubmit={hundleSubmit}>
        
          <fieldset className=" fieldset-inscrire justify-content-center" >
            <div className="row  mr-4">
              <p className="text mt-4">
                Informations personnelles de l’élève
              </p>
              <hr 
              className="bar"
              >

              </hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Prénom"
                    name="prenom"
                    value={identifiant.prenom}
                    onChange={hundeChange}
                    
                  />
                    {(errorPrenom)  ?
                      <p className=' text-danger'>{errorPrenom}</p>:
                      ''
                    }
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom"
                    name="nom"
                    value={identifiant.nom}
                    onChange={hundeChange}
                  />
                    {(errorNom)  ?
                            <p className=' text-danger'>{errorNom}</p>:
                           ''
                            }
                </div>
              </div>
            </div>
            <div className="row mr-4">
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="date"
                    placeholder="Date de naissance"
                    name="dateNaissance"
                    value={identifiant.dateNaissance}
                    onChange={hundeChange}
                  />
                    {(errorDateNais)  ?
                            <p className=' text-danger'>{errorDateNais}</p>:
                           ''
                            }
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Lieu de naissance"
                    name="lieuNaissance"
                    value={identifiant.lieuNaissance}
                    onChange={hundeChange}
                  />
                   {(errorLieuNais)  ?
                            <p className=' text-danger'>{errorLieuNais}</p>:
                           ''
                            }
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="sexe"
                    value={identifiant.sexe}
                    onChange={hundeChange}
                  >
                   
                     <option>Séléction le sexe</option>
                    <option value="H">Homme</option>
                    <option value="F">Femme</option>
                  </select>
                  {(errorSexe)  ?
                            <p className=' text-danger'>{errorSexe}</p>:
                           ''
                            }
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="classeAR"
                    value={identifiant.classeAR}
                    onChange={hundeChange}
                  >
                     
                    {/* <option >Classe Arabe</option> */}
                    <option>Choisir la classe arabe</option>
                    {classeAR.map((classear, index = 1) => (
                      <option value={classear.id}>{classear.code}</option>
                    ))}
                  </select>
                  {(errorClArabe)  ?
                            <p className=' text-danger'>{errorClArabe}</p>:
                           ''
                            }
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="classeFR"
                    value={identifiant.classeFR}
                    onChange={hundeChange}
                  >
                    
                    <option>Choisir la classe français</option>
                    {classeFR.map((classefr) => (
                      <option value={classefr.id}>{classefr.code}</option>
                    ))}
                  </select>
                  {(errorClFranca)  ?
                            <p className=' text-danger'>{errorClFranca}</p>:
                           ''
                            }
                </div>
              </div>
            </div>
            <div className="row mb-4 mt-4  d-flex justify-content-center">
             
                <div class="mb-3 col-9 ">
                  <label for="formFile" className="form-label d-flex justify-content-center">Selectionner l'extrait en format( pdf)</label>
                  <input className="form-control"  type="file"
                    name="extrait"
                    // value={identifiant.extrait}
                    onChange={hundeChange} id="formFile"/>
                </div>

                
            </div>
            <div className="row mr-4">
              <p className="text "> Informations tuteurs</p>
              <hr 
              className="bar"
              >

              </hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Non Complet du père"
                    name="nomCompletPere"
                    value={identifiant.nomCompletPere}
                    onChange={hundeChange}
                  />
                   {(errorNomCPere)  ?
                            <p className=' text-danger'>{errorNomCPere}</p>:
                           ''
                            }
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet de la mère"
                    name="nomCompletMere"
                    value={identifiant.nomCompletMere}
                    onChange={hundeChange}
                  />
                   {(errorNomCMere)  ?
                            <p className=' text-danger'>{errorNomCMere}</p>:
                           ''
                            }
                </div>
              </div>
            </div>

            <div className="row mr-4">
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet du tuteur"
                    name="nomCompletTuteur"
                    value={identifiant.nomCompletTuteur}
                    onChange={hundeChange}
                  />
                   {(errorNomCTuteur)  ?
                            <p className=' text-danger'>{errorNomCTuteur}</p>:
                           ''
                            }
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Téléphone tuteur"
                    name="telephoneTuteur"
                    value={identifiant.telephoneTuteur}
                    onChange={hundeChange}
                  />
                   {(errorTelephoneTuteur)  ?
                            <p className=' text-danger'>{errorTelephoneTuteur}</p>:
                           ''
                            }
                </div>
              </div>
            </div>
            <div className="row">
            
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Email du tuteur"
                    name="emailTuteur"
                    value={identifiant.emailTuteur}
                    onChange={hundeChange}
                  />
                   {/* {(errorTelephoneTuteur)  ?
                            <p className=' text-danger'>{errorTelephoneTuteur}</p>:
                           ''
                            } */}
                </div>
             
            </div>
            {/* Prise en charge */}
            <span className="text"> Prise en charge</span>
           <div className="row">

            <hr 
            className="bar"
            ></hr>
            </div>
            <div className="form-check">
              <input id="normal"
                className="form-check-input"
                type="radio"
                name="typeEleve"
                value={identifiant.typeEleveNormal}
                onChange={hundeChange} 
              />
              <label className="form-check-label">Non prise en charge</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="typeEleve"
                value={identifiant.typeElevePriseEnCharge}
                onChange={hundeChange} 
              />
              <label className="form-check-label">Prise en charge total</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio" id="no"
                name="typeEleve"
                value={identifiant.typeEleveAvecInscription}
                onChange={hundeChange}
              />
              <label className="form-check-label">
                Prise en charge mais avec inscription
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio" id="no"
                name="typeEleve"
                value={identifiant.typeEleveEnChargePersonalise}
                onChange={hundeChange}
              />
              <label className="form-check-label">
                Prise en charge pérsonalisée
              </label>
            </div>
           

            {identifiant.typeEleve === 'Normale' &&
            <div className="row mr-4 " >
              <div className="col-md-6">
                <div className="inputboite" >
                  <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                    type="text"
                      value={tarif.montantInscription+ ' F CFA'} disabled
                      />
                </div>
              </div>
                  <div className="col-md-6">
                    <div className="inputboite">
                      <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                        type="text"
                          value={tarif.montantMensualite+ ' F CFA'} disabled
                          />
                    </div>
                  </div>
            </div>
          }
           {identifiant.typeEleve === 'Avec inscription' &&
            <div className="row mr-4 " >
              <div className="col-md-12">
                      <div className="inputboite" >
                          <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                            type="text"
                             value={tarif.montantInscription+ ' F CFA'} disabled
                          />
                    </div>
              </div>
                 
            </div>
            }
            {identifiant.typeEleve === 'En charge personalise' &&
            <div className="row mr-4 " >
              <div className="col-md-12">
                <div className="inputboite" >
                  <input
                    type="number"
                    placeholder="Mensualité de l'élève"
                    name="chargePersonalise"
                    value={identifiant.chargePersonalise}
                    onChange={hundeChange}
                  />
                   {(errorChargePersonalise)  ?
                      <p className=' text-danger'>{errorChargePersonalise}</p>:
                      ''
                    }
                </div>
              </div>
                 
            </div>
            }
            {(errorTypeEleve)  ?
              <p className=' text-danger'>{errorTypeEleve}</p>:
              ''
            }

            <div>
              <div className="col-md-12 conteneur-btn ">
                <button type="submit" className="btn1-connexion enregistrer">
                  Enregistrer
                </button>
                <button className="btn1-connexion annuler">Annuler</button>
              </div>
            </div>
          
          </fieldset>
      
        </form>
      </div>
      <br/>
      </>}
    </Squelette>
    
  );
}

export default AjouEleve;
