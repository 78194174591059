import React from 'react'
import './BulletinNoteP1.css'
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
import BulletinNoteClassePA from './BulletinNoteClassePA';
import { BsFillPrinterFill } from "react-icons/bs";
import { Link} from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { FaDAndD } from 'react-icons/fa';


export default function BulletinNoteClasseM({donnee}) {

    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [classe, setClasse] = useState([]);
    const [data] = useState({});
    const [niveau, setNiveau] = useState({});
    const [donnees, setDonnees] = useState([]);
    const anneeScol =  localStorage.getItem("anneeScolaire");

    // const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        // data.classe_id = donnee.classe_id
        // data.semestre = donnee.semestre
        lastAnneeScol()
    } ,[])
    const lastAnneeScol = async() =>{
        await  axios(`${APP_ENV.baseUrl}/lastAnnee`,
        {headers: header}
        ).then(res=>{
        
            donnee.annee_id = res.data.annee.id
            donnee.annee_id = anneeScol
       
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
        })       
    }
  
const bulletineleveclasse = async() =>{
    donnee.annee_id = anneeScol

    await
    axios.post(`${APP_ENV.baseUrl}/bulletin-eleve-classe`,donnee, 
    {headers: header}
    ).then(res=>{
  
       setDonnees(res.data.data)
           
        setClasse(res.data.data[0].classe.type)
        setNiveau(res.data.data[0].niveau)
        setAnneeScolaire(res.data.data[0].anneeScolaire.code)
    })
    .catch(error =>{
    })
}
   
    useEffect(()=>{
        lastAnneeScol();
        bulletineleveclasse();
     
    },[])
    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }
    let profil = localStorage.getItem('profil')  
    
    return (
      <Squelette >
        {( niveau === 'Primaire' && classe === 'AR') ?
            <BulletinNoteClassePA donnees={donnees}/>
            :
            <>
        <div>
        <div className ='container'>
            {(profil === 'Admin') &&
                <div className="Rectangle-180 pl-3 pr-3 mb-4 " style={{marginRight: '300px'}} >
                    <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
                </div>
            }
        <div id="printablediv">

        {donnees ? donnees.map((donnee,index) => (
        
        // <div className="" style={{height: '1050px', borderBottom: '10px solid black;'}} >
        <div className="page" >
        
        <div className="herder-bulletin ">
            <div className="col-md-4">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>Ecole Franco-Arabe</small>
            </div>
            <div className="col-md-4">
                  <small className=' d-flex justify-content-end'>Année Scolaire: {anneeScolaire} </small><br/>
              <small className=' d-flex justify-content-end'>{donnee.semestre} semestre</small>
               
            </div>
        </div>
        <div className="row borederTitle mb-2 ms-0" >
            <h4 className='title-bultin'>Bulletin de Note</h4>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {donnee.eleve.prenom}</small><br/>
                {/* <small>Né(e): {donnee.eleve.dateNaiss}</small><br/> */}
                <small>Matricule: {donnee.eleve.matricule}</small>
            </div>
            <div className="col-md-4 mt-3">
                <small className="d-flex justify-content-center"> à {donnee.eleve.lieuNaiss}</small>
                <small className="d-flex justify-content-center">Nombre d'élève: {donnee.rang.effectif}</small>
            </div>
            <div className="col-md-4 px-3">
                <small className="d-flex justify-content-end">Nom: {donnee.eleve.nom}</small>
                <small className="d-flex justify-content-end">Classe: {donnee.classe.code}</small>
                <small className="d-flex justify-content-end">Classe Redoublée: 0</small>
            </div>
        </div>
        <table className='table table-bordered ' >
            <thead>
                <tr className='entete-tableau' style={{fontSize: '11px'}} >
                    <th>Matières</th>
                    <th>Devoir</th>
                    <th>Comp</th>
                    <th>Moy/20</th>
                    <th>Coéf</th>
                    <th>Moy X</th>
                    <th>Appréciation</th>
                </tr>
            </thead>
            <tbody id='ligneTab' >       
                {donnee.matieres ? donnee.matieres.map((mat,index) => (
                 <tr key={index} style={{fontSize: '11px',}} >
                    <td style={{fontSize: '11px',}}>{mat.libelle}</td>
                    <td>{mat.devoir}</td>
                    <td>{mat.compo}</td>
                    <td>{mat.moyenMatiere}</td>
                    <td>{mat.coef}</td>
                    <td>{mat.moyenx}</td>  
                    <td>{mat.appreciationNote}</td>  
                 </tr>                 
                )): <></>} 
                <tr style={{fontSize: '12px', fontWeight: 'bold'}} >
                   <td>Totaux </td> 
                   <td colSpan={3}></td>
                   <td>{donnee.sommeCoefs}</td> 
                   <td >{donnee.sommeNotes}</td> 
                   <td></td>
                </tr> 
                <tr style={{fontSize: '12px', fontWeight: 'bold'}} >
                    <th colSpan ={''} >Moyenne : {donnee.moyen.valeur+" / "+donnee.moyen.damander}</th>
                    <td colSpan ={''}>Rang :
                    { donnee.rang.position >1 ?
                        <>  { " "+donnee.rang.position + ' ème'} </>:
                        <> 
                           { " "+donnee.rang.position + ' ère'}
                        </>
                    }
                    </td>
                    <td></td>
                    <td colSpan ={2}>Retards : 0</td>
                    <td colSpan ={2}>Absence : 0</td>
                </tr>
            </tbody>
        </table>
        <div className='row justify-content-between '>
            <div className="col-4">
            {donnee.semestre === "1 ère" ?
            <table className='table table-bordered col-md-4'>
                <tbody id="tabApreciation">
                    <tr style={{fontSize: '12px',}} >
                        <th>Satisfaisant doit continuer</th>
                        {(donnee.moyen.valeur >= 12 && donnee.moyen.valeur <= 20)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>   
                    <tr style={{fontSize: '12px'}} >
                        <th>Peut Mieux faire</th>
                            {(donnee.moyen.valeur >= 10 && donnee.moyen.valeur < 12)?
                                <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Insuffisant</th>
                            {(donnee.moyen.valeur >= 9 && donnee.moyen.valeur < 10)?
                                <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Risque de Redoubler</th>
                            {(donnee.moyen.valeur >= 7 && donnee.moyen.valeur < 10)?
                                <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Risque de l'exclusion</th>
                            {(donnee.moyen.valeur < 7)?
                                <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                            }
                    </tr>

                </tbody>
            </table>
            : <table className='table table-bordered col-md-6' >
                <tbody>
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne première semestre</th>
                        <td>{donnee.moyenSemestre1+" / 20"}</td>
                    </tr>   
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne deuxième semestre</th>
                        <td>{donnee.moyen.valeur+" / "+donnee.moyen.damander}</td>
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Moyenne annuelle</th>
                        <td>{donnee.moyenAnnuelle+" / 20"}</td>
                    </tr>
                </tbody>
            </table>
            }
            </div>
            <div className="col-5">
           
            <table className='table table-bordered col-md-4'>
                <tbody id="tabApreciation">
                    <tr style={{fontSize: '12px'}} >
                        <th>Félicitations</th>
                        {(donnee.moyen.valeur >= 14 && donnee.moyen.valeur <= 20)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>   
                    <tr style={{fontSize: '12px'}} >
                        <th>Encouragements</th>
                        {(donnee.moyen.valeur >= 12 && donnee.moyen.valeur < 14)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Tableau d'honneur</th>
                        {(donnee.rang.position <= 5)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Avertissement</th>
                        {(donnee.moyen.valeur >= 9 && donnee.moyen.valeur < 12)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>
                    <tr style={{fontSize: '12px'}} >
                        <th>Blame</th>
                        {(donnee.moyen.valeur < 9)?
                            <td style={{textAlign: "center"}}><FiCheck /></td>: <td></td>
                        }
                    </tr>
                </tbody>
            </table>
            
            </div>
        </div>
        <div className='signature '>
            <div className=''>
                <p style={{marginBottom: '2px'}} className=''>Observation du conseil professeurs</p>
                <textarea rows="4" cols="36">
                </textarea>
            </div>
            <div className=''>
                <p  className=''>Le Chef d'Etablissement</p>
            </div>
        </div>
        </div>
        )): <></>
        }
      </div>
    </div>
    </div>
 </>
 }
</Squelette>
  )
}
