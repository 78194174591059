import React from 'react';
import { useState, useEffect } from "react";
import './Recu.css'
import axios from "axios";
import APP_ENV from "../../config/.env";
import { BsFillPrinterFill } from "react-icons/bs";
import { NumberToLetter } from "../paiement/ToLettre";
import {FormatDateHeure} from '../helpers/FormatDate'
import { Link} from 'react-router-dom';

    export default function Recu({data, tarification, anneeScolaire}) {
        const [eleve, setEleve] = useState('');
        
        const header = {
            Accept: "application/json",
            //"Content-Type": "multipart/form-data",
            WithCredentials: true,
            Authorization: "Bearer " + localStorage.getItem("token"),
          };
    
const eleveSohw = async () => {
  await 
        axios(`${APP_ENV.baseUrl}/eleve-show/${data}`,
        {headers: header}
        ).then(res=>{
          
            setEleve(res.data.eleve)
          
        })
        .catch(er =>{

      
        })
}
useEffect(() => {
  eleveSohw();
    },[]);
    const Print = () =>{     
      let printContents = document.getElementById('printablediv').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      document.title = 'Recu';
      window.print();
     document.body.innerHTML = originalContents; 
    }

 
    return (
      <>
        <div className=" container row " id="printablediv">
          <div className="col-md-1"></div>
          <div className="col-md-10 grandCarde">
                <div class="Rectangle-294">
                      <span class="A000-1">
                           {data}
                    </span>
                </div>
                <div class="Rectangle-292">
                  <span class="REU">
                    RE<span class="text-style-1">ç</span>U
                  </span>
                </div>
                <br />
                <br /><br />
                <div className="row  montant">
                    <div className="">
                    Montant : <input value={tarification +" FCFA"} className="input" disabled></input>
                      </div>
                </div>
                <br />  
                <br />
                <div className="row  recude">
                    <div className="">
                    Reçu de  : <input value={eleve.prenom  + " " +  eleve.nom }  className="inputNom" disabled></input>
                      </div>
                </div>
                <br />
                <div className="row">
                   <span class="Classe-6em-A6em-FR">
                      Classe : {eleve.classeAR} Arabe / {eleve.classeFR} Français
                    </span>
                </div>
                <p className="ligne"></p>
                <br />
                <div className="row  lasomme">
                    <div className="">
                   La somme de  : <input value={NumberToLetter(tarification)+" FCFA"} className="inputSomme" disabled></input>
                      </div>
                </div>
                <br />
                <div className="row">
                   <span class="Classe-6em-A6em-FR">
                      Montant d'inscription et la paiement du première mois
                    </span>
                </div>
                <p className="ligne"></p>
                <br />
                <div className="row">
                   <span class="Classe-6em-A6em-FR">
                     Date :  {FormatDateHeure(new Date()) }
                    </span>
                </div>
                <p className="ligne"></p>
                <br />
                <div className="row mr-5" style={{float: "right"}}>
                 <span className="" >
                   Signanture 
                   <hr />
                  </span>
                  
              </div>
          </div>
             
        </div>

       
        <div className="Rectangle-180 pl-3 pr-3 mt-5 " style={{marginRight: '300px'}} >
          <Link onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
        </div>
    </>
 )
};
