
import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import DataTableEleveAyantPayeUnMoi  from './DataTableEleveAyantPayeUnMoi'
import { Link } from 'react-router-dom';
import './ListEleveAyantPaye.css'
import LoadingSpinner from '../helpers/LoadingSpinner'

export default function ListEleveAyantPaye() {
    const [dataEleve, setDataEleve] = useState([])
    const [isLoading, setIsLoading] = useState(false);

        const [listeEleve, setListeEleve] = useState({
        mois: 'octobre',
        paye: 'paye',
        no: 'no'
    });  
   
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
      const hundeChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
       
          setListeEleve({ ...listeEleve, [name]: value });
      }

     const listeEleveAyantPaye  =  async () => {
      setIsLoading(true)
      await 
      axios
      .get(`${APP_ENV.baseUrl}/payeunmois/${listeEleve.mois}`, {
        headers: header,
      })
                .then(res =>{
                 setDataEleve(res.data.elves)
                 setIsLoading(false)
                })
      
    }
      useEffect(() => {
        listeEleveAyantPaye();
       
      },[]);
      const hundleSubmit = (e) => {
        e.preventDefault();
  if (listeEleve.status === "paye") {
    axios
    .get(`${APP_ENV.baseUrl}/payeunmois/${listeEleve.mois}`, {
      headers: header,
    })
              .then(res =>{
               setDataEleve(res.data.elves)
                // setListeUtilisateur(dataUser)
                
              })       
          }else{
            axios
                .get(`${APP_ENV.baseUrl}/neayantpayeunmois/${listeEleve.mois}`, {
                headers: header,
                })
                        .then(res =>{
                        setDataEleve(res.data.eleves)
                           
                        }) 
                    }
        }
  return (
    <Squelette>
   {isLoading ? <LoadingSpinner/> : 
        <>
<div className="container mt-2 ">
<form className="eleveform formulaire" onSubmit={hundleSubmit}>
    <div className="row"> 
        <div className="col-md-2 mb-3">
           <div class="form-group ">
                {/* <option>{eleves.classeAR.labelle}</option> */}
                  <select
                    class="form-control"
                    id="sel1"
                    name="mois"
                     value={listeEleve.mois}
                     onChange={hundeChange}
                  >
                    <option value="octobre" chacked >Mois d'octobre</option> 
                    <option value="novembre">Mois de novembre</option> 
                    <option value="decembre">Mois de décembre</option> 
                    <option value="janvier">Mois de janvier</option> 
                    <option value="fevrier">Mois de février</option> 
                    <option value="mars">Mois de mars</option> 
                    <option value="avril">Mois d'avril</option> 
                    <option value="mai">Mois de mai</option> 
                    <option value="juin">Mois de juin</option> 
                    <option value="juillet">Mois de juillet</option> 

                  </select>
                </div>
        </div>
        <div className="col-md-4">
           <div className="form-check">
              <input id="normal"
                className="form-check-input"
                type="radio"
                name="status"
                value={listeEleve.paye}
                onChange={hundeChange} 
              />
              <label className="form-check-label">Élèves ayant payés un mois</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                value={listeEleve.no}
                onChange={hundeChange} 
              />
              <label className="form-check-label">Élèves n'ayant pas payés un mois</label>
            </div>
        </div>
        
          <div className="col-md-6" >

          <div className='row d-flex justify-content-end' >
              <div className="col-md-8 content-link-eleve  d-flex justify-content-end">
            <button type="submit"  className='btn-classe mb-2 mr-3'>
                    Rechercher
                  </button>
              <Link to="/paiement-create" className='btn-classe mb-2 mr-3'>Nouvelle-paiement</Link>

          
          </div>
        </div>
             
           
          </div>

    </div>

              </form>
              <br />
              <br />
        <div className="col-md-12 mb-5" id='printablediv'>
          <fieldset className = 'fieldset'>
         <h3 className='tilte-fieldset user'>Liste des élèves ayant payés ou pas un mois données</h3>
             <DataTableEleveAyantPayeUnMoi data ={dataEleve ?dataEleve:[]}/>      
       </fieldset>
    </div>
    </div>
    </>}
    </Squelette>
  )

}
