import React, { useEffect } from 'react'
import { useState } from 'react';
import './Squrelette.css'
import {Link,useNavigate} from 'react-router-dom'
import { HiChevronDown } from "react-icons/hi";
import { AiFillSetting } from "react-icons/ai";
import {FaGraduationCap, FaBook, FaUser, FaHome,FaBars} from 'react-icons/fa';
import {  IoCalendarNumberSharp} from "react-icons/io5";
import { GrMoney } from "react-icons/gr";
import {AccountService} from '../services/AccountService'
import Accordion from './Accordion';
import APP_ENV from "../../config/.env";


export default function Squelette({children}) {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate();
  const Logout = () =>{
    AccountService.logout()
    navigate('/')
  }
     

  useEffect(() => {
    const hundleResize = ()=>{
      if(window.innerWidth < 730 && open){
        setOpen(false)
      }
      if(window.innerWidth > 730 && open){
        setOpen(true)
      }
    }
    window.addEventListener('resize', hundleResize)
  
  }, [])

  // console.log('open',open);
  let prenom = localStorage.getItem('prenom')    
  let nom = localStorage.getItem('nom')    
  let profil = localStorage.getItem('profil')  
  let idUser = localStorage.getItem('idUser')  
  let image = localStorage.getItem('avatar') 
 
  return (
    <div className='container-fluid app'>
    
          {/* ###### Navebar ####### */}
        <div className="navebar">
 
              <div className="content-navebar-title">
                  <FaBars 
                  className="icon-humburger" 
                  onClick = {()=>setOpen(!open)}
                 
                  />
                  <h4 className="navebar-title">Ecole <span>Abou Oubayda</span> </h4>
              </div>
              
                  {/* #### Barre de Recherche ##### */}
                    <div className='form-group recherche-content'>
                        <input type="text" className='form-control recherche' />
                    </div>
             
              <div className=" section-profil">
                  {/* #### LES LANGUES ##### */}
                  <div className='content-langue'>
                      <select className="form-select langue"
                              aria-label="Default select example"
                              style ={{
                                outline : 'none',
                                width: '70px',
                                border: 'none',
                                }}>
                            <option value ="fr">FR</option>
                            <option value ="ar">AR</option>
                      </select>
                  </div>
   
                      {/* #### Profil ##### */}
                    <div className="dropdown profil">
                          <div className=" dropdown-toggle avatar" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                          {(image === "null" || image === '') ?
                              // <img className="profilUser" src= {imageProfil} />
                              <img className="profilNav" 
                                    src={process.env.PUBLIC_URL + '/images/pp.png'} width="90" />:
                              <img className="profilNav" 
                                    src={`${APP_ENV.urlAvatar}/avatar/${image}`} width="90" /> 
                              }
                          </div>
                          <ul className="dropdown-menu">
                            <li>
                                <Link className="dropdown-item" to = {`/profilUser/${idUser}`}
                                style ={{margintop:'0'}}>
                                  Mon profil
                                </Link>
                            </li>
                            <li>
                              <button onClick = {Logout} className="dropdown-item">
                                  Déconnexion
                              </button>
                            </li>
                        
                          </ul>
                      </div>
                      <div className='profil-nom'>
                          <p className='nomProfil'>{prenom.substr(0,1).toUpperCase() +". "}{nom}</p>
                          <p className='profil-profil'>{profil}</p>
                      </div>
                  </div>
          
        </div>
     
      {/* ###### Corp de la page ####### */}
      <div className="content-sidebar-main">
      {/* ###### Sidebar ####### */}
   
        <div className= {`sidebar scroller ${open ? 'sidebar-open': 'sidebar-closed'}`}
        // {`${open ?  'sidebar-none ' :'sidebar-block sidebar-modifie '} sidebar scroller`}
                >
              <div className="accueil">
                <FaHome className='icon icon1'/>
                  {(profil === 'Parent') ?
                <Link to = '/Parent' className='accueil-link'>Accueil</Link>:
                <Link to = '/accueil' className='accueil-link'>Accueil</Link>
                    }
              </div>
              
              <div className="" id="accordionPanelsStayOpenExample" 
                    style={{marginBottom : '30px'}}>
                    {(profil === 'Admin' || profil === 'Comptable') &&
                      <> 
                    {/* Gestion des Eleves  */}
                  <Accordion 
                      icon1 = {<FaGraduationCap className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'Gestion des Eleves'}
                      heading = {'panelsStayOpen-headingOne'}
                      collapse = {'panelsStayOpen-collapseOne'}
                      >
                          <p><Link to = '/inscription' className='link'>Inscrition</Link></p>
                          <p><Link to = '/reinscription' className='link'>Réinscription</Link></p>
                          <p><Link to = '/listEeleve' className='link'>Liste des élèves </Link></p>
                  </Accordion>                    
                    {/* Gestion Budgétaire */}
                  <Accordion 
                      icon1 = {<GrMoney className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'Gestion Budgétaire'}
                      heading = {'panelsStayOpen-headingTwo'}
                      collapse = {'panelsStayOpen-collapseTwo'}
                      collapsed = {'collapsed'}
                      >
                          <p><Link to = '/accueilBudgetere' className='link'>Budget</Link></p>
                          <p><Link to = '/paiement-create' className='link'>Paiement mensuel </Link></p>
                          <p><Link to = '/listEleveAyantPaye' className='link'>Elèves paye un mois</Link></p>
                          <p><Link to = '/listEleveAvecPaiement' className='link'>Elèves avec paiement</Link></p>
                  </Accordion>
                      </>
                  }
                    {/* Gestion des notes */}
                    {(profil === 'Admin' || profil === 'Enseignent') &&
                  <Accordion 
                      icon1 = {<FaBook className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'Gestion pédagogique'}
                      heading = {'panelsStayOpen-headingThree'}
                      collapse = {'panelsStayOpen-collapseThree'}
                      collapsed = {'collapsed'}
                      >
                         {profil === 'Admin' &&
                              <>

                          <p><Link to = '/classe-liste' className='link'>Classes </Link></p>
                          <p><Link to = '/listMatiere' className='link'>Matières</Link></p>
                          <p><Link to = '/affectation' className='link'>Affectation matière</Link></p>
                          <p><Link to = '/tableauHoneure' className='link'>Tableau d'Honeure</Link></p>
                              </>}
                          <p><Link to = '/reportage-note' className='link'>Reporter note</Link></p>
                          <p><Link to = '/reclamation-note' className='link'>Réclamation note</Link></p>
                          {profil === 'Enseignent' &&
                            <>
                          <p><Link to="/listEeleve" className='link'>Liste des élèves</Link></p>
                          <p><Link to="/classe-liste" className='link'>Classes</Link></p>
                            </>}
                  </Accordion>
                    }
                  {/* Emplois du temps */}
                  {profil === 'Admin' &&
                      <>
                  <Accordion 
                      icon1 = {<IoCalendarNumberSharp className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'Emplois du temps'}
                      heading = {'panelsStayOpen-headingFour'}
                      collapse = {'panelsStayOpen-collapseFour'}
                      collapsed = {'collapsed'}
                      >
                          <p><Link to = '/creerEmploieTemp' className='link'>Création </Link></p>
                          <p><Link to = '/classe' className='link'>Liste des classes</Link></p>
                  </Accordion>
                  </>
                  }
                  {/* Gestion des Comptes */}
                  {profil === 'Admin' &&
                    <>
                  <Accordion 
                      icon1 = {<FaUser className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'Gestion des Comptes'}
                      heading = {'panelsStayOpen-headingFive'}
                      collapse = {'panelsStayOpen-collapseFive'}
                      collapsed = {'collapsed'}
                      >
                          <p><Link to = '/utilisateur-ajout' className='link'>Ajout utilisateur </Link></p>
                          <p><Link to = '/utilisateur-liste' className='link'>Liste utilisateur</Link></p>
                          <p><Link to = '/listParent' className='link'>Liste parent </Link></p>
                  </Accordion>
                  {/* la Configuration */}
                  <Accordion 
                      icon1 = {<AiFillSetting className='icon icon2'/>}
                      icon2 = {<HiChevronDown className='icon icon3'/>}
                      title = {'la Configuration'}
                      heading = {'panelsStayOpen-headingSix'}
                      collapse = {'panelsStayOpen-collapseSix'}
                      collapsed = {'collapsed'}
                      >
                          <p>
                              <Accordion 
                                icon2 = {<HiChevronDown className='icon icon3'/>}
                                title = {'Années Scolaire'}
                                heading = {'panelsStayOpen-headingSeven'}
                                collapse = {'panelsStayOpen-collapseSeven'}
                                collapsed = {'collapsed'}
                               
                                sousListe = {'sousListe'}
                                >
                                <p><Link to = '/annee-scolaire-create' className='link'>Création</Link></p>
                                <p><Link to = '/annee-scolaire-liste' className='link'>Liste Années</Link></p>
                              </Accordion>
                          </p>
                         
                          <p><Link to = '/mail' className='link'>Envoie de message</Link></p>
                          <p><Link to = '/affectationNiveau' className='link'>Affectation Niveau</Link></p>
                          <p><Link to = '/listSurveillent' className='link'>Surveillent</Link></p>
                          <p><Link to = '/niveau-liste' className='link'>Niveaux</Link></p>
                          <p><Link to = '/tarif-liste' className='link'>Tarifications</Link></p>
                          <p><Link to = '/classeDerniereAnnee' className='link'>Classes année passée</Link></p>

                  </Accordion>
                  </>}


            </div>
          </div>
                {/* </PerfectScrollbar>    */}
         
      {/* ###### Main ####### */}
       
        <div className= {`main ${open ? 'main-open' :'main-closed'}` }
         >
              <div className="">
                  {children}
              </div>
            
        </div>
    </div>
  </div>
  
   
  )
}
