import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import APP_ENV from "../../config/.env";
import DataTableTarification from './DataTableTarification';
import { async } from 'q';

function Tarification_liste() {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    const [listeTarifs, setListeTarifs] = useState([]);

    const tarifliste = async () => {
        await
        axios(`${APP_ENV.baseUrl}/tarif-liste`,{headers: header}
        ).then(res=>{
            if(res.status === 200){
                setListeTarifs(res.data.tarifs)
            }
        })
        .catch(error =>{
            console.log(error);
            // let ereurs = error.response.data.error
        })
    } 
    useEffect(()=>{
        tarifliste();
    }, [])
    
    return (
    
    <Squelette>
        <fieldset className = 'fieldset mt-5'>
            <h3 className='tilte-fieldset user'> Liste des tarifications </h3>
            <DataTableTarification data={listeTarifs} />      
        </fieldset>
    </Squelette>
  )
}

export default Tarification_liste;
