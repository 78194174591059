import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ModalConfirm({ show, handleClose , children  ,handleDelete }) {
   
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center">
         {children}
        </Modal.Body>
        <Modal.Footer className="mx-auto">
          <Button onClick={handleDelete} >Confirmer</Button>
          <Button onClick={handleClose}>Annuler</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
