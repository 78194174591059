import React from "react";
import { useState, useEffect } from "react";
import { useParams,useNavigate } from 'react-router-dom';
import axios from "axios";
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import './ModificationEleve.css';
import LoadingSpinner from '../helpers/LoadingSpinner'

function ModificationEleve() {
  let navigate = useNavigate();
  let matricule = useParams(); 
  const [error, setError] = useState("");
  const [ setSuccess] = useState("");
  const [classeAR, setClasseAR] = useState([]);
  const [classeFR, setClasseFR] = useState([]);
  const [identifiant, setIdentifiant] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const header = {
    Accept: "application/json",
    //"Content-Type": "multipart/form-data",
    WithCredentials: true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  //##### Changement des inputs #####
  const hundeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIdentifiant({ ...identifiant, [name]: value });

  };

  const getshowEleves =  async () => {
    await 
    axios.get(`${APP_ENV.baseUrl}/eleve-show/${matricule.id}`, {
      headers: header,
    })
    .then((res) => {
      // setSuccess(res.data);
      setIdentifiant(res.data.eleve)
      setIsLoading(false)
    })
    .catch((er) => {
      let ereur = er.response.data.errors;
      setError(ereur);
    });
  }
  const getclasseAR =  async () => {
    await 
      axios
        .get(`${APP_ENV.baseUrl}/classe-ar`, {
          headers: header,
        })
        .then((res) => {
          // setSuccess(res.data);
          setClasseAR(res.data.classeAR);
        })
        .catch((er) => {
          let ereur = er.response.data.errors;
          setError(ereur);
        });
      }
const getclasseFR =  async () => {
  await 
      axios
        .get(`${APP_ENV.baseUrl}/classe-fr`, {
          headers: header,
        })
        .then((res) => {
          // setSuccess(res.data);
          setClasseFR(res.data.classeFR);
        })
        .catch((er) => {
          let ereur = er.response.data.errors;
          setError(ereur);
        });
      }
  useEffect(() => {
    setIsLoading(true)
    getshowEleves();
   
        getclasseAR();
        getclasseFR();
  },[]);

//   const filUploade = (id) => {
//     const formData = new FormData();
//     formData.append("extrait"); // append: (ajout du file)
//     axios.post(`${APP_ENV.baseUrl}/uplodeFile/${id}`,formData,{
//     // axios.post(base_url +`uplodeFile/${id}`,formData,{
//         headers: header,
//     })
// };
  // je dois netoyer le DOM (alert error) apres Render avec useEffect()

  //##### Soumission du Formulaire #####
  const hundleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${APP_ENV.baseUrl}/updateEleve/${matricule.id}`, identifiant, {
        headers: header,
      })
      .then((res) => {
        // filUploade(res.data.id)
        navigate('/listEeleve')
      })
      .catch((er) => {
      });
  };

  return (
    <Squelette>
      {isLoading ? <LoadingSpinner/> : 
            <>
      <div className="eleveconteiner">
     
        <form className="eleveform formulaire" onSubmit={hundleSubmit}>
          <fieldset className="fieldset-ModificationEleve">
          <h4 className='tilte-fieldset user'>Modification élève</h4>
            <div className="row">
              <p className="text">
              
                Informations personnelles de l’élève
              </p>
              <hr className="bar"></hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Prénom"
                    name="prenom"
                    value={identifiant.prenom}
                    onChange={hundeChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom"
                    name="nom"
                    value={identifiant.nom}
                    onChange={hundeChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
              <div className="inputboite">
                  <input
                    type="text"
                    placeholder="lieu de naissance"
                    name="lieuNaiss"
                    value={identifiant.lieuNaiss}
                    onChange={hundeChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
              <div className="inputboite">
                  <input
                    type="text"
                    placeholder="date de naissance"
                    name="dateNaiss"
                    value={identifiant.dateNaiss}
                    onChange={hundeChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="sexe"
                    value={identifiant.sexe}
                    onChange={hundeChange}
                  >
                     {(identifiant.sexe === "F")?
                     <>
                     <option  value={identifiant.sexe}>Femme</option>
                     </>:
                     <option  value={identifiant.sexe}>Homme</option>
                    }
                    <option value="H">Homme</option>
                    <option value="F">Femme</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="classeAR_id"
                    // value={identifiant.classeAR_id}
                    onChange={hundeChange}
                  >
                    {/* <option >Classe Arabe</option> */}
                    <option value={identifiant.classeAR_id}>{identifiant.classeAR}</option>
 
                     {classeAR.map((classear, index = 1) => (
                      <option value={classear.id}>{classear.libelle}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div class="form-group ">
                  <select
                    class="form-control"
                    id="sel1"
                    name="classeFR_id"
                    // value={identifiant.classeFR_id}
                    onChange={hundeChange}
                  >
                    <option value={identifiant.classeFR_id}>{identifiant.classeFR}</option>
                    {classeFR.map((classefr) => (
                      <option value={classefr.id}>{classefr.libelle}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="row">
              <p className="text"> Informations tuteurs</p>
              <hr className="bar"></hr>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom Complet du père"
                    name="nomCompletPere"
                    value={identifiant.nomCompletPere}
                    onChange={hundeChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet de la mère"
                    name="nomCompletMere"
                    value={identifiant.nomCompletMere}
                    onChange={hundeChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Nom complet tuteur"
                    name="nomCompletTuteur"
                    value={identifiant.nomCompletTuteur}
                    onChange={hundeChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inputboite">
                  <input
                    type="text"
                    placeholder="Téléphone tuteur"
                    name="telephoneTuteur"
                    value={identifiant.telephoneTuteur}
                    onChange={hundeChange}
                  />
                </div>
              </div>
            </div>
            <p className="text"> Prise en charge</p>
            <hr className="barre"></hr>
            <div className="form-check">
            {(identifiant.typeEleve === "Normale")?
              <>
              <input id="normal"
                className="form-check-input"
                type="radio" checked
                name="typeEleve"
                value={identifiant.typeEleve}
                onChange={hundeChange} 
              />
              </>:
              <input id="normal"
              className="form-check-input"
              type="radio"
              name="typeEleve"
              value={identifiant.typeEleve}
              onChange={hundeChange} 
            />
            }
              
              <label className="form-check-label">Non prise en charge</label>
            </div>
            <div className="form-check">

            {(identifiant.typeEleve === "Prise en charge")?
          <>
          <input
                className="form-check-input"
                type="radio" checked
                name="typeEleve"
                value={identifiant.typeEleve}
                onChange={hundeChange} 
              />
          </>:
          <input
          className="form-check-input"
          type="radio"
          name="typeEleve"
          value={identifiant.typeEleve}
          onChange={hundeChange} 
        />
          }
              
              <label className="form-check-label">Prise en charge total</label>
            </div>
            <div className="form-check">
            {(identifiant.typeEleve === "Avec inscription")?
            <>
            <input
                className="form-check-input"
                type="radio" id="no" checked
                name="typeEleve"
                value={identifiant.typeEleve}
                onChange={hundeChange}
              />
            </>:
            <input
            className="form-check-input"
            type="radio" id="no"
            name="typeEleve"
            value={identifiant.typeEleve}
            onChange={hundeChange}
          />
            }
              
              <label className="form-check-label">
                Prise en charge mais avec inscription
              </label>
            </div>
            <div className="row " id="normalrow" style={{display: "none"}}>
              <div className="col-md-6">
                      <div className="inputboite" >
                          <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                            type="text"
                             value="35.500 FCFA" disabled
                              />
                    </div>
              </div>
                  <div className="col-md-6">
                          <div className="inputboite">
                              <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                                type="text"
                                 value="12.000 FCFA" disabled
                                  />
                        </div>
                  </div>
            </div>

            <div className="row " id="inscrie" style={{display: "none"}}>
              <div className="col-md-12">
                      <div className="inputboite" >
                          <input style={{ backgroundColor: "rgba(196, 196, 196, 0.64)"}}
                            type="text"
                             value="35.500 FCFA" disabled
                          />
                    </div>
              </div>
                 
            </div>
            {error !== "" ? (
              <h2 className="alert alert-danger text-center">{error}</h2>
            ) : (
              ""
            )}

            <div>
              <div className="col-md-12 conteneur-btn btnAjoutUser">
                <button type="submit" className="btn1-connexion enregistrer">
                  Enregistrer
                </button>
                <button className="btn1-connexion annuler">Annuler</button>
              </div>
              {/* <button className='btn-connexion'>Enregistre</button>
                <button type = 'submit' className='btn-annuler'>Enregistre</button> */}
            </div>
            
          </fieldset>
        </form>
      </div>
      </>}
    </Squelette>
    
  );
}

export default ModificationEleve;
