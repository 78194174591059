
import React, { useEffect,useState } from 'react'
import axios from 'axios'
import Squelette from "../squelette/Squelette";
import APP_ENV from "../../config/.env";
import './ListEleveAvecPaiement.css'
import DataTableEleveAvecPaiement from './DataTableEleveAvecPaiement'
import Descripteur from '../accueil/Descripteur';

import { Link} from 'react-router-dom';
import LoadingSpinner from '../helpers/LoadingSpinner'



export default function ListEleveAvecPaiement() {
    const [ setDataEleve] = useState([])
        const [listePaiement, setListePaiement] = useState([]);  
        const [effectifElevePaiement, effEctifElevePaiement] = useState([]);  
        const [isLoading, setIsLoading] = useState(false);
      const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }


      const paiemeneList = async () => {
        setIsLoading(true)
        await 
        axios.get(`${APP_ENV.baseUrl}/paiement-liste`, {
          headers: header,
        })
        .then(res =>{
          setListePaiement(res.data.paiements)
          // setDataEleve(res.data.paiements.eleve)
          setIsLoading(false)
          
        })
      }
      const getEffectifElevePaiement = async () => {
        await
         
        axios.get(`${APP_ENV.baseUrl}/effectifElevePaiement`, {
          headers: header,
        })
        .then(res =>{
          effEctifElevePaiement(res.data)
        })
        
      }
      useEffect(() => {
        paiemeneList();
        getEffectifElevePaiement();
       
      },[]);
  return (
    <Squelette>
       {isLoading ? <LoadingSpinner/> : 
        <>
   <div className="container-fluid content-tab ">
    <div className="row effectif" >
    <div className='col-md-1'></div>
              <div className='col-md-3 cadreEffectiv shadow  ' style={{border: '2px solid  rgba(226, 120, 120, 0.64)'}}>
                        <Descripteur texForm ={effectifElevePaiement.effectifTotal} text = 'Effectifs'  color = '#000'
                        colorBg =' rgba(226, 120, 120, 0.64)'
                        />
                    </div>
                    <div className='col-md-3 shadow cadreEffectiv ' style={{border: '2px solid  rgba(237, 251, 78, 0.69)'}}>
                        <Descripteur className='ef' texForm ={effectifElevePaiement.effectifPaye} text = 'Nombre déjà payé'  color = '#000'
                        colorBg =' rgba(237, 251, 78, 0.69)'
                        />
                    </div>
                    <div className='col-md-3  shadow cadreEffectiv ' style={{border: '2px solid   rgba(56, 194, 120, 0.53)'}}>
                        <Descripteur texForm ={effectifElevePaiement.effectifNoPaye} text = 'Nombre restent'  color = '#000'
                        colorBg ='  rgba(56, 194, 120, 0.53)'
                        />
                    </div>
                </div>
                <div className='row d-flex justify-content-end mt-2' >
              <div className="col-md-12 content-link-eleve  d-flex justify-content-end">
              <Link to="/paiement-create" className='btn-classe mb-2 mr-3'>Nouvelle paiement</Link>
          </div>
        </div>
              
            
       <br />
       
       <div className="col-md-12 mb-5" id='printablediv'>
          <fieldset className = 'fieldset'>
         
            <h3 className='tilte-fieldset user'>Listes de paiements des élèves</h3>
                <DataTableEleveAvecPaiement data ={listePaiement}/>      
          </fieldset>
      </div>
      </div>
      </>}
    </Squelette>
  )

}
