export function FormatDate(date) {
    let d = new Date(date)
      let m = d.getMonth()+1;
      let dey = d.getDate();
      let a = d.getFullYear();
       if (dey<= 9) {
        dey= "0"+dey 
       }
       if (m<= 9) {
        m= "0"+m 
       }
      return dey+"/"+m+"/"+a;
}
export function FormatDateHeure(date) {
    let d = new Date(date)
    let m = d.getMonth()+1;
    let dey = d.getDate();
    let a = d.getFullYear();
    let h =  d.getHours();
    let mn =   d.getMinutes();

       if (dey<= 9) {
        dey= "0"+dey 
       }
       if (m<= 9) {
        m= "0"+m 
       }
       return dey+"/"+m+"/"+a +" à "+h +"h "+mn+" mn";
}

