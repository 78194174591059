import React from 'react'

 function Descripteur({texForm, text,detail, colorBg,color, width, height,br}) {
 
  return (
    <div className='contenu-descrpteur'>
      
        <div className="descripteur-form" style={{backgroundColor : colorBg , color: color, width: width, height: height, border: br}}>{texForm}</div>
        <div className="text-title" style = {detail ? {marginTop: '8px'}: {marginTop: '15px'}}>
            <h5 className="descripteur-text">{text}</h5>
            <p className='descripteur-detail ' >{detail}</p>
        </div>
    </div>
  )
}
export default Descripteur;
