
import { useNavigate } from 'react-router-dom';
import React, { useEffect,useState } from 'react'
import axios from 'axios';
import "./CreationEmploieTemp.css";
import Squelette from '../squelette/Squelette';
import APP_ENV from "../../config/.env";
import LoadingSpinner from '../helpers/LoadingSpinner'

function CreationEmploieTemp() {
    let navigate = useNavigate( );
  const [isLoading, setIsLoading] = useState(false);
  const [identifiant, setIdentifiant] = useState(
   {
    jour : "",
    heureDebut:"",
    heureFin:"",
    matiere_id: "",
    classe_id: "",
    enseignent_id: "",
    
   })
 
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const [errorJour, setErrorJour] = useState('')
  const [errorHeurFin, setErrorHeurFin] = useState('')
  const [errorHeurDebut, setErrorHeurDebut] = useState('')
  const [errorMatiere, setErrorMatiere] = useState('')
  const [errorProf, setErrorProf] = useState('')
  const [errorClasse, setErrorClasse] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }
      const [listMatiere, setListMatiere] = useState([])
      const [listEnseignent, setListEnseignent] = useState([])
      const [listClasse, setListClasse] = useState([])

      const getList = async () =>{

        setIsLoading(true)
        await
        axios
        .get(`${APP_ENV.baseUrl}/listMatiere`, {
          headers: header,
        })
        .then(res =>{                
          setListMatiere(res.data.donnees)
        /// setIsLoading(false)
        })  
      }
      const getListenseignent = async () =>{ 
        await
        axios
        .get(`${APP_ENV.baseUrl}/enseignent-list`, {
          headers: header,
        })
        .then(res =>{                
          setListEnseignent(res.data.enseignents)
        /// setIsLoading(false)
        })  
        axios
        .get(`${APP_ENV.baseUrl}/classes-liste`,

        {headers: header}
        ).then(res=>{
            if(res.status === 200){
              setListClasse(res.data.classes)
          
            }
            setIsLoading(false)
        })
        .catch(error =>{
            // let ereurs = error.response.data.error
        })
      }
      useEffect(() => {
        getList();
        getListenseignent();

      },[]);
     
      // je dois netoyer le DOM (alert error) apres Render avec useEffect()

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
        if (identifiant.jour === '') {
          setErrorJour("Veillez saisir le Jour")
        }else{
          setErrorJour('')
        }
        if (identifiant.heureFin === '') {
          setErrorHeurDebut("Veillez saisir heur de Debut")
        }else{
          setErrorHeurDebut('')
        }
        if (identifiant.heureFin === '') {
          setErrorHeurFin("Veillez saisir heur de Fin")
        }else{
        setErrorMatiere('')
        }
        if (identifiant.matiere_id === '') {
          setErrorMatiere("Veillez saisir Le matiere")
        }else{
        setErrorMatiere('')
        }
        if (identifiant.classe_id === '') {
          setErrorClasse("Veillez saisir La classe")
        }else{
        setErrorClasse('')
        }
        if (identifiant.enseignent_id === '') {
          setErrorProf("Selectionnez l'enseignent")
        }else{
        setErrorProf('')
        }
        // if (identifiant.heureDebut > identifiant.heureFin ) {
        //   setError("OPP! heure de debut ne doit pas etre supperieur")
        //   }else{
            axios.post(`${APP_ENV.baseUrl}/creationEmploieTemp`,identifiant, {
              headers: header
              })
            .then(res=>{
              window.location.reload(false)
              
            })
            .catch(er =>{
              let ereur = er.response.data.errors
              setError(ereur) 
              setSuccess('')
            }) 
      }
  return (
    <Squelette>
       {isLoading ? <LoadingSpinner/> : 
        <>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
        <fieldset  className = 'fieldset'>
          <h4 className='tilte-fieldset user'>Ajout dans l'emploie du temp</h4>
                {/* <h3 className=' tilte-fieldset mt-1 '>Création matière</h3>  */}
            <div className="row justify-content-center">
                    {(error )  ? <>
                <div class="alert alert-danger col-11" role="alert">
                            <p className=' text-danger text-center'>{error}</p>
              </div>
              </> :
                                ''
                           }  
              {(success )  ? 
              <>
              <div class="alert alert-success col-11" role="alert">
                     
                                <p className=' text-black text-center'>{success}</p> 
              </div></>:
                        ''
                        } 
                 
                <div className="col-md-12 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="form-control " id="sel1" name="jour"  onChange = {hundeChange} >
                        <option value="">Choisire le joure</option>
                            <option value="lundi">LUNDI</option>
                            <option value="mardi" >MARDI</option>
                            <option value="mercredi" >MERCREDI</option>
                            <option value="jeudi" >JEUDI</option>
                            <option value="vendredi" >VENDREDI</option>
                            <option value="samedi" >SAMEDI</option>
                        
                        </select>
                        {(errorJour)  ?
                            <span className=' text-danger'>{errorJour}</span>:
                           ''
                            } 
                    </div>
                </div>
                </div>
                <div className="row justify-content-center mt-4">
                <div className="col-md-6">
                    <div className="inputEmploieTemp">
                        <input type="text" placeholder="Huere debut " name='heureDebut' onChange = {hundeChange}/>
                        {(errorHeurDebut)  ?
                            <span className=' text-danger'>{errorHeurDebut}</span>:
                           ''
                            }  
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputEmploieTemp">
                        <input type="text" placeholder="Heure fin" name='heureFin' onChange = {hundeChange}/>
                        {(errorHeurFin)  ?
                            <span className=' text-danger'>{errorHeurFin}</span>:
                           ''
                            } 
                    </div>
                </div>
            </div> 

            <div className="row mt-4">
               
                <div className="col-md-6 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="selectpicker" data-live-search="true" id="sel1" name="matiere_id"  onChange = {hundeChange} >
                        <option>Le matiere</option>
                        {listMatiere.map((post,index) => (
                            <option data-tokens="mustard" value={post.id}>{post.libelle}</option>
                           
                          ))}
                        </select>
                        {(errorMatiere)  ?
                            <span className=' text-danger'>{errorMatiere}</span>:
                           ''
                            }  
                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="selectpicker" id="sel1" data-live-search="true" name="enseignent_id"  onChange = {hundeChange} >
                        <option >Proffeseur</option>
                        {listEnseignent.map((post,index) => (
                            <option data-tokens="mustard" value={post.enseignent_id}>{post.prenom } {post.nom}</option>
                           
                          ))}
                        
                        </select>
                        {(errorProf)  ?
                            <span className=' text-danger'>{errorProf}</span>:
                           ''
                            } 
                    </div>
                </div>
                </div>
                <div className="row mt-4">
                <div className="col-md-12 ">
                    <div className="selectEmploieTemp">
                  
                    {/* <HiChevronDown className='icon-chevron mt-3'/> */}

                        <select class="selectpicker" id="sel1" name="classe_id" data-live-search="true" onChange = {hundeChange} >
                        <option >La classe</option>
                        {listClasse.map((post,index) => (
                          <>
                            {(post.niveau !== 'Primaire')&&
                              <option data-tokens="mustard" value={post.id}>{post.libelle} ({post.type})</option>
                            }</>
                          ))}
                        </select>
                        {(errorClasse)  ?
                            <spna className=' text-danger'>{errorClasse}</spna>:
                           ''
                            }  
                    </div>
                </div>
                </div>
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </>}
    </Squelette>
  )
}
 
export default CreationEmploieTemp;
