import React from 'react'
import './BulletinNoteP.css'
import axios from 'axios';
import { Link} from 'react-router-dom';
import APP_ENV from "../../config/.env";
import { useState,useEffect} from 'react';
import { BsFillPrinterFill } from "react-icons/bs";

export default function BulletinNoteP({donnees}) {
    const header = {
        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    
    const [listeClasses, setListeClasses] = useState([]);
    const [anneeScolaire, setAnneeScolaire] = useState([]);
    const [matiere, setMatiere] = useState([]);
    const [classe, setClasse] = useState([]);
    const [rang, setRang] = useState([]);
    const [moyenne, setMoyenne] = useState([]);
    const [eleves, setEleves] = useState([]);
   
    // Recuperation liste des classes
    const getBPA = async () => {
      await axios.post(`${APP_ENV.baseUrl}/bulletin-primaire-fr`,donnees, 
        {headers: header}
        ).then(res=>{
            setListeClasses(res.data)
            setAnneeScolaire(res.data.anneeScolaire.code)
            setEleves(res.data.eleve)
            setMatiere(res.data.matieres)
            setClasse(res.data.classe)
            setRang(res.data.rang)
            setMoyenne(res.data.moyen )    
        })
        .catch(error =>{
            
        })
    }
    useEffect(()=>{
        //setIsLoading(true)
        getBPA();
       
    },[])
    let profil = localStorage.getItem('profil')  

    const Print = () =>{     
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        document.title = '     ';
        window.print();
       document.body.innerHTML = originalContents; 
      }

  return (
    <div className ='container'>
    <div id="printablediv">

        <div className="herder-bulletin">
            <div className="col-md-5">
                <small>Abou Oubayda As Sakina</small><br/>
                <small>IEF Dakar</small><br/>
                <small>École Franco-Arabe</small>
            </div>
            <div className="col-md-5 ">
                <small className="d-flex justify-content-end">Année Scolaire:  {anneeScolaire} </small>
                <small className="d-flex justify-content-end"> {listeClasses.semestre} semestre</small>
            </div>
        </div>
        
        <div className="row borederTitle ms-0" >
            <h4>Bulletin de Note </h4>
        </div>
        <div className="herder-bulletin">
            <div className="col-md-4">
                <small>Prénom: {eleves.prenom}</small><br/>
                {/* <small>Né(e): {eleves.dateNaiss}</small><br/> */}
                <small>Matricule: {eleves.matricule}</small>
            </div>
            <div className="col-md-3" style={{textAlign: "center"}}>
                <small> à {eleves.lieuNaiss}</small><br/>
                <small>Nombre d'élèves: {rang.effectif} élèves</small>
            </div>
            <div className="col-md-4 justify-content-end">
                <small className="d-flex justify-content-end">Nom: {eleves.nom}</small>
                <small className="d-flex justify-content-end">Classe: {classe.code}</small>
                <small className="d-flex justify-content-end">Classe Redoublée: 0</small>
            </div>
        </div>
        <table className='table table-bordered tableNote'>
            <thead>
                <tr className='entete-tableau'>
                    <th style={{fontSize: '14px'}}>Matières</th>
                    <th style={{width: '100px', fontSize: '14px'}}>Notes</th>
                    <th style={{fontSize: '14px'}}>Appréciation</th>
                </tr>
            </thead>
            <tbody>
            {matiere ? matiere.map((matier,index) => (
                <tr>
                    <td>
                        <h6 style={{fontWeight: 'bold'}}>{matier.libelle}</h6>                     
                        { (matier.libelle.toUpperCase() === "ARTS PLASTIQUES" || matier.libelle.toUpperCase() === "CONDUITE") ?
                            <> </> : 
                            <>
                                <h6 style={{fontSize: '14px', paddindTop: '-14px'}}>Ressource</h6>
                                <h6 style={{fontSize: '14px', paddindTop: '-14px'}} className=''>compétence</h6>
                            </>
                        }
                    </td>
                    <td>
                    { (matier.libelle.toUpperCase() === "ARTS PLASTIQUES" || matier.libelle.toUpperCase() === "CONDUITE") ?
                            <h6 className =' mt-0'>{matier.ressource}/{matier.demande.ressource} </h6>
                        :
                        <>
                            <h6 style={{paddingTop: '27px'}}>{matier.ressource}/{matier.demande.ressource} </h6>
                            <h6 style={{paddingTop: '0px'}}>{matier.competence}/{matier.demande.competence}</h6>
                        </>
                    }
                    </td>
                       
                    {(index === 1 )&&
                    <td rowSpan={matiere.length} style={{borderTop: '1px solid white',borderRight: '1px solid #eee', textAlign:'center'}}>
                        <h6 className="mt-5" style={{textAlign:'center'} }>DÉCISION DU CONSEIL PÉDAGOGIQUE</h6>
                        <h6 style={{textAlign:'center'} }>passé en classe supérieur</h6>
                        <input type="checkbox"  className='inputBulletin'/>
                        <h6>Redoublé de classe </h6>
                        <input type="checkbox" className='inputBulletin' />
                    </td>
                    }
                   
                </tr>
               )):<></>}
               
                <tr style={{borderTop: '1px solid black'}}>
                    <th>
                        <h6>TOTAL</h6>
                    </th>
                    <td>{listeClasses.sommeNote  + ' / ' + listeClasses.totalDemande}</td>
                </tr>
            </tbody>
        </table>
        <div className="" style={{display: 'flex', justifyContent: 'space-between', }}>
            <div className="col-md-6 d-flex justify-content-between">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin'>Moyenne de l'élève:  {moyenne.valeur +" / 10"}</h6>
            </div>
            <div className="col-md-6">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin'>Rang de l'élève: 
                    {rang.position >1 ?
                    <>  {" "+rang.position + ' ème'} </>:
                    <> 
                        {" "+rang.position + ' ère'}
                    </>
                    }
                </h6>
            </div>
        </div>
        {(listeClasses.semestre === "2 ème")&&
        <div className="col-md-12" >
            <table className='table table-bordered col-md-12'>
                <tbody>
                    <tr style={{fontSize: '13px'}} >
                        <td >Moyenne première semestre : {listeClasses.moyenSemestre1+" / 10"}</td>
                        <td>Moyenne deuxième semestre :  {moyenne.valeur+" / "+moyenne.damander}</td>
                        <td>Moyenne annuelle : {listeClasses.moyenAnnuelle+" / 10"}</td>
                    </tr> 
                </tbody>
            </table>
        </div>
        }
        <div className='' style={{display: 'flex', justifyContent: 'space-around', }}>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    le parent
                </h6>
            </div>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    le maitre
                </h6>
            </div>
            <div className="">
                <h6 style={{fontSize: '15px'}} className='footer-bulletin text-decoration-underline'>
                    la direction
                </h6>
            </div>

        </div>
        
        </div>
        {(profil === 'Admin') &&
            <div className="Rectangle-180 pl-3 pr-3 mt-5 " style={{marginRight: '300px'}} >
                <Link to='#' onClick={Print} style={{cursor: "pointer", color: "white"}}> <BsFillPrinterFill/> Imprimer</Link>
            </div>
        }
    </div>

  )
}
