import React, { useEffect,useState } from 'react'
import './Accueil.css'
import CardList from './CardList';
import Descripteur from './Descripteur';
import { Link} from 'react-router-dom';
import axios from 'axios'
import APP_ENV from "../../config/.env";
import {FaEllipsisV } from 'react-icons/fa';
import LoadingSpinner from '../helpers/LoadingSpinner';

function Accueil() {
  const [listeClasse, setListeClasse] = useState([]); 
  const [listeUtilisateur, setListeUtilisateur] = useState([]);
  const [listVarible, setListVarible] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
    
    const lastAnneeScolaire = async()=>{
      await axios(`${APP_ENV.baseUrl}/lastAnnee`,
      {headers: header}

      ).then(res=>{
        localStorage.setItem('anneeScolaire', JSON.stringify(res.data.annee.id));        
      })
      .catch(error =>{
        console.log("erreur")
      })
    }
    useEffect(()=>{
      lastAnneeScolaire();

      setIsLoading(true);
      axios(`${APP_ENV.baseUrl}/accueil-show`, {
        headers: header
        })
        .then(res =>{
          const dataAccueil = res.data.datas

          setListeClasse(dataAccueil.classes)
          setListeUtilisateur(dataAccueil.utilisateurs)
          setListVarible(dataAccueil)
         
          setIsLoading(false)
        })
    }, []);

  return(
   
    <div className='container mb-4'>
      {isLoading ? <LoadingSpinner/> : 
        <div className="row ">
            <div className="col-md-8">
                <div className="row bg-success">
                    <div className='col-md-12 baniereAccueil'>
                        <h2 className='title'>BIENVENUE CHEZ GROUPE SCOLAIRE ISLAMIQUE <br />FRANCO - ARABE ABOU OUBAYDA </h2>
                        <p className='adresse'>Parcelles Assainies <span>Unité : 26</span></p>
                        <Link to='/detailaccueil' title = "Informatios sur l'ecole">
                            <button type='buttun' className='btn-plus'>Voir Plus</button>
                        </Link>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-md-6" style={{paddingLeft: '0'}}>
                      <div >
                      <CardList title = 'Liste des Classes' classname='cardAccueil'>
                           <div className="dropdown detail ">
                              
                            <Link to="#" className="pt-3 accueil-detail dropdown-toggle" 
                                  data-bs-toggle="dropdown" aria-expanded="true">
                              <FaEllipsisV />
                            </Link>
                          
                            <div className="dropdown-menu tx-13">
                              <Link className="dropdown-item" to ="/classe-liste">Voir Plus</Link>
                              <Link className="dropdown-item" to ="/classe-create">Ajouter</Link>
                            </div>
                          </div>
                         
                          {listeClasse.map((classe, index ) => (
                            (index<=2) && 
                            <Descripteur texForm ={classe.libelle.substr(0,1).toUpperCase() } 
                              text = {classe.code }  
                              detail = {classe.effectif > 1 ? classe.effectif + ' élèves' : classe.effectif + ' élève'}
                              colorBg = '#cc41be'
                              key={index}
                            />
                          ))}
                  
                     </CardList>

                      </div>
                    </div>
                    <div className="col-md-6 " style={{paddingRight: '0'}}>
                    <div >
                      
                    <CardList title = 'Liste des Utilisateurs' classname='cardAccueil'>
                        <div className="dropdown detail">
                              
                          <Link to="#" className=" pt-3 accueil-detail dropdown-toggle" 
                                        data-bs-toggle="dropdown" aria-expanded="true" >
                            <FaEllipsisV/>
                          </Link>
                        
                          <div className="dropdown-menu tx-13">
                            <Link className="dropdown-item" to ="/utilisateur-liste">Voir Plus</Link>
                            <Link className="dropdown-item" to ="/utilisateur-ajout">Ajouter</Link>
                          </div>
                            
                        </div>
                          
                      {
                        listeUtilisateur.map((user, index ) => (
                          (index<=2)&& <Descripteur texForm ={user.prenom.substr(0,1).toUpperCase()} text = {user.prenom + ' ' + user.nom }  detail = {user.profil}  colorBg = 'rgba(65, 215, 248, 0.6)'/>
                         ))
                      }
                           </CardList>

                    </div>
                    </div>
                    </div>
                    
               
                {/* </div> */}
    
            </div>
            <div className="col-md-4" >
              <CardList title = {'Effectif globale ('+(listVarible.nbGarcon+listVarible.nbFille)+')'} color='rgba(56, 194, 120, 0.53)' classname='cardAccueil11'>
                  <Descripteur texForm ={listVarible.nbGarcon } text = {'garçons'}  
                    colorBg = '#268d36' color = '#000' 
                  />
                  <Descripteur texForm ={ listVarible.nbFille } text = {'filles'}  colorBg = '#268d36' color = '#000'/>
              </CardList>
              
              <CardList title = 'État des élèves' color='rgba(237, 251, 78, 0.69)' classname='cardAccueil1'>
                  <Descripteur texForm ={listVarible.eleve_pris_en_charge} text = {'en charge'}  colorBg = '#e5ca3a' color = '#000'/>
                  <Descripteur texForm ={listVarible.eleve_non_pris_en_charge} text = {'payantes'}  colorBg = '#e5ca3a' color = '#000'/>
              </CardList>
              <CardList title = "Membres de l'administration" color='rgba(226, 120, 120, 0.64)' classname='cardAccueil1'>
                  <Descripteur texForm ={listVarible.membreAd} text = {'membres'}  colorBg = '#eb3333' color = '#000'/>
              </CardList>
            </div>
        </div>
       } 
      </div>
    
  )
}
export default Accueil;
