
import React, { useEffect,useState } from 'react'
import './ListeUser.css'
import './ListeUserArchive.css'
import axios from 'axios'
import APP_ENV from "../../config/.env";
import Squelette from '../squelette/Squelette'
import DataTableUserArchive from './DataTableUserArchive';
import LoadingSpinner from '../helpers/LoadingSpinner'

export default function ListeUserArchive() {
    const [listeUtilisateur, setListeUtilisateur] = useState([]);  
    const [isLoading, setIsLoading] = useState(false);
      const header = {

        Accept : "application/json", 
        "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    
      const getData = async () => {
         await  axios (`${APP_ENV.baseUrl}/userArchive`, {
          method: "GET",
          headers: header
          })
          .then(res =>{
            const dataUser = res.data.donnees
           
            setListeUtilisateur(dataUser)
            setIsLoading(false)
            
          })       
      };
      useEffect(() => {
        setIsLoading(true)
        getData();
      }, []);
  return (
    <Squelette>
        {isLoading ? <LoadingSpinner/> : 
            <>
        <div className="container-fluid content-tab ">
          <fieldset className = 'fieldset'>
            <h3 className='tilte-fieldset user'>Liste des Utilisateurs Archivés</h3>
                <DataTableUserArchive data ={listeUtilisateur}/>      
          </fieldset>
      </div>
      </>}
    </Squelette>
  )
}
