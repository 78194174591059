import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import Squelette from '../squelette/Squelette'
import './Annee_scolaire_create.css'
import APP_ENV from "../../config/.env";

function Annee_scolaire_create() {
  const base_url = `${APP_ENV.baseUrl}`
  const header = {
    Accept : "application/json", 
    "Content-Type" : "application/json",
    WithCredentials : true,
    Authorization: "Bearer " + localStorage.getItem("token"),
  }
  // const date: Date = new Date();
  let navigate = useNavigate( );
  const [formulaire, setFormulaire] = useState({
    code: '',
    dateDebut : '',
    dateFin : '',
  })
  // Recuperation des niveau
    useEffect(()=>{

    }, [])

    const [error, setError] = useState('')
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      formulaire.code = formulaire.dateDebut.substr(0,4)+"-"+formulaire.dateFin.substr(0,4)
      
      setFormulaire({...formulaire ,[name]:value})
    }
    
    const hundleSubmit = (e) =>{    
      e.preventDefault();

      // Recuperation valeurs 
      axios.post(base_url + '/annee-create',formulaire,
      {headers: header}
      ).then(res=>{
        if(res.status === 200){
          navigate('/tarif-create')
        }
      })
      .catch(er =>{
        let ereur = er.response.data.error
        setError(ereur)
      })
  }


     
  return (
<Squelette>
  <div>
    <form onSubmit={hundleSubmit} className='container mt-5 formulaire col-md-8'>
        <fieldset className = 'p-2'>
          <h3 className='title-form-as'>Création année scolaire</h3>
          <div className="row">
            
            <div className="inputboite col-md-10 ml-3 m-2">
              <input type="text" placeholder="Code année scolaire" value={formulaire.code} name='code' onChange = {hundeChange} readOnly="true"/>
            </div>

            <div className="inputboite col-md-10 ml-3" >
              <div className="row mt-4">
                <div className="col-md-6">
                  <input type="date" placeholder="Choisir date début" name='dateDebut' onChange = {hundeChange}/>
                </div>
                <div className="col-md-6 ">
                <input type="date" placeholder="Choisir date fin"  name='dateFin' onChange = {hundeChange}/>
                </div>
              </div>                      
            </div>                    
          </div>
          {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
          : '' 
          }   
          <div className="col-md-12 conteneur-btn ">
            <button className='btn1-connexion enregistrer'>Enregistrer</button>
            <button className='btn1-connexion annuler'>Annuler</button>
          </div>
    
        </fieldset>
      </form>
    </div>
    </Squelette>
  )
}
 
export default Annee_scolaire_create;
