import React from 'react'

export default function TitreDetailPaiement({title, classname}) {
  return (
     <div className='col-md-12 px-0 paiement-trimestre '>
        <div className="container-fluid row d-flex mt-3 justify-content-between ">
            <div className={`col-md-5 mt-3 ${classname}`}></div>
            <div className="col-md-7 text-detail  d-flex justify-content-center">
                <h3>{title}</h3>
            </div>
        </div>
    </div>
  )
}
