import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from 'react';
import axios from 'axios';
import '../annee_scolaire/Annee_scolaire_create.css'
import ImageUploading from 'react-images-uploading';
import APP_ENV from "../../config/.env";
//Success POPUP

export default function AjoutUser() {
    
    const header = {
        Accept : "application/json", 
        // "Content-Type" : "application/json",
        WithCredentials : true,
        Authorization: "Bearer " + localStorage.getItem("token"),
    }

    const [formulaire, setFormulaire] = useState({
        nom: '',
        prenom : '',
        email : '',
        telephone : '',
        profil_id : '',
        avatar : '',
    })
    const [ setError] = useState('')
    const [profils, setProfils] = useState([])
    const [ setAvatar] = useState('')
    const [errorPrenom, setErrorPrenom] = useState('')
    const [errorNom, setErrorNom] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const [errorTelephone, setErrorTelephone] = useState('')
    const [errorProfil, setErrorProfil] = useState('') 
    const [images, setImages] = useState('');
    const [previewAvatar, setPreviewAvatar] = useState('')
    //##### Changement des inputs #####
    const maxNumber = 69;
    const onChange = (imageList) => {
      setImages(imageList[0].file);
      setPreviewAvatar(imageList[0])
      
    }
    //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      if (e.target.type != "file") {
        setFormulaire({...formulaire, avatar : images})
        setFormulaire({...formulaire ,[name]:value})
    } else {
        formulaire.avatar = images
        setAvatar(e.target.files[0]); // tableau File avec ces elements
      }
    }
    let navigate = useNavigate( );
    // Recuperation des niveau
    const filUploade = (id) => {
        const formData = new FormData();
        formData.append("avatar", images); // append: (ajout du file)
      axios.post(`${APP_ENV.baseUrl}/uplodeFile/${id}`,formData,{

            headers: header,
        })
        .then((res) => {
            // console.log(res);
        })
        .catch((er) => {

        });
    };
    const getProfile = async () => {
      await
      axios(`${APP_ENV.baseUrl}/profils`,
            {headers: header}
        ).then(res=>{
            setProfils(res.data.donnees)
        })
        .catch(er =>{
            let ereur = er.response
            setError(ereur)
        })
    } 
    useEffect(()=>{
        getProfile();
    }, [])

    const emailValidation =()=> {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !(!formulaire.email || regex.test(formulaire.email) === false);
      }
    
    const hundleSubmit = (e) =>{    
      e.preventDefault();
      setFormulaire({...formulaire, avatar : images})
     // console.log(emailValidation());
    //   validation des champs
      if (formulaire.prenom === '') {
         setErrorPrenom("Veillez saisir le champ prenom")
      }else{
        setErrorPrenom('')
      }
      if (formulaire.nom === '') {
        setErrorNom("Veillez saisir le champ nom")
     }else{
       setErrorNom('')
     }
     if (formulaire.email === '') {
        setErrorEmail("Veillez saisir le champ email")
     }else if(emailValidation() === false){
        setErrorEmail("Veillez saisir un bon email (example@gmail.com)")
       
     }else{
        setErrorEmail('')
    }
// telephone
      const regex = (/^[0-9\b]+$/);
     if (formulaire.telephone === '') {
        setErrorTelephone("Veillez saisir le champ telephone")
        }else if (!regex.test(formulaire.telephone)) {
          setErrorTelephone ("Please entere des nombre");
        }else if(formulaire.telephone.length > 9 || formulaire.telephone.length < 9){
          setErrorTelephone( "Please entere un numero telephone valide.");
        }   
     else{
        setErrorTelephone('')
     }
     if (formulaire.profil_id === '') {
        setErrorProfil("Veillez saisir le champ email")
     }else{
        setErrorProfil('')
     }
      axios.post(`${APP_ENV.baseUrl}/user`,formulaire,
      
      {headers: header}
      ).then(res=>{
        filUploade(res.data.user?.id)
        navigate('/utilisateur-liste')
      })
      .catch(er =>{
        // let ereur = er.response.data.errors
        setError(er)
      })
    }
    return (
    <div>
        <form onSubmit={hundleSubmit} className='container mt-4 formulaire'>
        <fieldset className = 'fieldset'>
         
         <h3 className='tilte-fieldset user'>Créer un utilisateur</h3>
            
                <div className="row ">
                    <div className="col-md-8">
                     <div className="row d-flex justify-content-between">
                        <div className="inputboite " style = {{width: '48%'}}>

                         <input type="text" placeholder="Prénom" className="form-controluser "
                             name='prenom' onChange = {hundeChange} />
                              {(errorPrenom)  ?
                            <p className=' text-danger'>{errorPrenom}</p>:
                           ''
                            }
                        </div>
                        <div className="inputboite" style = {{width: '48%'}}>
                          <input type="text" placeholder="Nom" className="form-controluser "
                             name='nom' onChange = {hundeChange} />
                             {(errorNom )  ?
                             
                             <p className=' text-danger'>{errorNom}</p>:''
                             }  
                        </div>
                    
                      </div>
                        <div className="row">
                        <div className="inputboite col-md-12"  style={{paddingRight: '20px'}}>
                            <input type="text" placeholder="Email" className="form-controluser "
                                name='email' onChange = {hundeChange} />
                                {(errorEmail )  ?                              
                                <p className=' text-danger'>{errorEmail}</p>:
                                ''
                                }  
                           
                       </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className=" inputboite " style = {{width: '48%'}}>
                            <input type="text" placeholder="Téléphone" className="form-controluser "
                                name='telephone' onChange = {hundeChange} />
                                  {(errorTelephone )  ?
                             <p className=' text-danger'>{errorTelephone}</p>:
                             '' 
                             }
                            </div>
                            <div className=" inputboite" style = {{width: '48%'}}>
                                   
                                   <select name="profil_id" onChange = {hundeChange}  className="form-controluser ">
                                   <option >Veillez choisir le profil</option>
                                   {(profils) ? profils.map((prof, index) => (
                                       <option value={prof.id}>{ prof.libelle}</option>
                                   )) : <h4>Pas de profils trouvés</h4>}
                                   </select>
                                   {(errorProfil )  ?
                                       <p className=' text-danger'>{errorProfil}</p>:
                                       ''
                                       }
                            </div>
                        </div>
                        </div>
                    <div className='col-md-4 ml-4 d-flex justify-content-center'>

                    <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    name="avatar"
                    dataURLKey="data_url"
                    acceptType={["jpg","png","jpeg"]}
                >
        {({
            onImageUpdate,
          onImageUpload,
          isDragging,
          dragProps
        }) => (
          <div className="upload__image-wrapper justify-content-center">
            <a  onChange={onChange}
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
             
            </a>
            &nbsp;
              <div  className="image-item mt-4 ml-3">
              {  previewAvatar ?
              <>
                <img className="profil-user" src={previewAvatar.data_url} alt="" onChange={onChange}
                    style={{width: '150px', height: '150px',objectFit: 'cover',cursor: 'pointer'}} 
                    title="Cliquer ici pour choisir une image" 
                    onClick={onImageUpload}
                    {...dragProps}
                    />
                </>
                     : 
                     <img className="profil-user" src={process.env.PUBLIC_URL + '/images/pp.png'} 
                     style={{width: '150px', height: '150px',objectFit: 'cover', cursor: 'pointer'}}
                    title="Cliquer ici pour choisir une image" 
                    onClick={onImageUpload}
                    {...dragProps}
                    />  
                } 
                    <div className="image-item__btn-wrapper">
                    </div>
                </div>
                {/* ))} */}
            </div>
            )}
                    </ImageUploading>                      
                    </div>
                    </div>
                    
                    <br />
                    {formulaire.profil_id == 3 &&
                    <div className="row "  >
                                <div className="col-md-2"></div>
                                <div className="col-md-4">
                                    <div className="form-group  ">
                                    {/* className="form-controluser " */}
                                        <select className="form-control  " id="sel1" name="type"  onChange = {hundeChange} >
                                        <option  >Type  d'enseignent</option >
                                            <option value="FR">FRANCAIS</option>
                                            <option value="AR" >ARABE</option>
                                        
                                        </select>
                                    </div>
                                </div>
                              
                            </div>
                            }
                    <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
                  

                {/* </div> */}
        
            </fieldset>
        </form>
    </div>
  )
}


