import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container d-flex align-items-center justify-content-center p-5">
      <div className="loading-spinner d-flex align-items-center justify-content-center p-5">
      </div>
    </div>
  );
}