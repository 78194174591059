import Connexion from './components/auth/Connexion';
import { Routes, Route, Navigate } from 'react-router-dom';
import SqAccueil from './components/accueil/SqAccueil';
import ReinitialisePassword from './components/auth/ReinitialisePassword';
import ModifierPassword from './components/auth/ModifierPassword';
import SqAffichageBudgetere from './components/paiement/SqAffichageBudgetere';
import AjoutApport from './components/apport/AjoutApport';
import ModifierAport from './components/apport/ModifierAport';
import Eleves from './components/eleve/Eleves';
import Reinscrire from './components/eleve/Reinscrire';
import Classe_create from './components/classe/Classe_create';
import ListeClasse from './components/classe/ListeClasse';
import UpdateClasse from './components/classe/UpdateClasse';
import Squelette from './components/squelette/Squelette';
import SqAjoutUser from './components/user/SqAjoutUser';
import SqUpdateUser from './components/user/SqUpdateUser';
import SqListeUser from './components/user/SqListeUser';
import Affichage_classe from './components/classe/Affichage_classe'
import Annee_scolaire_create from './components/annee_scolaire/Annee_scolaire_create'
import Annee_scolaire_liste from './components/annee_scolaire/Annee_scolaire_liste'
import Annee_scolaire_update from './components/annee_scolaire/Annee_scolaire_update'
import Paiement_create from './components/paiement/Paiement_create'
import AffichageEleve from './components/eleve/AffichageEleve'
import ProfilUser from './components/user/ProfilUser';
import ListEleveAyantPaye from './components/paiement/ListEleveAyantPaye';
import ListEleveAvecPaiement from './components/paiement/ListEleveAvecPaiement';
import ListEleves from './components/eleve/ListEleves';
import {AccountService} from './components/services/AccountService'
import ListUserArchive from './components/user/ListeUserArchive'
import Recu from './components/eleve/Recu'
import ListElevesArchive from './components/eleve/ListElevesArchive'
import ModificationEleve from './components/eleve/ModificationEleve';
import CreationMatirer from './components/matiere/CreationMatirer'
import ListMatiere from './components/matiere/ListMatiere'
import ModifierMatiere from './components/matiere/ModifierMatiere'
import CreerSurveillent from './components/surveillent/CreerSurveillent'
import ListSurveillent from './components/surveillent/ListSurveillent'
import ModifierSurveillent from './components/surveillent/ModifierSurveillent'
import RecuPaiement from './components/paiement/RecuPaiement';

import ListeEnseignent from './components/enseignent/ListeEnseignent';
import ListeClasseDUnEenseignent from './components/enseignent/ListeClasseDUnEenseignent';
import ListeNiveau from './components/niveaux/Liste_niveau';
import Creation_niveau from './components/niveaux/Creation_niveau';
import Update_niveau from './components/niveaux/Update_niveau';
import Tarification_create from './components/tarification/Tarification_create';
import Tarification_liste from './components/tarification/Tarification_liste';
import Tarification_update from './components/tarification/Tarification_update'
import AffectationMatiereClasseEnseignent from './components/matiere/AffectationMatiereClasseEnseignent'
import DetailPaiement from './components/paiement/DetailPaiement';
import AffectationNiveau from './components/niveaux/AffectationNiveau';
import ProfilEnseignent from './components/enseignent/ProfilEnseignent';
import ReporterNoteClasse from './components/note/ReporterNoteClasse';
import Extrait from './components/eleve/Extrait';
import DetailAccueil from './components/accueil/DetailAccueil';
import ReclamationNote from './components/note/ReclamationNote';
import EmploisDuTempsClasse from './components/emploieDuTemp/EmploisDuTempsClasse';
import EmploisDuTempsEleve from './components/emploieDuTemp/EmploisDuTempsEleve';
import ListNoteParClasse from './components/note/ListNoteParClasse';
import ListNoteParClasseUnSamestre from './components/note/ListNoteParClasseUnSamestre';
import ListNoteEleveSamestre from './components/note/ListNoteEleveSamestre';
import CreattionEmploieTemp from './components/emploieDuTemp/CreationEmploieTemp';
import ListParent from './components/user/ListParent';
import AccueilParent from './components/accueil/AccueilParent';
import BulletinNoteP from './components/note/BulletinNoteP'
import BulletinNotePA from './components/note/BulletinNotePA'
import BulletinNoteM from './components/note/BulletinNoteM';
import UpdateEmploisDuTemps from './components/emploieDuTemp/UpdateEmploisDuTemps';
import ListeClasses from './components/emploieDuTemp/ListeClasses';
import Mail from './components/messaage/Mail';
import RangEleveClasse from './components/note/RangEleveClasse'
import BulletinNoteClasseM from './components/note/BulletinNoteClasseM';
import BulletinNoteClasseMA from './components/note/BulletinNoteClasseMA';
import TableauHoneure from './components/note/TableauHoneure';
import ListClasseDerniereAnnee from './components/annee_scolaire/ListClasseDerniereAnnee';
import TemponAnneeScolaire from './components/annee_scolaire/TemponAnneeScolaire';
import AffichageClasseTempon from './components/classe/AffichageClasseTempon';

function App() {

    return (
        <Routes>
                    {/* authentification */}
        <Route path = '/' element = {< Connexion /> }/> 
        <Route path = '/reinitialisation' element = { < ReinitialisePassword /> } />   
        <Route path = '/modifierPassword' element = { < ModifierPassword /> }/>  
        <Route path = '/test' element = { < ListeEnseignent /> }/>  
        <Route path = '/squelette'element = { < Squelette /> }/> 

            {AccountService.isLogged() ?
                <>
                    {/* accueil */}
        <Route path = '/accueil' element = { < SqAccueil /> }/> 
        <Route path = '/detailaccueil'element = { < DetailAccueil /> }/> 

                    {/* niveaux */}
        <Route path = '/niveau-create' element = { <Creation_niveau/> }/>   
        <Route path = '/niveau-update/:id' element = { <Update_niveau/> }/>   
        <Route path = '/niveau-liste' element = { <ListeNiveau/> }/> 
        <Route path = '/affectationNiveau' element = { < AffectationNiveau /> }/>

                    {/* tarification */}
        <Route path = '/tarif-create' element = { <Tarification_create/> }/>   
        <Route path = '/tarif-liste' element = { <Tarification_liste/> }/>   
        <Route path = '/tarif-update' element = { <Tarification_update/> }/>

                    {/* classes */}
        <Route path = '/cl-liste-eleve-matiere/:id' element = {<ListeClasseDUnEenseignent/> }/> 
        <Route path = '/affectation' element = { <AffectationMatiereClasseEnseignent/> }/>
        <Route path = '/classe-create' element = { <Classe_create /> }/>  
        <Route path = '/classe-liste' element = {< ListeClasse /> }/>   
        <Route path = '/classe-update/:id' element = { < UpdateClasse /> }/> 
        <Route path = '/classe-affiche/:id' element = { < Affichage_classe /> }/>
        <Route path = '/classe-affiche-tempon/:id/:annee' element = { <AffichageClasseTempon /> }/>
        <Route path = '/listeClasseEnseignent/:id'element = { < ListeClasseDUnEenseignent /> }/> 
        <Route path = '/listEleveParClasse/:id' element = { < ListeClasseDUnEenseignent /> }/> 
        
                    {/* utlisateur */}
        <Route path = '/utilisateur-ajout' element = {< SqAjoutUser /> }/>   
        <Route path = '/utilisateur-modifier/:id' element = { < SqUpdateUser /> }/>   
        <Route path = '/utilisateur-liste' element = { < SqListeUser /> }/>   
        <Route path = '/userArchive' element = { < ListUserArchive /> }/> 
        <Route path = '/profilUser/:id' element = { < ProfilUser /> }/>   

                    {/* annee scolaire */}
        <Route path = '/annee-scolaire-create'element = { < Annee_scolaire_create /> }/>  
        <Route path = '/annee-scolaire-update'element = { < Annee_scolaire_update /> }/>  
        <Route path = '/annee-scolaire-liste'element = { < Annee_scolaire_liste /> } />  
        <Route path = '/classeDerniereAnnee' element = { < ListClasseDerniereAnnee /> }/> 
        <Route path = '/tempnAnneeScolaire/:id' element = { < TemponAnneeScolaire /> }/> 
        
        {/* afectationAnneeScolaireClasse */}

                    {/* paiement */}
        <Route path = '/paiement-create' element = {<Paiement_create /> }/> 
        <Route path = '/detailpaiement' element = { < DetailPaiement /> }/> 
        <Route path = '/accueilBudgetere' element = { < SqAffichageBudgetere /> }/>
        <Route path = '/listEleveAyantPaye' element = { < ListEleveAyantPaye />}/>  
        <Route path = '/listEleveAvecPaiement' element = { < ListEleveAvecPaiement /> }/>     
        
                    {/* eleve */}
        <Route path = '/eleve-profil/:id' element = { < AffichageEleve /> }/> 
        <Route path = '/reinscription' element = {< Reinscrire /> }/> 
        <Route path = '/listEeleve'   element = { < ListEleves /> }/>   
        <Route path = '/eleveArchive'element = { < ListElevesArchive /> }/>
        <Route path = '/modificationEleve/:id' element = { < ModificationEleve />}/>          
        <Route path = '/inscription'element = { < Eleves /> }/> 
        
                    {/* apport */}
        <Route path = '/ajoutApport'element = { < AjoutApport /> }/>   
        <Route path = '/modifierAport/:id' element = { < ModifierAport /> }/>   
        <Route path = '/delete/:id'element = { < ModifierAport /> }/>

                    {/* enseignent */}
        <Route path = '/profilEnseignent/:id' element = { < ProfilEnseignent /> }/>
        <Route path = '/listeClasseEnseignent/:id' element = {<ListeClasseDUnEenseignent/> }/> 
       
                    {/* matiere */}
        <Route path = '/creationMatirer'element = { < CreationMatirer /> }/> 
        <Route path = '/listMatiere'element = { < ListMatiere /> }/> 
        <Route path = '/modifierMatiere/:id'element = { < ModifierMatiere /> }/>

                    {/* surveillent */}
        <Route path = '/creerSurveillent'element = { < CreerSurveillent /> }/>
        <Route path = '/listSurveillent'element = { < ListSurveillent /> }/>
        <Route path = '/modifierSurveillent/:id'element = { < ModifierSurveillent /> }/>

                    {/* Documents */}
        <Route path = '/recu'element = { < Recu /> }/>
        <Route path = '/recu/:id' element = { <Recu/> }/>  
        <Route path = '/recuPaiement'element = { < RecuPaiement /> }/>
        <Route path = '/extrait/:id' element = {<Extrait/> }/> 
                    
                    {/* Evaluation et note */}
        <Route path = '/reportage-note' element = {<ReporterNoteClasse/> }/> 
        <Route path = '/reclamation-note' element = {<ReclamationNote/> }/> 
        <Route path = '/listNoteParClassse/:id' element = {<ListNoteParClasse/> }/>   
        <Route path = '/listdesnotes/:id' element = {<ListNoteParClasseUnSamestre/> }/>   
        <Route path = '/noteEleveSemetrielle/:id' element = {<ListNoteEleveSamestre/> }/>  
        <Route path = '/bulletin-de-notep' element = {<BulletinNoteP/> }/> 
        <Route path = '/bulletin-de-notem' element = {<BulletinNoteM/> }/> 
        <Route path = '/bulletin-de-notepa' element = {<BulletinNotePA/> }/> 
        <Route path = '/classe-rang/:id' element = {<RangEleveClasse/> }/> 
        <Route path = '/bulletinNoteClasseM/:id' element = {<BulletinNoteClasseM/> }/> 
        <Route path = '/bulletinNoteClasseMA/:id' element = {<BulletinNoteClasseMA/> }/> 
        <Route path = '/TableauHoneure' element = {<TableauHoneure/> }/> 
        
                    {/* emplois de temps */}
        <Route path = '/emplois-du-temps-classe/:id' element = {<EmploisDuTempsClasse/> }/> 
        <Route path = '/emplois-du-temps-update/:id' element = {<UpdateEmploisDuTemps/> }/>          
        <Route path = '/emplois-du-temps-eleve/:id' element = {<EmploisDuTempsEleve/> }/> 
        <Route path = '/creerEmploieTemp' element = {<CreattionEmploieTemp/> }/>          
        <Route path = '/classe' element = {<ListeClasses/> }/>          

                    {/* // Messages  */}
        <Route path = '/mail' element = {<Mail/> }/>  

                    {/* Parent */}
        <Route path = '/parent' element = {<AccueilParent/> }/>  
        <Route path = '/listParent' element = { < ListParent /> }/>   
           
            </>:
        <Route path = '*'element = {<Navigate to = '/'/> }/>  
        }
            
    </Routes>

    );
}

export default App;