import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom';
import './ModifierPassword.css'
import APP_ENV from "../../config/.env";

function ModifierPassword() {
    let navigate = useNavigate( );
  // const APP_KEY = "base64:1eVr0Ulj38dFmPI2OCbecG8wZzIcftMZeO8gz898sdk="
  const [identifiant, setIdentifiant] = useState({
    code: '',
    email: '',
    password : '',
    password_confirmation: '',
  })
  const [error, setError] = useState('')
  const [ setSuccess] = useState('')
      //##### Changement des inputs #####
    const hundeChange =(e)=>{
      const name = e.target.name;
      const value = e.target.value
      setIdentifiant({...identifiant ,[name]:value})
    }
      // je dois netoyer le DOM (alert error) apres Render avec useEffect()

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
        if(identifiant.password_confirmation!==identifiant.password)
        {
        setError("Veillez saisir deux mot de passe identique!!!");
        }else if(identifiant.password.length <8){
          setError("Le mot de passe doit être minimum 8 carractères!!!");
        }else{
          axios.post(`${APP_ENV.baseUrl}/password/reset`,identifiant)
          .then(res=>{
            navigate('/')
            setSuccess(res.data);
          })
          .catch(er =>{
              let ereur = er.response.data.errors.messag
              setError(ereur)
          })
        }
      }
     
    
  return (
    <div className='content-form'>
      <form className="contactform" onSubmit={hundleSubmit}>
        <h3>Réinitialisation mot de passe</h3>
        <div className="inputboite">
            <input type="text" placeholder="Code " name='code' value={identifiant.code} onChange = {hundeChange}/>
        </div>
        <div className="inputboite">
            <input type="text" placeholder="Email" name='email' value={identifiant.email} onChange = {hundeChange}/>
        </div>
          <div className="inputboite">
            <input type="text" placeholder="Nouveau mot de passe" name = 'password'value={identifiant.password} onChange = {hundeChange}/>
        </div>  
        <div className="inputboite">
            <input type="text" placeholder="confirmation mot de passe" name = 'password_confirmation' value={identifiant.password_confirmation} onChange = {hundeChange}/>
        </div>  

        {error !== '' ? 
        <h2 className='alert alert-danger text-center'>{error}</h2>
        : '' 
        } 
        
        <div className="inputboite">
            <button type = 'submit' className='btn-connexion'>Réinitialisation</button>
        </div>
    
        <p><Link to="/" className="dropdown-item">Retour</Link> 
          </p>
      </form>
     
    </div>
  )
}
 
export default ModifierPassword;
