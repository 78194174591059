
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';
import axios from 'axios';
import './CreationMatiere.css'
import Squelette from '../squelette/Squelette';
import APP_ENV from "../../config/.env";
import { useParams } from 'react-router-dom';
function ModifierMatiere() {
    let matiereId = useParams(); 
    let navigate = useNavigate();
  const [identifiant, setIdentifiant] = useState([])
    const header = {
      Accept : "application/json", 
      "Content-Type" : "application/json",
      WithCredentials : true,
      Authorization: "Bearer " + localStorage.getItem("token"),
    }
  const [error] = useState('')
      //##### Changement des inputs #####
      const hundeChange =(e)=>{
              const name = e.target.name;
              const value = e.target.value
              setIdentifiant({...identifiant ,[name]:value})
      }

      const getMatiereById = async () => {
        await
        axios
        .get(`${APP_ENV.baseUrl}/matiereById/`+matiereId.id, {
          headers: header,
        })
                  .then(res =>{
                    setIdentifiant(res.data.donnees)
                    
                  })
      }
        
     useEffect(() => {
      getMatiereById();
     
      },[]);

      //##### Soumission du Formulaire #####
      const hundleSubmit = (e) =>{
        e.preventDefault();
            axios.post(`${APP_ENV.baseUrl}/matiere/`+matiereId.id,identifiant, {
            headers: header
            })
          .then(res=>{
             navigate('/listMatiere')
            
          })
          .catch(er =>{
  
              })
      }
  return (
    <Squelette>
    <div className='apporttconteiner'>
       <form className="apportform formulaire" onSubmit={hundleSubmit}>
        <br />
        <br />
            <fieldset className = 'fieldset'>
                <h3 className='tilte-fieldset user'>Modification matière</h3> 
            <div className="row justify-content-center">
       
                <div className="col-md-10 ">
                    <div class="form-group " style={{ marginLeft: 10}}>
                    
                        <select class="form-control" id="sel1" name="type"  value={identifiant.type} onChange = {hundeChange} >
                        {  ( identifiant.type === 'AR')?
                         <option value="AR">ARABE</option>:
                         <option value="FR">FRANCAIS</option>
                        }
                            <option value="FR">FRANCAIS</option>
                            <option value="AR" >ARABE</option>
                        
                        </select>
                    </div>
                </div>
               
       
                <div className="col-md-10 mt-4 ">
                    <div className="inputboite " style={{ marginRight: 17}}>
                        <input type="text"  placeholder="Nom Matiere" name='libelle' value={identifiant.libelle} onChange = {hundeChange}/>
                    </div>
                </div>
            </div>
            {(identifiant.type === 'AR')&&
                <div className="row justify-content-center">
               
                <div className="col-md-10">
                    <div className="inputboite">
                        <input type="text" placeholder="Description" name='description' value={identifiant.description} onChange = {hundeChange}/>
                        {/* {(errorLibelle )  ?
                             
                             <p className=' text-danger'>{errorLibelle}</p>:
                             ''
                             }   */}
                    </div>
                </div>
            </div>
                }
           
            {error !== '' ? 
            <h2 className='alert alert-danger text-center'>{error}</h2>
            : '' 
            } 
            
            <div className="col-md-12 conteneur-btn btnAjoutUser ">
                        <button type = 'submit' className='btn1-connexion enregistrer'>Enregistrer</button>
                        <button className='btn1-connexion annuler'>Annuler</button>
                    </div>
           
            </fieldset>
       </form>
     
    </div>
    </Squelette>
  )
}
 
export default ModifierMatiere;
